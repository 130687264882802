import { Loader, PageHeader } from 'components/ui';
import React, { Suspense } from 'react';
import {
  CompanyIntro,
  Mission,
  Services,
  Affiliates,
  AboutUs,
  Contact,
  ContactInfo
} from './sections';
import styles from './CompanyView.module.scss';
import { IntroButtons } from './sections/CompanyIntro/sections';
import { useGetCompanyProfileQuery, useUpdateCompanyProfileMutation } from 'api/company-profile';
import { useSelector } from 'react-redux';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import { getCurrentUserIdSelector, getIsAdminSelector } from 'store/login.slice';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';
import { handleAsync } from 'helpers';
function CompanyView() {
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;

  const userId = useSelector(getCurrentUserIdSelector);
  const companyId = useParams()?.id;
  const hasEditRights =
    useSelector(getIsAdminSelector) && userTier?.toLowerCase() === APP_TIERS.REGULAR;
  const [updateCompanyProfile] = useUpdateCompanyProfileMutation();
  companyId?.split('-');
  const { isLoading, data, error, isError } = useGetCompanyProfileQuery(
    {
      companyId: companyId?.split('-')[0]
    },
    {
      skip: !companyId
    }
  );
  if (isLoading) return <Loader fullpage />;
  if (isError)
    return (
      <p
        style={{
          color: 'red'
        }}
      >
        {error?.data?.message}
      </p>
    );

  if (!data) return null;
  const onSectionUpdate = debounce(async (resultData) => {
    const companyProfile = {
      ...data,
      ...resultData
    };
    return handleAsync(
      updateCompanyProfile({
        companyId,
        companyProfile
      }).unwrap()
    );
  }, 300);
  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div className={styles['company-view']}>
        <PageHeader fixed listItems={['Home', 'Services', 'About us', 'Contact us']} />
        <CompanyIntro companyName={data.name} companyLogo={data.logo} companyTheme={data?.theme} />
        {userId && <IntroButtons />}
        <ResponsiveWrapper>
          <div className={styles['main-wrapper']}>
            <Mission
              mission={data.mission}
              onSectionUpdate={onSectionUpdate}
              hasEditRights={hasEditRights}
              companyTheme={data?.theme}
            />
            <Services
              services={data.services}
              onSectionUpdate={onSectionUpdate}
              hasEditRights={hasEditRights}
              companyTheme={data?.theme}
            />
            <Affiliates
              affiliates={data.affiliates}
              onSectionUpdate={onSectionUpdate}
              hasEditRights={hasEditRights}
            />
            <AboutUs
              about={data.about}
              onSectionUpdate={onSectionUpdate}
              hasEditRights={hasEditRights}
              companyTheme={data?.theme}
            />
            {/* this is an email contact component */}
            {data?.contact?.email ? (
              <Contact companyTheme={data?.theme} contact={data.contact} />
            ) : null}
            <ContactInfo
              contact={data.contact}
              onSectionUpdate={onSectionUpdate}
              hasEditRights={hasEditRights}
              companyTheme={data?.theme}
            />
          </div>
        </ResponsiveWrapper>
      </div>
    </Suspense>
  );
}

export default CompanyView;

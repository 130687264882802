import React from 'react';
import styles from './PollQuestion.module.scss';
import Add from 'assets/icons/addmore.svg?react';
import Delete from 'assets/icons/deleteicon.svg?react';

export default function PollQuestion({
  question,
  handleChange,
  addOptionToQuestion,
  handleChangeOptions,
  removeOptionFromQuestion,
  questionIndex
}) {
  return (
    <div className={styles.PollQuestionContainer}>
      <input
        className={styles.questionInput}
        type="text"
        id="question"
        name="question"
        required
        placeholder="Add your question"
        value={question?.question}
        onChange={(event) => {
          handleChange(event, questionIndex);
        }}
      />
      {question?.options.map((option, optionIndex) => {
        return (
          <div
            key={`Question${questionIndex}option${optionIndex}`}
            className={styles.pollQuestionInputs}
          >
            <input
              type="text"
              id={`${questionIndex}option${optionIndex}`}
              name={`option`}
              required
              placeholder="Add your option"
              value={option?.option}
              onChange={(event) => {
                handleChangeOptions(event, questionIndex, optionIndex);
              }}
            />
            <Delete
              onClick={() => {
                removeOptionFromQuestion(questionIndex, optionIndex);
              }}
              className={styles.deleteOption}
            />
          </div>
        );
      })}
      <div
        className={styles.AddQuestionButton}
        onClick={() => {
          addOptionToQuestion(questionIndex);
        }}
      >
        <Add /> Add option
      </div>
    </div>
  );
}

import { jwtDecode } from 'jwt-decode';
import Cookies from './cookies';
export function authenticateUser(token) {
  const jwt = token || getAuthData();
  const companyId = getAuthData('activeProfile') || '';
  if (!jwt) return [false];
  try {
    const decoded = jwtDecode(jwt);
    if (!decoded || !decoded.exp) return [false];
    const now = new Date();
    const nowInSec = Math.floor(now.getTime() * 0.001);
    return [
      decoded.exp > nowInSec,
      decoded,
      companyId ? companyId.split('-')[0] : 'No activeProfile',
      jwt
    ];
  } catch (unusedERROR) {
    deleteAuthData();
    return [false];
  }
}

export function getAuthData(name = 'authCode') {
  const cookies = new Cookies();
  return cookies.get(name);
}

// export function deleteAuthData(noRedirect = true) {
export function deleteAuthData() {
  document.cookie = `authCode=;domain=.${
    import.meta.env.REACT_APP_DOMAIN_URL
  };path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  document.cookie = `activeProfile=;domain=.${
    import.meta.env.REACT_APP_DOMAIN_URL
  };path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  // const { href, pathname } = window.location;
  // let params = '';
  // if (noRedirect) params = `?_rdr=${href}&service=wizrx&path=${pathname}`;
  // navigate to masterhub and replace the current url
  window.location.replace(`${import.meta.env.REACT_APP_LOGIN_URL}`);

  return true;
}
export function switchActiveProfile(id) {
  document.cookie = `activeProfile=${id};domain=.${import.meta.env.REACT_APP_DOMAIN_URL};`;
  window.location.reload();
  return true;
}
export function directUserToMasterHub() {
  window.location.href = `${import.meta.env.REACT_APP_LOGIN_URL}/hub/home`;
  return true;
}

import React from 'react';
import styles from './MWEditor.module.scss';

export const Button = React.forwardRef(({ className, active, reversed, ...props }, ref) => (
  <span {...props} ref={ref} className={`${styles.button} ${active && styles.active}`} />
));

export const Icon = React.forwardRef(({ className, ...props }, ref) => (
  <span {...props} ref={ref} className={styles.icons} />
));

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={`${styles.menu} editorMenu`} />
));

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu {...props} ref={ref} className={styles.toolbar} />
));

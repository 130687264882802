import React from 'react';
import styles from './LandingPage.module.scss';
import Logo from 'assets/icons/landingWizr.svg?react';
import { Button } from 'components/ui';

export default function LandingPage({ click }) {
  return (
    <>
      <div className={styles.landingPage}>
        <div className={styles.content}>
          <div>
            <h2>
              <span>WiZR</span> X is platform for connecting people & sharing their activities
            </h2>
            <p>
              Platform all for connecting people & sharing their activities between individuals and
              teams.
            </p>
            <Button onClick={click} color="white" bgColor="#079677">
              Get Started
            </Button>
          </div>
        </div>
        <Logo />
      </div>
    </>
  );
}

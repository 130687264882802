import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ArrowDown from 'assets/icons/arrowdown.svg?react';

export default function TableHeaders({ sorter, setSorter }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const renderType = searchParams?.get('type') || 'name';
  const renderAsc = searchParams?.get('asc') || true;

  useEffect(() => {
    setSorter({
      type: renderType,
      asc: renderAsc === 'false' ? false : true
    });
  }, [location]);

  const changeSort = (e) => {
    const newSort = e.target.getAttribute('name');
    const { asc } = sorter;
    if (sorter?.type !== newSort) {
      searchParams.set('type', newSort);
      searchParams.set('asc', 'true');
    } else {
      searchParams.set('asc', !asc);
    }
    const newUrl = '/dashboard/participants?' + searchParams.toString().replace(/%2C/g, ',');
    navigate(newUrl);
  };

  return (
    <tr className={styles.table_tr}>
      <th
        className={`${styles.table_th} ${sorter?.type === 'name' ? styles.active : ''}`}
        onClick={changeSort}
        name="name"
        style={{ textAlign: 'start' }}
        scope="col"
      >
        Name
        <ArrowDown
          className={`${styles.sorter_arrow} ${
            !sorter.asc && sorter?.type === 'name' ? styles.desc : ''
          } `}
        />
      </th>

      <th
        className={`${styles.table_th} ${sorter?.type === 'spent_time' ? styles.active : ''}`}
        onClick={changeSort}
        name="spent_time"
        scope="col"
      >
        Spent Time
        <ArrowDown
          className={`${styles.sorter_arrow} ${
            !sorter.asc && sorter?.type === 'spent_time' ? styles.desc : ''
          } `}
        />
      </th>
      <th
        className={`${styles.table_th} ${sorter?.type === 'progress' ? styles.active : ''}`}
        onClick={changeSort}
        name="progress"
        scope="col"
      >
        Progress
        <ArrowDown
          className={`${styles.sorter_arrow} ${
            !sorter.asc && sorter?.type === 'progress' ? styles.desc : ''
          }`}
        />
      </th>
      <th
        className={`${styles.table_th} ${sorter?.type === 'purchase_date' ? styles.active : ''}`}
        onClick={changeSort}
        name="purchase_date"
        scope="col"
      >
        Purchase date
        <ArrowDown
          className={`${styles.sorter_arrow} ${
            !sorter.asc && sorter?.type === 'purchase_date' ? styles.desc : ''
          } `}
        />
      </th>
      <th
        className={`${styles.table_th} ${sorter?.type === 'expiry_date' ? styles.active : ''}`}
        onClick={changeSort}
        name="expiry_date"
        scope="col"
      >
        Expiration date
        <ArrowDown
          className={`${styles.sorter_arrow} ${
            !sorter.asc && sorter?.type === 'expiry_date' ? styles.desc : ''
          } `}
        />
      </th>
      <th
        className={`${styles.table_th} ${sorter?.type === 'certified' ? styles.active : ''}`}
        onClick={changeSort}
        name="certified"
        scope="col"
      >
        Certificate
        <ArrowDown
          className={`${styles.sorter_arrow} ${
            !sorter.asc && sorter?.type === 'certified' ? styles.desc : ''
          } `}
        />
      </th>
    </tr>
  );
}

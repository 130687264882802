import React from 'react';
import { Questions, Responses, Settings } from './components/PagesView';

export const tabNames = {
  questions: 'Questions',
  responses: 'Responses',
  settings: 'Settings'
};

const links = [
  {
    value: tabNames.questions,
    path: '/quiz/questions',
    page: <Questions />
  },
  {
    value: tabNames.responses,
    path: '/quiz/responses',
    page: <Responses />
  },
  {
    value: tabNames.settings,
    path: '/quiz/settings',
    page: <Settings />
  }
];

export default links;

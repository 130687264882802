import React, { useState, useEffect, Suspense } from 'react';
import styles from './SinglePost.module.scss';
import { Loader, ProfilePic } from 'components/ui';
import { isImageURL } from 'helpers';
import Poll from 'components/molecules/Poll/Poll';
import { getS3Url } from 'helpers';
import Slider from 'react-slick';
import Next from '../../../assets/icons/next.svg?react';
import Prev from '../../../assets/icons/prev.svg?react';
import Close from '../../../assets/icons/close.svg?react';
import EmployeeCard from 'components/molecules/EmployeeCard/EmployeeCard';
import { Modal } from 'components/modals';
import moment from 'moment';
import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';
function SinglePost({ employeeName, employeeImage, poll, ...rest }) {
  const initialSlide = Math.max(0, rest.media.length - 3);
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
  const [showMedia, setShowMedia] = useState(false);
  const handleSliderToggle = () => {
    if (isSliderOpen) {
      setCurrentSlide(initialSlide);
    }
    setSliderOpen(!isSliderOpen);
    toggleFullscreen();
  };

  const handleMediaClick = (index) => {
    setSelectedMediaIndex(index);
    setShowMedia(true);
  };
  const toggleFullscreen = () => {
    if (document.fullscreenEnabled) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setSliderOpen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div className={styles.singlePostContainer}>
        {showMedia && selectedMediaIndex !== null && (
          <Modal extraClass="mediaModal" onClose={() => setShowMedia(false)}>
            {(() => {
              const selectedMedia = rest.media[selectedMediaIndex];
              const url = getS3Url(selectedMedia.link);
              const extension = url.split('.').pop();
              const urlIsImage = isImageURL(extension.toLowerCase());

              if (urlIsImage) {
                return (
                  <div className={styles.selectedMedia}>
                    <img src={url} alt="media" />
                    <Close onClick={() => setShowMedia(false)} />
                  </div>
                );
              } else {
                return (
                  <div className={styles.selectedMedia}>
                    <video controls src={url} />
                    <Close onClick={() => setShowMedia(false)} />
                  </div>
                );
              }
            })()}
          </Modal>
        )}
        <div className={styles.container}>
          <div className={styles.profilerow}>
            <div className={styles.profileinfo}>
              <ProfilePic image={employeeImage} width={45} height={45} extraClass="cardprofile" />
              <div className={styles.info}>
                <div className={styles.name}>
                  <h3>{employeeName}</h3>
                </div>
                <p>{`${moment(new Date(rest?.time), 'YYYYMMDD').fromNow()}`}</p>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            {rest?.children}
            {rest?.cover && (
              <div className={styles.cover}>
                <img src={getS3Url(rest.cover)} alt="cover image" />
              </div>
            )}
            {rest?.media?.length ? (
              <SliderContainer breakpoint={1440} breakpointSlide={3} slides={1}>
                {rest.media.map((media, index) => {
                  const url = getS3Url(media.link);
                  const extension = url.split('.').pop();
                  const urlIsImage = isImageURL(extension.toLowerCase());
                  return (
                    <div key={index}>
                      {urlIsImage ? (
                        <img
                          style={{
                            cursor: 'grab',
                            width: '100%',
                            borderRadius: '20px',
                            objectFit: 'contain',
                            height: '500px'
                          }}
                          src={url}
                          onClick={() => handleMediaClick(index)}
                          alt="dynamic image"
                        />
                      ) : (
                        <video
                          style={{
                            cursor: 'grab',
                            width: '100%',
                            borderRadius: '20px',
                            objectFit: 'cover',
                            height: '500px'
                          }}
                          controls
                          src={url}
                        />
                      )}
                    </div>
                  );
                })}
              </SliderContainer>
            ) : (
              <></>
            )}
            {/* POLL HERE */}
            {poll?.id ? (
              <>
                <Poll
                  questions={poll?.questions}
                  postId={rest.postId}
                  pollId={poll?.id}
                  category={rest.category}
                />
              </>
            ) : (
              ''
            )}
            {rest?.profiles?.length ? (
              <div
                className={
                  rest.profiles.length >= 5
                    ? `${styles.fiveProfilesRow} ${styles.profiles}`
                    : styles.profiles
                }
              >
                {rest.profiles.map((profile, index) => {
                  return (
                    <div className={styles.mediacontainer} key={profile.id}>
                      <EmployeeCard
                        key={profile.id}
                        {...profile}
                        disableCheckbox={true}
                        extraClass={
                          rest.profiles.length >= 5
                            ? 'fiveCards'
                            : rest.profiles.length < 5
                              ? 'profileCard'
                              : 'grid-card'
                        }
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export default SinglePost;

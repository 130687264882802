import styles from './UploadArea.module.scss';

const UploadArea = ({ getRootProps, getInputProps, isDragActive, img }) => {
    const usedImg = img ?
        (img + `?cacheBust=${new Date().getTime()}`) :
        'https://res.cloudinary.com/dsg2ktuqk/image/upload/c_scale,w_600/v1728897073/Templates/remy_loz-3S0INpfREQc-unsplash_iwa1nd.webp';

    return (
        <div {...getRootProps()} className={`${styles.uploadArea} ${isDragActive ? styles.is_dragging : ''}`}>
            <div className={styles.backgroundBanner}>
                {
                    !img && (
                        <div className={styles.bannerOverlay} />
                    )
                }
                <img src={usedImg} alt="content-img" className={styles.bannerImage} />
                <span id='content-img-loader' className={styles.banner_load_bar} />
            </div>
            <input {...getInputProps()} />
            {
                !img && (
                    <>
                        {isDragActive ? (
                            <p>Drop the files here...</p>
                        ) : (
                            <>
                                <p>Add your course main image <br /> here to start uploading</p>
                                <span>-or-</span>
                                <button className={styles.browseButton}>Browse files</button>
                            </>
                        )}
                    </>
                )
            }
        </div>
    );
}

export default UploadArea;

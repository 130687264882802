import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ series, labels }) => {
  const [state, setState] = useState({
    series: series,
    options: {
      chart: {
        fontFamily: 'Raleway',
        type: 'pie',
        foreColor: '#fff'
      },
      fill: {
        colors: ['#0077b6', '#1e90ff', '#4169e1', '#6495ed', '#87ceeb', '#add8e6']
      },
      labels: labels,
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -10
          }
        }
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }
      },
      dataLabels: {
        formatter: (val, opts) => {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(1) + '%'];
        }
      },
      legend: {
        show: false,
        position: 'bottom',
        fontFamily: 'Raleway',
        labels: {
          colors: '#fff'
        },
        itemMargin: {
          horizontal: 10,
          vertical: 10
        }
      }
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="pie" />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;

import { useGetEducationByUserIdQuery } from 'api/individual-profile';
import { useEducationUtils } from 'components/organisms/Profile/hooks/templates';
import { LoadMoreButton, Loader } from 'components/ui';
import { isSharedMode } from 'helpers';
import { useHandleRtkPagination } from 'hooks';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUserIdSelector } from 'store/login.slice';
import styles from './EducationsSection.module.scss';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {
  convertStringToParagraph,
  isJsonString,
  serialize
} from 'components/atoms/MWEditor/Helpers';
import Options from 'components/atoms/Options/Options';
import CreateEducationModal from './CreateEducationModal';
import { EDUCATION_DEGREES } from 'constants';
import { EDUCATION_GRADES } from 'constants';
import EducationCard from './EducationCard';
import star from 'assets/icons/education.svg?react';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import { useTranslation } from 'react-i18next';

const defaultSectionTitle = 'Educations';
function EducationsSectionView({
  data: educations,
  hasNextPage,
  isFetching,
  setPage,
  sectionTitle,
  isPlaceholdersActive
}) {
  const userId = useSelector(getCurrentUserIdSelector);
  const [create, setCreate] = useState(false);

  const {
    onUpdateSubmit,
    onDeleteSubmit,
    onCreateSubmit,
    isSaving,
    isCurrentUserProfile,
    staticDesc,
    onDescriptionSave,
    editDescription,
    setDescription,
    setUpdatedDesc,
    educationDesc: educationDescription
  } = useEducationUtils({ userId });

  return (
    <div className={styles.edcontainer}>
      {isCurrentUserProfile && (
        <Options
          extraClass="skillsOptions"
          handleDiscard={() => setDescription(false)}
          handleAdd={() => setCreate(true)}
          handleEdit={() => setDescription(true)}
          handleSave={onDescriptionSave}
          edit={editDescription}
        />
      )}
      <div className={styles.education}>
        <h3 className={styles.title} id="educations-title">
          {sectionTitle ?? defaultSectionTitle}
        </h3>
        <div className={styles.desc}>
          {editDescription ? (
            <div className={styles.editDesc}>
              <MWEditorLite
                data={
                  educationDescription
                    ? isJsonString(educationDescription)
                      ? JSON.parse(educationDescription)
                      : convertStringToParagraph(educationDescription)
                    : undefined
                }
                placeholderText="Describe your experience"
                setData={setUpdatedDesc}
              />
            </div>
          ) : (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: educationDescription
                  ? serialize(
                      educationDescription
                        ? isJsonString(educationDescription)
                          ? JSON.parse(educationDescription)
                          : convertStringToParagraph(educationDescription)
                        : undefined
                    )
                  : staticDesc
              }}
            ></div>
          )}
        </div>
        <div className={styles.infowrapper}>
          {educations?.map((item) => {
            return (
              <EducationCard
                isPlaceholdersActive={isPlaceholdersActive}
                key={item.id}
                img={star}
                institution={item.institution}
                degree={item.degree}
                specialization={item.specialization}
                grade={item.grade}
                location={item.location}
                ongoing={item.ongoing}
                from={item.from}
                to={item.to}
                id={item.id}
                degreesList={EDUCATION_DEGREES}
                isCurrentUserProfile={isCurrentUserProfile}
                gradesList={EDUCATION_GRADES}
                onUpdateSubmit={onUpdateSubmit}
                onDeleteSubmit={onDeleteSubmit}
              />
            );
          })}
          {isFetching && <Loader />}
          <LoadMoreButton
            hidden={!hasNextPage}
            onClick={() => setPage((pre) => pre + 1)}
            disabled={isFetching || !hasNextPage}
          />
        </div>
      </div>

      <CreateEducationModal
        open={create}
        onClose={() => setCreate(false)}
        onSaveEducation={(edu) => onCreateSubmit(edu)}
        isSaving={isSaving}
        degreesList={EDUCATION_DEGREES}
        gradesList={EDUCATION_GRADES}
      />
    </div>
  );
}

function EducationsSection({ ...args }) {
  const [sharedDate] = useGetSharedProfile();
  const { t } = useTranslation();

  const { result, page, setPage } = useHandleRtkPagination({
    queryName: 'getEducationByUserId',
    rtkQuery: useGetEducationByUserIdQuery,
    rtkQueryArgs: { userId: args?.userId },
    rtkQueryOptions: {
      skip: isSharedMode()
    }
  });
  const { data, isLoading, isError, isFetching, error } = !isSharedMode()
    ? result
    : (sharedDate ?? {});
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  // HOOK here
  // const {data, isLoading, IsError} = useGetSomething({...arges})
  const educations = isSharedMode() ? data?.bio?.educations : data?.result;
  const isPlaceholdersActive = educations?.length === 0;

  return (
    <EducationsSectionView
      data={educations && educations?.length !== 0 ? educations : placeHolder}
      isPlaceholdersActive={isPlaceholdersActive}
      hasNextPage={data?.page?.hasNextPage}
      setPage={setPage}
      isFetching={isFetching}
      sectionTitle={t(args?.sectionTitle)}
    />
  );
}
export default EducationsSection;

const placeHolder = [
  {
    id: 1,
    institution: 'University of Lagos - Placeholder',
    degree: EDUCATION_DEGREES[0].text,
    specialization: 'Computer Science',
    grade: EDUCATION_GRADES[0].text,
    location: 'Lagos, Nigeria',
    ongoing: false,
    from: '2020-01-04T18:56:34.000Z',
    to: '2024-04-04T18:56:34.000Z'
  },
  {
    id: 2,
    institution: 'University of California - Placeholder',
    degree: EDUCATION_DEGREES[1].text,
    specialization: 'Information Technology',
    grade: EDUCATION_GRADES[1].text,
    location: 'California, USA',
    ongoing: false,
    from: '2016-01-04T18:56:34.000Z',
    to: '2020-04-04T18:56:34.000Z'
  },

  {
    id: 3,
    institution: 'Harvard University - Placeholder',
    degree: EDUCATION_DEGREES[2].text,
    specialization: 'Industrial Chemistry',
    grade: EDUCATION_GRADES[2].text,
    location: 'Massachusetts, USA',
    ongoing: false,
    from: '2020-01-04T18:56:34.000Z',
    to: '2024-04-04T18:56:34.000Z'
  }
];

import React from 'react';
import styles from './CourseReviews.module.scss';
import ReviewCard from 'components/atoms/ReviewCard/ReviewCard';
import { courseReviewData } from 'constants/dummy';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Arrow from 'assets/icons/arrowSlider.svg?react';

export default function CourseReviews() {
  const filterContent = courseReviewData.map(({ url, by, date, ...rest }) => rest);
  const cardsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <div>
        <Arrow />
      </div>
    ),
    nextArrow: (
      <div>
        <Arrow />
      </div>
    )
  };
  return (
    <div className={`${styles.container} reviewsSlider`}>
      <div className={styles.cardList}>
        <h2>Reviews</h2>
        <Slider {...cardsSlider}>
          {filterContent?.map((d, i) => (
            <ReviewCard extraClass="courseReview" {...d} key={i} />
          ))}
        </Slider>
      </div>
    </div>
  );
}

class FetchWrapper {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.requestInterceptors = [];
    this.responseInterceptors = [];
    this.errorInterceptors = [];
  }

  addRequestInterceptor(interceptor) {
    this.requestInterceptors.push(interceptor);
  }

  addResponseInterceptor(interceptor) {
    this.responseInterceptors.push(interceptor);
  }

  addErrorInterceptor(interceptor) {
    this.errorInterceptors.push(interceptor);
  }

  async applyRequestInterceptors(options) {
    let modifiedOptions = options;
    for (const interceptor of this.requestInterceptors) {
      modifiedOptions = await interceptor(modifiedOptions);
    }
    return modifiedOptions;
  }

  async applyResponseInterceptors(response) {
    let modifiedResponse = response;
    for (const interceptor of this.responseInterceptors) {
      modifiedResponse = await interceptor(modifiedResponse);
    }
    return modifiedResponse;
  }

  async applyErrorInterceptors(error) {
    let modifiedError = error;
    for (const interceptor of this.errorInterceptors) {
      modifiedError = await interceptor(modifiedError);
    }
    return modifiedError;
  }

  async request(endpoint, options = {}) {
    const url = `${this.baseURL}${endpoint}`;
    let config = await this.applyRequestInterceptors(options);

    const defaultHeaders = {
      'Content-Type': 'application/json'
    };

    config = {
      ...config,
      headers: {
        ...defaultHeaders,
        ...config.headers
      },
      body: config.body ? JSON.stringify(config.body) : undefined
    };

    try {
      const response = await fetch(url, config);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      let responseData = await response.json();
      responseData = await this.applyResponseInterceptors(responseData);
      return responseData;
    } catch (error) {
      const handledError = await this.applyErrorInterceptors(error);
      console.error('Fetch error:', handledError);
      throw handledError;
    }
  }

  get(endpoint, options = {}) {
    return this.request(endpoint, { ...options, method: 'GET' });
  }

  post(endpoint, body, options = {}) {
    return this.request(endpoint, { ...options, method: 'POST', body });
  }

  put(endpoint, body, options = {}) {
    return this.request(endpoint, { ...options, method: 'PUT', body });
  }

  patch(endpoint, body, options = {}) {
    return this.request(endpoint, { ...options, method: 'PATCH', body });
  }

  delete(endpoint, options = {}) {
    return this.request(endpoint, { ...options, method: 'DELETE' });
  }
}

// Usage example:
const fetchInstance = new FetchWrapper('');

// Adding a request interceptor
fetchInstance.addRequestInterceptor(async (config) => {
  config.headers = {
    ...config.headers
  };
  return config;
});

// Adding a response interceptor
fetchInstance.addResponseInterceptor(async (response) => {
  // Modify response here
  return response.data;
});

// Adding an error interceptor
fetchInstance.addErrorInterceptor(async (error) => {
  // Handle error globally
  console.error('Global error handling:', error);
  return Promise.reject(error);
});

export default fetchInstance;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import { useTour } from '@reactour/tour';

const Button = forwardRef(
  (
    {
      marginBottom,
      bgColor = '#41414185',
      color = 'white',
      children,
      onClick,
      span,
      extraClass,
      colorBgSwitcher,
      guideButton,
      buttonBorder,
      ...rest
    },
    ref
  ) => {
    const { currentStep, setCurrentStep, setIsOpen, isOpen } = useTour();

    const handleClick = (e) => {
      if (onClick) {
        onClick(e);
      }
      if (guideButton && isOpen) {
        setCurrentStep(currentStep + 1);
      }
    };
    let content = null;
    if (span) {
      content = (
        <span
          {...rest}
          ref={ref}
          className={`${styles.button} ${styles[rest?.extraClass]} ${rest?.hardCodedClass}`}
          style={{ background: bgColor, color }}
          onClick={handleClick}
        >
          {children}
        </span>
      );
    } else {
      content = (
        <button
          {...rest}
          ref={ref}
          className={`${styles.button} ${styles[rest?.extraClass]} ${rest?.hardCodedClass} ${
            colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''
          }`}
          style={{ background: bgColor, color, ...rest?.style, marginBottom }}
          onClick={handleClick}
        >
          {children}
        </button>
      );
    }

    return content;
  }
);

Button.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  span: PropTypes.bool
};

export default Button;

import React, { useEffect, useState } from 'react';
import styles from './QueryableProfiles.module.scss';
import { apiSlice } from 'api/apiSlice';
import { useDispatch } from 'react-redux';
import { removeHeightSetWidthSvgIcon } from 'helpers';
import Group from 'assets/icons/people.svg?react';
import CreateModal from '../CreateModal/CreateModal';
import { Input } from 'components/form';
import { getUserStatusSelector } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { useDebounce } from 'hooks';
import SelectableItems from 'components/atoms/SelectableItems/SelectableItems';
import { getCurrentCompanyIdSelector } from 'store/login.slice';
import { Loader } from 'components/ui';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { BasicTooltip } from 'components/atoms/Tooltip/Tooltip';
export default function QueryableProfiles({ rtkQueryName, setProfiles, selection }) {
  const userStatus = useSelector(getUserStatusSelector);
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selection);
  const [keyword, setKeyword] = useState('');
  const { currentStep, setCurrentStep } = useTour();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useDebounce(() => dispatchQuery(keyword), 500, [keyword]);
  const dispatchQuery = async (keyword) => {
    if (!keyword || !rtkQueryName) {
      return;
    }
    const result = dispatch(apiSlice.endpoints[rtkQueryName].initiate({ keyword, companyId }));
    return () => result.unsubscribe();
  };
  const { data, isLoading: isFetching } = useSelector(
    apiSlice.endpoints[rtkQueryName].select({ keyword, companyId })
  );

  const addIfNotSelected = (item) => {
    setSelectedItems([...selectedItems, item]);
  };

  const removeFromSelection = (item) => {
    setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
  };

  return (
    <div className={styles.profileQuery} data-tooltip-id="profiles-tooltip">
      <div className={styles.iconWrapper}>
        <Group
          className={styles.addpeople}
          onClick={() => {
            setShowModal(true);
            setCurrentStep(currentStep + 1);
          }}
        />
      </div>
      {showModal && (
        <CreateModal
          extraClass="profileModal"
          guidedButton
          handleClose={() => {
            setShowModal(false);
            setCurrentStep(currentStep + 1);
          }}
          clickEvent={() => {
            setProfiles(selectedItems);
            setKeyword('');
            setShowModal(false);
            setCurrentStep(currentStep + 1);
          }}
        >
          <>
            <h1>{t('Add Profile')}</h1>
            <SelectableItems
              selectableItems={selectedItems}
              removeFromSelection={removeFromSelection}
            />
            <Input
              autoFocus
              placeholder={t('Search')}
              onChange={(e) => setKeyword(e.target.value.trim())}
            />
            <SelectableItems selectableItems={data?.result} addIfNotSelected={addIfNotSelected} />
            {isFetching && <Loader />}
          </>
        </CreateModal>
      )}
      <BasicTooltip id={`profiles-tooltip`} variant={'dark'} content={'Profiles'} />
    </div>
  );
}

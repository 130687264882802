import React, { useState } from 'react';
import styles from './styles.module.scss';
import data from './data_dummy';
import TableHeaders from './TableHeaders';
import TableBody from './TableBody';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePagination } from 'components/organisms/UpDashboard/hooks/usePagination';

export default function index() {
  const [sorter, setSorter] = useState({
    type: 'name',
    asc: true
  });

  const CONTENT_COUNT = 7;


  const sortedData = [...data].sort((a, b) => {
    const { type, asc } = sorter;
    let sortOrder = asc ? 1 : -1;

    if (type === 'name') {
      return a.name.localeCompare(b.name) * sortOrder;
    } else if (type === 'spent_time' || type === 'progress') {
      return (a[type] - b[type]) * -1 * sortOrder;
    } else if (type === 'purchase_date' || type === 'expiry_date') {
      return (new Date(a[type]) - new Date(b[type])) * -1 * sortOrder;
    } else if (type === 'certified') {
      if (a.progress === b.progress) {
        return a.certified === b.certified ? 0 : a.certified ? -1 : 1;
      }
      return (b.progress - a.progress) * sortOrder;
    }
    return 0;
  });

  const {
    paginatedData,
    currentPage,
    totalPages,
    pageNumbers,
    navigateToPage,
  } = usePagination(sortedData, CONTENT_COUNT);

  const handleCertification = (student) => {
    const { certified } = student || {};
    if (certified) {
      // show cert popup
    } else {
      // show give cert popup
    }
  };

  return (
    <div className={styles.container}>
      <table className={styles.part_table} cellSpacing="0">
        {/* <caption className={styles.table_header}>Statement Summary</caption> */}
        <thead className={styles.table_thead}>
          <TableHeaders sorter={sorter} setSorter={setSorter} />
        </thead>
        <tbody className={styles.table_tbody}>
          {paginatedData?.map((entry) => (
            <TableBody key={entry?._id} entry={entry} handleCertification={handleCertification} />
          ))}
        </tbody>
      </table>
      <div className={styles.bottom_control_section}>
        <ul className={styles.pagination_container}>
          {pageNumbers.map((pageNumber) => (
            <li
              key={pageNumber}
              className={`${styles.page_number} ${pageNumber === currentPage ? styles.active : ''}`}
            >
              <button className={styles.page_btn} onClick={() => navigateToPage(pageNumber)}>
                {pageNumber}
              </button>
            </li>
          ))}
        </ul>
        <div className={styles.export_btns}>{/* */}</div>
      </div>
    </div>
  );
}

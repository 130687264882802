import React from 'react';
import styles from './CheckInput.module.scss';

function CheckInput({ onChange, checked, label, id, name, ...rest }) {
  const getTeamName = (name) => {
    if (name.length > 20) {
      return `${name.substring(0, 10)}...`;
    } else return name;
  };
  return (
    <label htmlFor={id} className={`${styles.checkcontainer} ${styles[rest?.extraClass]}`}>
      <input {...rest} id={id} name={name} onChange={onChange} checked={checked} type="checkbox" />
      <span className={styles.checkmark} />
      {label && <span title={label}>{getTeamName(label)}</span>}
    </label>
  );
}
export default CheckInput;

import React from 'react';
import styles from './AwardCard.module.scss';
import PropTypes from 'prop-types';
import PdfPng from 'assets/images/pdf.webp';
import Trash from 'assets/icons/trashafter.svg?react';
import Award from 'assets/icons/award.svg?react';
import { getS3Url } from 'helpers/s3Handler';

// TODO: use BlobCard component
export default function AwardCard({
  icon = <Award />,
  edit,
  imagePath,
  onImageSelected,
  onToggleSelection,
  isSelected
}) {
  const awardName = imagePath.split('--')?.at(1) || '';
  const awardFileExtension = imagePath.split('.').at(-1) || '';

  const handleImageClick = (event) => {
    event.preventDefault();
    if (!edit) {
      if (awardFileExtension === 'pdf') {
        window.open(getS3Url(imagePath), '_blank');
      } else {
        onImageSelected(getS3Url(imagePath));
      }
    } else {
      onToggleSelection();
    }
  };
  return (
    <label
      title={awardName}
      onClick={handleImageClick}
      style={{
        backgroundImage: `url(${awardFileExtension === 'pdf' ? PdfPng : getS3Url(imagePath)})`
      }}
      className={edit ? `${styles.award} ${styles.awardEdit}` : styles.award}
    >
      {edit && (
        <>
          <input type="checkbox" checked={isSelected} onChange={onToggleSelection} />
        </>
      )}
      <p className={styles.name}>
        {awardName.length > 25 ? `${awardName.slice(0, 25)}...` : awardName}
      </p>
    </label>
  );
}

AwardCard.propTypes = {
  icon: PropTypes.object,
  date: PropTypes.string,
  award: PropTypes.string,
  city: PropTypes.string
};

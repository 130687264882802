import React, { useEffect, useState } from 'react';
import styles from './PostCard.module.scss';
import { ProfilePic } from 'components/ui';
import PropTypes from 'prop-types';
import Slider from 'components/atoms/Slider/Slider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AddCommentEditor from '../AddCommentEditor/AddCommentEditor';
import Comment from '../../../assets/icons/comment_svg.svg?react';
import { CustomDropdown } from 'components/form';
import TripleDots from 'assets/icons/dropdown.svg?react';
import { useDeletePostByIdMutation, useReactToPostMutation } from 'api/social-board';
import CommentBoard from 'components/molecules/CommentBoard/CommentBoard';
import MWEditor from 'components/atoms/MWEditor';
import { deleteFromS3, getS3Url } from 'helpers/s3Handler';
import { isImageURL } from 'helpers/index.js';
import { useGetCommentsByPostIdQuery } from 'api/social-board/commentSlice';
import { handleAsync } from 'helpers';
import EmployeeCard from '../EmployeeCard/EmployeeCard';
import DeleteModal from '../DeleteModal/DeleteModal';
import Heart from '../../../assets/icons/heart.svg?react';
import Share from 'assets/icons/share_svg.svg?react';
import Poll from '../Poll/Poll';
import { Button } from 'components/ui';
import { Link } from 'react-router-dom';
import { useGetUrlQuery, useHandleRtkPagination, useScrollToItem, useUploadBlobs } from 'hooks';
import ResultSharePostModal from 'components/ui/ResultSharePostModal/ResultSharePostModal';
import SharePostModal from 'components/ui/SharePostModal/SharePostModal';
import { useSharePostByIdMutation } from 'api/social-board';
import { getAppUrl } from 'helpers/index.js';
import { useSelector } from 'react-redux';
import { getCurrentUserProfileId } from 'store/login.slice';
import moment from 'moment';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';
import SliderContainer from 'components/atoms/SliderContainer/SliderContainer';

export default function PostCard({ employeeName, employeeImage, initialComments, poll, ...rest }) {
  const userProfileId = useSelector(getCurrentUserProfileId);
  const activePost = useGetUrlQuery('entityId') === rest.postId;
  const ownerProfileId = rest.ownerProfileId;
  const [commentsVisible, setCommentsVisible] = useState(
    initialComments?.length > 0 ? true : false
  );
  const [hasReacted, setHasReacted] = useState(
    rest?.reactions?.some((r) => r.profileId === userProfileId)
  );
  const [comment, setComment] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletePostById] = useDeletePostByIdMutation();
  const [isSliderOpen, setSliderOpen] = useState(false);
  const initialSlide = Math.max(0, rest.media.length - 3);
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [showMedia, setShowMedia] = useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [rtkCommentsEndpoint, setRtkCommentsEndpoint] = useState(false);
  const [shareResult, setShareResult] = useState({
    open: false,
    link: ''
  });
  const [sharePostById] = useSharePostByIdMutation();
  const [reactToPost] = useReactToPostMutation();
  const { uploadBlobs, isUploading: isFilesUploading, totalUploadProgress } = useUploadBlobs();
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;

  const handleMediaClick = (index) => {
    setSelectedMediaIndex(index);
    setShowMedia(true);
  };
  const toggleFullscreen = () => {
    if (document.fullscreenEnabled) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setSliderOpen(false);
      }
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleSliderToggle = () => {
    if (isSliderOpen) {
      setCurrentSlide(initialSlide);
    }
    setSliderOpen(!isSliderOpen);
    toggleFullscreen();
  };

  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getCommentsByPostId',
    rtkQuery: useGetCommentsByPostIdQuery,
    rtkQueryArgs: {
      postId: rest.postId
    },
    rtkQueryOptions: {
      // ? is used because when creating the post, the initial comments doesn't exist yet
      skip: !rtkCommentsEndpoint
      // if initial comments,
    }
  });

  const { data, isFetching, isLoading, isError, isSuccess } = result;
  const reactions = [
    {
      id: 1,
      text: 'Great direction',
      onClick: () => {
        setComment('Great direction');
      }
    },
    {
      id: 2,
      text: 'Inspiringly good',
      onClick: () => {
        setComment('Inspiringly good');
      }
    },
    {
      id: 3,
      text: 'Take a chill pill',
      onClick: () => {
        setComment('Take a chill pill');
      }
    },
    {
      id: 4,
      text: 'Me like',
      onClick: () => {
        setComment('Me like');
      }
    }
  ];

  const toggleComments = () => setCommentsVisible(!commentsVisible);

  const toggleShare = () => setShareModalOpen(true);

  const array = [
    {
      id: 1,
      text: `like (${rest.reactions?.length})`,
      icon: <Heart />,
      onClick: () => {
        setHasReacted(!hasReacted);
        reactToPost({
          postId: rest.postId,
          reaction: { type: 'like', profileId: userProfileId },
          category: rest.category
        });
      },
      clickedColor: hasReacted ? 'red' : undefined
    },
    {
      id: 2,
      text: commentsVisible ? 'Hide Comments' : `View Comments (${rest.commentCount})`,
      icon: <Comment />,
      onClick: toggleComments
    },
    {
      id: 3,
      text: 'Share',
      icon: <Share />,
      onClick: toggleShare
    }
  ];

  if (userTier === APP_TIERS.LITE) {
    array.splice(1, 1);
  }

  const EdittingOptions = [
    {
      id: 1,
      text: 'Edit',
      onClick: () => {
        setEditMode(!editMode);
      }
    },
    {
      id: 2,
      text: 'Delete',
      onClick: async () => {
        const { media: postMedia } = rest;
        const result = setDeleteModal(true);
        if (!result) return;
        const resultF = await handleAsync(
          deletePostById({
            postId: rest.postId,
            category: rest.category
          }).unwrap()
        );
        if (resultF[0]) {
          handleAsync(deleteFromS3(postMedia));
        }
      }
    }
  ];

  useScrollToItem(rest.postId, activePost);

  if (editMode) {
    return (
      <div className={styles.editcontainer}>
        <MWEditor
          toBeUpdated={JSON.parse(rest?.content)}
          mediaLinks={rest.media}
          postedProfiles={rest.profiles}
          cancel={() => {
            setEditMode(!editMode);
          }}
          postId={rest.postId}
          key={rest.postId}
          category={rest.category}
          postPollToBeUpdated={poll}
          coverToBeUpdated={rest?.cover}
        />
      </div>
    );
  } else {
    return (
      <>
        <div
          style={activePost ? { border: '1px solid white' } : null}
          className={styles.container}
          id={rest.postId}
        >
          <div className={styles.profilerow}>
            <div className={styles.profileinfo}>
              <ProfilePic
                clickable
                profileId={ownerProfileId}
                image={employeeImage}
                width={45}
                height={45}
                extraClass="cardprofile"
              />
              <div className={styles.info}>
                <div className={styles.name}>
                  <Link color="white" to={`/profile/${ownerProfileId}`} replace={true}>
                    <h3>{employeeName}</h3>
                  </Link>
                </div>
                <p>
                  {rest?.updatedAt
                    ? `Edited ${moment(new Date(rest?.updatedAt), 'YYYYMMDD').fromNow()}`
                    : moment(new Date(rest?.createdAt), 'YYYYMMDD').fromNow()}
                </p>
              </div>
            </div>
            {userProfileId === ownerProfileId ? (
              <div className={styles.options}>
                <CustomDropdown
                  isEditor={false}
                  Icon={<TripleDots />}
                  list={EdittingOptions}
                  extraClass={'editOptionsDropDown'}
                  clickable={true}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.content}>
            <div style={{ marginBottom: '10px' }}>{rest?.children}</div>
            {rest?.cover && (
              <div className={styles.cover}>
                <img src={rest.cover} alt="cover image" />
              </div>
            )}
            <div className={styles.presentation}>
              {rest?.presentation.map((item, index) => {
                return (
                  <img
                    src={item.img}
                    alt="img"
                    key={item.id}
                    onClick={() => handleMediaClick(index)}
                  />
                );
              })}
            </div>

            {rest?.media?.length ? (
              <SliderContainer breakpoint={1440} breakpointSlide={3} slides={1}>
                {rest.media.map((media, index) => {
                  const url = getS3Url(media.link);
                  const extension = url.split('.').pop();
                  const urlIsImage = isImageURL(extension.toLowerCase());
                  return (
                    <div key={index}>
                      {urlIsImage ? (
                        <img
                          style={{
                            cursor: 'grab',
                            width: '100%',
                            borderRadius: '20px',
                            objectFit: 'contain',
                            height: '500px'
                          }}
                          src={url}
                          onClick={() => handleMediaClick(index)}
                          alt="dynamic image"
                        />
                      ) : (
                        <video
                          style={{
                            cursor: 'grab',
                            width: '100%',
                            borderRadius: '20px',
                            objectFit: 'cover',
                            height: '500px'
                          }}
                          controls
                          src={url}
                        />
                      )}
                    </div>
                  );
                })}
              </SliderContainer>
            ) : (
              <></>
            )}
            {poll?.id ? (
              <>
                <Poll
                  questions={poll?.questions}
                  postId={rest?.postId}
                  pollId={poll?.id}
                  category={rest?.category}
                />
              </>
            ) : (
              ''
            )}

            {rest?.profiles?.length ? (
              <div
                className={
                  rest.profiles.length >= 5
                    ? `${styles.fiveProfilesRow} ${styles.profiles}`
                    : styles.profiles
                }
              >
                {rest.profiles.map((profile, index) => {
                  return (
                    <div className={styles.mediacontainer} key={profile.id || profile._id}>
                      <EmployeeCard
                        showProfileButton
                        key={profile.id || profile._id}
                        {...profile}
                        id={profile.id || profile._id}
                        disableCheckbox={true}
                        extraClass={
                          rest.profiles.length >= 5
                            ? 'fiveCards'
                            : rest.profiles.length < 5
                              ? 'profileCard'
                              : 'grid-card'
                        }
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.buttons}>
            {array.map((item) => {
              return (
                <Button
                  color={item?.clickedColor ? item.clickedColor : '#F8F9FB'}
                  key={item.id}
                  onClick={item?.onClick}
                >
                  {' '}
                  {item?.icon} {item?.text}{' '}
                </Button>
              );
            })}
          </div>
          {commentsVisible && (
            <CommentBoard
              isLoading={isLoading}
              isFetching={isFetching}
              isFilesUploading={isFilesUploading}
              uploadingData={totalUploadProgress}
              // initially we show the initial comments if there are
              comments={data?.result || initialComments}
              thereIsNextPage={
                // if the load more button is clicked we show the comments fetched by getCommentsByPostId, otherwise we show the initial comments
                rtkCommentsEndpoint ? data?.page?.hasNextPage : initialComments?.length > 1
              }
              setCurrentPageSize={() => {
                // if the load more wasn't clicked, we won't change the page size
                rtkCommentsEndpoint && setPage((prev) => prev + 1);
                !rtkCommentsEndpoint && setRtkCommentsEndpoint(true);
              }}
            />
          )}
          <AddCommentEditor
            postCurrentPage={rest.postCurrentPage}
            placeholder={"What's on your mind, "}
            postId={rest.postId}
            comment={comment}
            setComment={setComment}
            showComments={() => {
              setRtkCommentsEndpoint(true);
              setCommentsVisible(true);
            }}
            uploadBlobs={uploadBlobs}
          />
          <div className={styles.reactions}>
            {reactions.map((item) => {
              return (
                <Button bgColor="#232323" color="#F8F9FB" key={item.id} onClick={item.onClick}>
                  {' '}
                  {item.text}{' '}
                </Button>
              );
            })}
          </div>
          {/* <PostReactions
            postId={rest.postId}
            reactionsCount={rest.reactions?.length}
            activeOne={
              rest.reactions.find((reaction) => reaction.profileId === userProfileId)?.type
            }
            onClick={({ text }) => {
              const reactionDto = { type: text, profileId: userProfileId };
              reactToPost({
                postId: rest.postId,
                reaction: reactionDto,
                category: rest.category
              });
            }}
            extraClass={styles['post-reactions']}
          /> */}
          {deleteModal && (
            <DeleteModal
              title="Are you sure you want to delete this post ?"
              handleClose={() => setDeleteModal(false)}
              handleDelete={async () => {
                const [status] = await handleAsync(
                  deletePostById({
                    postId: rest.postId,
                    category: rest.category
                  }).unwrap()
                );
                if (status) {
                  const { media: postMedia } = rest;
                  handleAsync(deleteFromS3(postMedia.map((media) => media.link)));
                }
              }}
            />
          )}
        </div>
        {/* {showMedia && selectedMediaIndex !== null && (
          <Modal extraClass="mediaModal" onClose={() => setShowMedia(false)}>
            {(() => {
              const selectedMedia = rest.media[selectedMediaIndex];
              const url = getS3Url(selectedMedia.link);
              const extension = url.split('.').pop();
              const urlIsImage = isImageURL(extension.toLowerCase());

              if (urlIsImage) {
                return (
                  <div className={styles.selectedMedia}>
                    <img src={url} alt="media" />
                    <Close onClick={() => setShowMedia(false)} />
                  </div>
                );
              } else {
                return (
                  <div className={styles.selectedMedia}>
                    <video controls src={url} />
                    <Close onClick={() => setShowMedia(false)} />
                  </div>
                );
              }
            })()}
          </Modal>
        )} */}
        <SharePostModal
          openShareModal={shareModalOpen}
          onClose={() => {
            setShareModalOpen(false);
          }}
          onSubmit={async (shareDto) => {
            const status = await handleAsync(
              sharePostById({
                sharingData: {
                  ...shareDto,
                  sharedBy: userProfileId,
                  sharedPostId: rest.postId
                }
              }).unwrap()
            );
            if (status[0]) {
              const result = status[1];
              const { slug } = result;
              setShareResult({
                ...shareResult,
                open: true,
                link: `${getAppUrl()}/sharedPost?slug=${slug}`
              });
            }
          }}
        />
        <ResultSharePostModal
          value={shareResult.link}
          open={shareResult.open}
          onClose={() => {
            setShareResult({ ...shareResult, open: false });
          }}
        />
      </>
    );
  }
}

PostCard.propTypes = {
  employeeName: PropTypes.string,
  employeeImage: PropTypes.string,
  buttonsList: PropTypes.array,
  children: PropTypes.any,
  presentation: PropTypes.array,
  time: PropTypes.string,
  postCurrentPage: PropTypes.number
};

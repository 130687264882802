import React from 'react';
import styles from './styles.module.scss';

const Toggle = ({ checked, onChange, label }) => {
  return (
    <div className={styles.toggleContainer}>
      {label && <span className={styles.label}>{label}</span>}
      <label className={styles.switch}>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className={styles.slider}></span>
      </label>
    </div>
  );
};

export default Toggle;

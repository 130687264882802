import styles from './SkillList.module.scss';

const SkillList = ({ skills, onRemoveSkill }) => (
    <div className={styles.skillList}>
        {skills.map((skill, index) => (
            <div key={index} className={styles.skill}>
                <span>{skill}</span>
                <button className={styles.removeSkill} onClick={() => onRemoveSkill(index)}>
                    &times;
                </button>
            </div>
        ))}
    </div>
);

export default SkillList;

import React, { useLayoutEffect } from 'react';
import styles from './styles.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import links, { tabNames } from './constants';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import NavTabs from 'components/atoms/NavTabs/NavTabs';
import CourseBanner from 'components/atoms/CourseBanner/CourseBanner';
import AboutCourse from 'components/molecules/AboutCourse/AboutCourse';
import CourseReviews from 'components/molecules/CourseReviews/CourseReviews';
import CourseStructure from 'components/molecules/CourseStructure/CourseStructure';
import InstructorSection from 'components/molecules/InstructorSection/InstructorSection';
import { Button } from 'components/ui';

export default function CoursePreview() {
  const skills = ['Tech', 'Leadership', 'Mentor'];
  const { tab } = useParams();
  const navigate = useNavigate();
  const advancedTab = tab?.toLowerCase();
  const validTab = links.find((link) => link.path === `/course-preview/${tab}`);

  useLayoutEffect(() => {
    if (!tab || !validTab) {
      navigate('/course-preview/quizzes');
    }
  }, [tab, navigate, validTab]);

  return (
    <>
      <CourseBanner
        image="https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/Rectangle+75.jpg"
        title="Paint Techniques "
        subtitle="Antonio Mila"
        description="Assumenda omnis consequuntur. Qui molestiae est ut molestiae. Sit illum quia quis tenetur vel mollitia ducimus et. Neque quae repellat molestiae quos vel."
      />
      <ResponsiveWrapper>
        <AboutCourse
          video="https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708529174/9fc423c1-0fe1-4d4c-86a6-b9a9fb1a20b1_k9c5pv.mp4"
          skills={skills}
          description="In this Mental Health course you will learn what the responsibilities are from both employers and employees in relation to safety, equality and diversity, discrimination, bullying and harassment and conflict."
        />
        <CourseStructure editable={false} />
        <InstructorSection />
      </ResponsiveWrapper>
      <CourseReviews />
      <ResponsiveWrapper>
        <div className={styles.topContainer}>
          <NavTabs disableScroll={true} links={links} extraClass="instructorTabs" />
          <div className={styles.buttons}>
            {advancedTab === tabNames.resources.toLowerCase() && <Button>Upload Resources</Button>}
            {advancedTab === tabNames.quizzes.toLowerCase() && (
              <Button>
                <span>+</span> Create quiz
              </Button>
            )}
          </div>
        </div>
        <div className={styles.container}>
          <main className={styles.view_container}>{validTab?.page || links[0].page}</main>
        </div>
      </ResponsiveWrapper>
    </>
  );
}

import { useState } from "react";
import styles from './ContentItem.module.scss'
import FilePreview from "components/molecules/FilePreview/FilePreview";
import { extIcon } from "helpers/course";
import ActionsTab from "./ActionsTab";

const ContentItem = ({ item, setCourseMaterial, handleContentDelete }) => {
    const [editable, setEditable] = useState(false)
    const [openMenuId, setOpenMenuId] = useState(null);
    const [openPreview, setOpenPreview] = useState(false)

    const { id, type, title, fileUploadPromise, isLoading } = item || {};

    const cancelFn = () => {
        fileUploadPromise && fileUploadPromise.cancel()
    }

    const closePreviewModal = () => {
        setOpenPreview(false);
    };

    const toggleContentMenu = (id) => {
        setOpenMenuId((prev) => (prev === id ? null : id));
    };

    const handleContentRename = (id) => {
        setEditable(true)
        setOpenMenuId(null);
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            setEditable(false)
        }
    }

    const handleTitleChange = (e) => {
        const title = e.target.value?.trim()
        if (title) {
            setCourseMaterial((prev) => prev.map((content) => content.id === id ? { ...content, title } : content))
        }

    }

    const ext = type !== 'link' ? (item.extension || "") : "link";
    const previewContent = {
        path: item.source,
        type: item.type,
        title: item.title,
        ext: item.extension
    }

    return (
        <div key={id} className={styles.item} onDoubleClick={() => handleContentRename(id)} onContextMenu={(e) => {
            e.preventDefault()
            toggleContentMenu(id)
        }}>
            <div className={styles.left}>
                {extIcon(ext)}
                {
                    editable ?
                        <input
                            type="text"
                            value={title}
                            onChange={handleTitleChange}
                            onBlur={() => setEditable(false)}
                            onKeyUp={handleKeyUp}
                            autoFocus
                        />
                        :
                        <div className={styles.title}>{title}</div>
                }
            </div>
            {
                !isLoading ? (
                    <ActionsTab
                        item={item}
                        setOpenPreview={setOpenPreview}
                        openMenuId={openMenuId}
                        setOpenMenuId={setOpenMenuId}
                        setEditable={setEditable}
                        handleContentDelete={handleContentDelete}
                    />
                ) : (
                    <div className={styles.actions_in_progress}>
                        <div className={styles.progress_container}>
                            <span id={`${id}-loading`} className={styles.progress}></span>
                        </div>
                        <button className={styles.cancel_btn} onClick={cancelFn}>✖</button>
                    </div>
                )
            }
            <FilePreview isOpen={openPreview} onClose={closePreviewModal} content={previewContent} />
        </div>
    )
}

export default ContentItem;

import React from 'react';
import styles from './CourseBanner.module.scss';
import Save from 'assets/icons/saveicon.svg?react';
import Levels from 'assets/icons/levels.svg?react';
import Progress from 'assets/icons/progress.svg?react';

function CourseBanner({ title, subtitle, description, image }) {
  return (
    <div style={{ backgroundImage: `url(${image})` }} className={styles.container}>
      <div className={styles.frame}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{description}</p>
        <h3 className={styles.by}>By: {subtitle}</h3>
        <div className={styles.actions}>
          <button className={styles.button}>
            <Save /> Save course
          </button>
          <button className={`${styles.button} ${styles.level}`}>
            <Levels className={styles.level} /> All levels
          </button>
          <button className={`${styles.button} ${styles.progress}`}>
            <Progress /> Progress
          </button>
        </div>
      </div>
    </div>
  );
}
export default CourseBanner;

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './NavProfile.module.scss';
import defaultImage from 'assets/images/avatar.webp';
import { useSelector } from 'react-redux';
import { getProfileStatusSelector, getIsUserTouringSelector, setTouring } from 'store/login.slice';
import { getS3Url } from 'helpers/s3Handler';
import NavProfileModel from './NavProfileModel';
import UpdateStatusModal from './UpdateStatusModal';
import defaultProfileImage from 'assets/images/avatar.webp';
import {
  useGetNotificationsQuery,
  useMarkAllNotificationAsReadMutation,
  useMarkNotificationAsReadMutation,
  useUpdateProfileAvailabilityStatusMutation
} from 'api/individual-profile';
import { handleAsync } from 'helpers';
import { capitalizeFirstLetter } from 'helpers';
import { Button, Loader, Notification, ProfilePic } from 'components/ui/';
import { useHandleRtkPagination } from 'hooks';
import { useDispatch } from 'react-redux';
import video from 'assets/images/video-lesson.webp';
import Guides from 'assets/icons/guides.svg?react';
import InfoIcon from 'assets/icons/info.svg?react';
import { getCompanyThemeSelector } from 'store/login.slice';
import { useTranslation } from 'react-i18next';

export default function NavProfile() {
  const {
    company,
    picture,
    id: profileId,
    availability,
    contact,
    userProfiles
  } = useSelector(getProfileStatusSelector);
  const isTouring = useSelector(getIsUserTouringSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateProfileStatus, { isLoading }] = useUpdateProfileAvailabilityStatusMutation();
  const [markAllNotificationsRead] = useMarkAllNotificationAsReadMutation();
  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getNotifications',
    rtkQuery: useGetNotificationsQuery,
    rtkQueryArgs: { profileId },
    rtkQueryOptions: {
      pollingInterval: 1000 * 60 * 3 // 3 minutes
    }
  });

  const [status, setStatus] = useState(availability?.status ?? 'offline');
  const {
    data: notifications,
    isFetching: isNotificationFetching,
    isLoading: isNotificationLoading
  } = result;

  const [markRead] = useMarkNotificationAsReadMutation();

  const notificationMenuRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const modalRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const companyTheme = useSelector(getCompanyThemeSelector);
  if (isLoading) return <Loader fullpage={true} />;
  return (
    <div className={styles.container} ref={modalRef}>
      {/* TODO: remove commented part when done with guides */}
      {/* <Guides
        className={
          location.pathname === '/info-hub' && companyTheme !== 'default' && styles.infoHubGuides
        }
        onClick={() => {
          navigate('/guides');
        }}
      /> */}

      {/* <div className="info-button" onClick={() => navigate('/info')}>
        <InfoIcon />
      </div> */}
      <Notification unReadCount={notifications?.total || 0} setOpenMenu={setOpenMenu}>
        <Notification.Menu
          ref={notificationMenuRef}
          onMarkAllReadClick={() => {
            markAllNotificationsRead({ profileId });
          }}
        >
          {isNotificationLoading && <Loader extraClass="notificationloader" />}
          {notifications?.result?.map((notification) => (
            <Notification.Item
              onClick={() => {
                !notification.read.includes(profileId) &&
                  handleAsync(
                    // profileId is needed, if removed, we should get the profileId from the cache in the slice
                    markRead({ notificationId: notification.id, profileId: profileId }).unwrap()
                  );
              }}
              id={notification.id}
              key={notification.id}
              title={notification.title}
              read={notification.read.includes(profileId)}
              description={notification.description}
              profilePic={
                notification?.profile?.picture
                  ? getS3Url(notification.profile.picture)
                  : defaultProfileImage
              }
              date={notification.createdAt}
              link={notification.navigationLink}
            />
          ))}
          {isNotificationFetching && <Loader extraClass="notificationloader" />}
          {!isNotificationFetching && notifications?.result?.length === 0 && (
            <p className={styles.noNotification}>{t('No notifications found')}</p>
          )}
          {notifications?.page?.hasNextPage && (
            <Button onClick={() => setPage((pre) => pre + 1)}>
              {t('Load More Notifications')}
            </Button>
          )}
        </Notification.Menu>
      </Notification>
      {/* <p className={styles.companyName}>{capitalizeFirstLetter(company.name)}</p> */}
      <ProfilePic
        onClick={() => setOpenMenu(!openMenu)}
        extraClass="navprofile"
        image={picture ? getS3Url(picture) : defaultImage}
      />
      {openMenu && (
        <div className={styles.profileModal}>
          <NavProfileModel
            userProfiles={userProfiles}
            contact={contact}
            status={status}
            onUpdateStatusClick={() => {
              setOpenUpdateStatus(true);
              setOpenMenu(false);
            }}
            image={picture ? getS3Url(picture) : defaultImage}
          />
        </div>
      )}
      {openUpdateStatus && (
        <>
          <UpdateStatusModal
            handleClose={() => {
              setOpenUpdateStatus(false);
              setOpenMenu(false);
            }}
            handleSave={async (data) => {
              if (status === data.status) return;
              const result = await handleAsync(
                updateProfileStatus({ availabilityStatus: data, profileId }).unwrap()
              );
              setStatus(result[1]?.availability?.status);
              setOpenUpdateStatus(false);
              setOpenMenu(false);
              return result;
            }}
          />
        </>
      )}
    </div>
  );
}

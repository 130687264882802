import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createCourseDraft, getDrafts, updateDraftById } from 'api/courses/coursesApis';
import { togglePopup } from './popups/popupSlice';

const initialState = {
  drafts: [],
  fetching: false,
  error: null,
  creating: false,
  saving: false
};

export const createDraft = createAsyncThunk(
  'draft-courses/createDraft',
  async ({ callback }, { dispatch }) => {
    const response = await createCourseDraft();
    dispatch(
      togglePopup({
        pointer: 0,
        text: ''
      })
    );
    const { result } = response;
    if (!result) {
      throw new Error('Draft creation failed');
    }
    callback && callback(response);
    return result;
  }
);

export const getMyDrafts = createAsyncThunk('draft-courses/getMyDrafts', async () => {
  const response = await getDrafts();
  return response;
});

export const updateMyDraft = createAsyncThunk(
  'draft-courses/updateMyDraft',
  async ({ id, data }) => {
    const response = await updateDraftById(id, data);
    return response;
  }
);

const courseSlice = createSlice({
  name: 'courses-draft',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // creators
    builder.addCase(createDraft.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(createDraft.fulfilled, (state, action) => {
      state.drafts = [...state.drafts, action.payload];
      state.creating = false;
    });
    builder.addCase(createDraft.rejected, (state, action) => {
      state.error = action.error;
      state.creating = false;
    });

    // getting drafts

    builder.addCase(getMyDrafts.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(getMyDrafts.fulfilled, (state, action) => {
      state.drafts = action.payload;
      state.fetching = false;
    });
    builder.addCase(getMyDrafts.rejected, (state, action) => {
      state.error = action.error;
      state.fetching = false;
    });

    // updating drafts
    builder.addCase(updateMyDraft.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(updateMyDraft.fulfilled, (state, action) => {
      state.drafts = state.drafts.map((draft) => {
        if (draft?.id === action?.payload?.id) {
          return action.payload;
        }
        return draft;
      });
      state.saving = false;
    });
    builder.addCase(updateMyDraft.rejected, (state, action) => {
      state.error = action.error;
      state.saving = false;
    });
  }
});

// export const { } = courseSlice.actions;
export default courseSlice.reducer;

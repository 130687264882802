import styles from './styles.module.scss';

const CircularProgress = ({ progress = 75, size = 120, strokeWidth = 16, progressColor }) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <div className={styles.circleContainer}>
            <svg
                className={styles.svg}
                width={size}
                height={size}
            >
                <circle
                    className={styles.backgroundCircle}
                    fill="#ECF0F3"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={strokeWidth}
                />
                <circle
                    className={`${styles.progressCircle} ${progressColor}`}
                    fill="none"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                />
            </svg>

            <span className={styles.percentageText}>
                {progress}%
            </span>
        </div>
    );
};

export default CircularProgress;

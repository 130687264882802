import React from 'react'
import styles from './styles.module.scss'
import ViewLoader from '../ViewLoader';


export default function VideoViwer({ content, Loader }) {

  const { id, type, title, source, mimeType, isLoading } = content;

  return (
    <div className={styles.main_wrapper}>
      <video className={styles.video_player}
        onMouseEnter={(e) => e.target.controls = true}
        onMouseLeave={(e) => e.target.controls = false}
        autoPlay={false}
      >
        <source src={source} type={mimeType} />
      </video>
      {
        isLoading && <ViewLoader id={id} />
      }
    </div>
  )
}

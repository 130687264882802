import { useState } from "react";
import styles from './Lesson.module.scss'
import { FaPlus } from "react-icons/fa";

const LessonInputUpload = ({ handleMainLessonUpload }) => {

    const handleMainCourseUploadDnD = (e) => {
        e.preventDefault()
        const file = e.dataTransfer.files[0]
        if (!file) {
            return;
        }
        handleMainLessonUpload(file)
    }

    const handleInputChange = (e) => {
        const file = e.target.files[0]
        if (!file) {
            return;
        }
        handleMainLessonUpload(file)
    }

    return (
        <label className={styles.main_lecture}
            htmlFor='lecture-input-id'
            onDrop={handleMainCourseUploadDnD}
        >
            <span
                className={styles.add_content}
            >
                <FaPlus />
                Add Lecture Video
            </span>
            <input
                id='lecture-input-id'
                type='file'
                multiple={false}
                accept='video/*'
                onChange={handleInputChange}
                hidden />
        </label>
    )
}

export default LessonInputUpload;

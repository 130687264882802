import React from 'react';
import Edit from 'assets/icons/editicon.svg?react';
import Save from 'assets/icons/icon-save.svg?react';
import Add from 'assets/icons/addicon.svg?react';
import Trash from 'assets/icons/trash.svg?react';
import LikeI from 'assets/icons/like.svg?react';
import LoveI from 'assets/icons/love.svg?react';
import PrayI from 'assets/icons/pray.svg?react';
import styles from './SharedIcons.module.scss';
function EditI({ onClick }) {
  return <Edit className={`${styles.icon}`} onClick={() => onClick?.()} />;
}

function SaveI({ onClick, style }) {
  return <Save style={style} className={`${styles.icon}`} onClick={() => onClick?.()} />;
}

function AddI({ onClick, style, classN }) {
  return <Add style={style} className={`${styles.icon} ${classN}`} onClick={() => onClick?.()} />;
}

function CloseI({ onClick, style }) {
  return (
    <Add
      style={style}
      className={`${styles.closeIcon} ${styles.icon}`}
      onClick={() => onClick?.()}
    />
  );
}

function TrashI({ onClick, style }) {
  return <Trash style={style} className={`${styles.icon}`} onClick={() => onClick?.()} />;
}

function LikeIcon({ onClick, style = {} }) {
  return (
    <LikeI
      style={{ height: '25px', width: '25px', ...style }}
      className={`${styles.icon}`}
      onClick={() => onClick?.()}
    />
  );
}

function LoveIcon({ onClick, style = {} }) {
  return (
    <LoveI
      style={{ height: '25px', width: '25px', ...style }}
      className={`${styles.icon}`}
      onClick={() => onClick?.()}
    />
  );
}

function PrayIcon({ onClick, style = {} }) {
  return (
    <PrayI
      style={{ height: '25px', width: '25px', ...style }}
      className={`${styles.icon}`}
      onClick={() => onClick?.()}
    />
  );
}

export { EditI, SaveI, AddI, TrashI, CloseI, LikeIcon, LoveIcon, PrayIcon };

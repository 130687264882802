export default class Cookies {
  get(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  set(name, value) {
    document.cookie = `${name}=${value}; path=/; expires=Thu, 1 Jan 2030 00:00:00 GMT;`;
  }

  remove(name) {
    document.cookie = `${name}=; path=/; expires=Thu, 1 Jan 1970 00:00:00 GMT;`;
  }

  clear() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 1 Jan 1970 00:00:00 GMT;';
    }
  }
}

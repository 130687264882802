import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import CoursePreview from 'components/organisms/CoursePreview/CoursePreview';

function CoursePreviewPage() {
  document.body.style.overflow = '';

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/course-preview/quizzes" />} />
      <Route path="/:tab" element={<CoursePreview />} />
    </Routes>
  );
}

export default CoursePreviewPage;

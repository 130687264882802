import React from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { previewStages, setPreviewStage } from 'store/quiz.slice';

const QuizDescription = () => {
  const dispatch = useDispatch();
  const { title, description } = useSelector((state) => state.quiz);
  const navigate = useNavigate();

  const goBackToQuiz = () => navigate('/quiz');
  const startQuiz = () => dispatch(setPreviewStage(previewStages.take));

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.card}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description}</p>
          <p className={styles.author}>By Nik Luna</p>
          <div className={styles.buttonGroup}>
            <button className={styles.cancelButton} onClick={goBackToQuiz}>
              Cancel
            </button>
            <button className={styles.startButton} onClick={startQuiz}>
              Start Quiz
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizDescription;

import React from 'react';
import { BiCheck } from 'react-icons/bi';
import { MdOutlineQuestionMark } from "react-icons/md";
import styles from './styles.module.scss';

const QuestionIndicators = ({ results }) => {
  return (
    <div className={styles.indicatorContainer}>
      {results.map((result, index) => {
        let indicatorClass = styles.correct;
        if (result.isCorrect === false) indicatorClass = styles.incorrect;
        else if (result.isCorrect === undefined) indicatorClass = styles.needReview;

        return (
          <div key={index} className={styles.indicator}>
            <div className={indicatorClass}>
              {result.isCorrect === true && <BiCheck size={16} />}
              {result.isCorrect === false && 'X'}
              {result.isCorrect === undefined && <MdOutlineQuestionMark size={14} />}
            </div>
            <span>Q{index + 1}</span>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionIndicators;

import styles from 'components/organisms/UpDashboard/DashboardView.module.scss';

const Pagination = ({ pageNumbers, navigateToPage, currentPage }) => {
  return (
    <div className={styles.paginationContainer}>
      {pageNumbers.map(
        (pageNumber) =>
          pageNumber > 1 && (
            <button
              key={pageNumber}
              className={`${styles.pageButton} ${currentPage === pageNumber ? styles.active : ''}`}
              onClick={() => navigateToPage(pageNumber)}
            >
              {pageNumber}
            </button>
          )
      )}
    </div>
  );
};

export default Pagination;

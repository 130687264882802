import React, { useState } from 'react';
import styles from './styles.module.scss';
import DataSlider from './components/DataSlider/DataSlider';
import DataTable from './components/DataTable/DataTable';
import UserTable from './components/UserTable/UserTable';
export default function index() {
  const dummyUsers = [
    {
      id: 1,
      name: 'John Doe',
      x:
        'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
      score: 'Pending',
      status: 'Pending',
      date: '2022-01-01'
    },
    {
      id: 2,
      name: 'John Doe',
      profileImage:
        'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
      score: 40,
      status: 'Failed',
      date: '2022-01-01'
    },
    {
      id: 3,
      name: 'John Doe',
      profileImage:
        'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
      score: 80,
      status: 'Passed',
      date: '2022-01-01'
    },
    {
      id: 4,
      name: 'John Doe',
      profileImage:
        'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
      score: 100,
      status: 'Passed',
      date: '2022-01-01'
    },
    {
      id: 5,
      name: 'John Doe',
      profileImage:
        'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
      score: 90,
      status: 'Passed',
      date: '2022-01-01'
    }
  ];
  const data = {
    countOfResponses: 10,
    status: 'Active',
    details: [
      {
        id: 1,
        value: 'Data Point 1',
        questionData: [
          {
            answer: 'Finance',
            numberOfResponses: 5,
            users: dummyUsers
          },
          {
            answer: 'Tech',
            numberOfResponses: 5,
            users: dummyUsers
          },
          {
            answer: 'HR',
            numberOfResponses: 5,
            users: dummyUsers
          }
        ]
      },
      {
        id: 2,
        value: 'Data Point 2',
        questionData: [
          {
            answer: 'Music',
            numberOfResponses: 5,
            users: dummyUsers
          },
          {
            answer: 'OPS',
            numberOfResponses: 5,
            users: dummyUsers
          },
          {
            answer: 'STUFF',
            numberOfResponses: 5,
            users: dummyUsers
          },
          {
            answer: 'Research',
            numberOfResponses: 5,
            users: dummyUsers
          }
        ]
      }
    ]
  };

  const [currentDataPoint, setCurrentDataPoint] = useState(data.details[0]);
  const handleSlideChange = (pageNumber) => {
    setCurrentDataPoint(data.details[pageNumber - 1]);
    // Update your other components here
  };
  return (
    <div className={styles.container}>
      <DataSlider data={data.details} onSlideChange={handleSlideChange} />

      <DataTable data={currentDataPoint} />
      <UserTable data={dummyUsers} />
    </div>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './AboutCourse.module.scss';
import { skillSuggestions } from 'utils/skills';
import UploadArea from '../../../../../molecules/UploadArea/UploadArea';
import CourseDetails from '../CourseDetails/CourseDetails';

const AboutCourse = ({ headerData, changeHeader, handleContentImgUpload, thumbnail }) => {
    const { skills, description } = headerData || {};
    const [skillInput, setSkillInput] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const suggestionRef = useRef(null);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/gif': [],
          },
        multiple: false,
        onDrop: handleImageDrop,
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    function handleImageDrop(acceptedFiles) {
        const file = acceptedFiles[0];
        if (file) {
            handleContentImgUpload(file);
        }
    }

    function handleAddSkill(skill) {
        if (skill && !skills.includes(skill)) {
            changeHeader({ skills: [...skills, skill] });
            resetSkillInput();
        }
    }

    function resetSkillInput() {
        setSkillInput('');
        setFilteredSuggestions([]);
        setShowSuggestions(false);
    }

    function handleInputChange(e) {
        const input = e.target.value;
        setSkillInput(input);
        if (input) {
            const filtered = skillSuggestions.filter((skill) =>
                skill.toLowerCase().startsWith(input.toLowerCase())
            );
            setFilteredSuggestions(filtered);
            setShowSuggestions(filtered.length > 0);
        } else {
            resetSkillInput();
        }
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter' && skillInput.trim()) {
            handleAddSkill(skillInput.trim());
        }
    }

    function removeSkill(indexToRemove) {
        changeHeader({ skills: skills.filter((_, index) => index !== indexToRemove) });
    }

    return (
        <div className={styles.uploadContainer}>
            <UploadArea getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} img={thumbnail} />
            <CourseDetails
                skills={skills}
                skillInput={skillInput}
                showSuggestions={showSuggestions}
                filteredSuggestions={filteredSuggestions}
                suggestionRef={suggestionRef}
                onInputChange={handleInputChange}
                onKeyPress={handleKeyPress}
                onAddSkill={handleAddSkill}
                onRemoveSkill={removeSkill}
                description={description}
                changeHeader={changeHeader}
            />
        </div>
    );
};

export default AboutCourse;

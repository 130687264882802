import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import QuizBuilder from 'components/organisms/QuizBuilder/QuizBuilder';

export default function Quiz() {
  document.body.style.overflow = '';

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/quiz/questions" />} />
      <Route path="/:tab" element={<QuizBuilder />} />
    </Routes>
  );
}

import { useEffect, useRef } from "react";
import styles from './ContentItem.module.scss'
import { FaPencil, FaRegTrashCan } from "react-icons/fa6";
import { IoEllipsisVertical, } from "react-icons/io5";

const ActionsTab = ({ item, setOpenPreview, openMenuId, setOpenMenuId, setEditable, handleContentDelete  }) => {
    const contentMenuRef = useRef(null);

    const { id } = item

    useEffect(() => {
        const getClassName = (event) => {
            const { className } = event.target;
            return className instanceof SVGAnimatedString ? className.baseVal : className;
        };

        const handleClickContentMenuOutside = (event) => {
            const className = getClassName(event);

            if (contentMenuRef.current && !contentMenuRef.current.contains(event.target)) {
                if (className !== "hideMenu") {
                    setOpenMenuId(null);
                }
            }
        };

        document.addEventListener('click', handleClickContentMenuOutside, true);

        return () => {
            document.removeEventListener('click', handleClickContentMenuOutside, true);
        };
    }, []);

    const openPreviewModal = (content) => {
        setOpenPreview(true);
    };

    const toggleContentMenu = (id) => {
        setOpenMenuId((prev) => (prev === id ? null : id));
    };

    const handleContentRename = (id) => {
        setEditable(true)
        setOpenMenuId(null);
    }


    const handleDelete = (id) => {
        setOpenMenuId(null);
        handleContentDelete([{id}]);
    }



    return (
        <div className={styles.actions}>
            <button
                onClick={openPreviewModal}
            >
                Preview
            </button>
            <div className={styles.icon} key={id}>
                <button className={'hideMenu'} onClick={(e) => {
                    e.preventDefault()
                    toggleContentMenu(id)
                }}>
                    <IoEllipsisVertical className="hideMenu" />
                </button>
                <div className={`${styles.contextmenu} ${openMenuId === id ? styles.active_menu : ''}`} ref={contentMenuRef}>
                    <div
                        className={styles.option}
                        onClick={() => handleContentRename(id)}
                    >
                        <div className={styles.option_icon}>
                            <FaPencil />
                        </div>
                        <div className={styles.option_title}>
                            Rename
                        </div>
                    </div>
                    <div
                        className={styles.option}
                        onClick={() => handleDelete(item.id)}
                    >
                        <div className={styles.option_icon}>
                            <FaRegTrashCan />
                        </div>
                        <div className={styles.option_title}>
                            Delete
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActionsTab;

import React from 'react'
import LoadingPopup from './LoadingPopup'
import OneButtonPopup from './OneButtonPopup'

const index = ({ pointer, data, close }) => {
  switch (pointer) {
    case 1: {
      return <LoadingPopup data={data} />
    }
    case 399: {
      return <ConfirmPopup data={data} />
    }
    case 404: {
      return <OneButtonPopup data={data} close={close} />
    }
    default: {
      close()
      break
    }
  }
}

export default index

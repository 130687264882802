import React, { useRef, useState } from 'react';
import styles from './ResultShareProfileModal.module.scss';
import { Input } from 'components/form';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import Copy from 'assets/icons/copy.svg?react';
import { fallbackCopyTextToClipboard } from 'helpers/general';
const ResultShareProfileModal = ({ open, value, onClose }) => {
  const inputRef = useRef(null);
  const [isLinkCopied, setLinkCopied] = useState(false);

  const handleCopyClick = async () => {
    if (inputRef.current) {
      const textToCopy = inputRef.current.value;

      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(textToCopy);
        setLinkCopied(true);
      } else {
        fallbackCopyTextToClipboard(textToCopy, () => {
          setLinkCopied(true);
        });
      }
    }
  };
  if (!open) return null;

  return (
    <CreateModal handleClose={onClose} textCancel="Close" showSave={false}>
      <div className={styles.shareLinkModal}>
        <h1>Share Profile</h1>
        <Input
          label="Sharing Link"
          disabled
          type="text"
          extraClass="teamInput"
          value={value}
          name="passKey"
          onChange={(e) => {}}
          icon={<Copy />}
          inputRef={inputRef}
          clickEvent={handleCopyClick}
          tooltip={isLinkCopied ? 'Link Copied' : 'Copy Link'}
        />
      </div>
    </CreateModal>
  );
};

export default ResultShareProfileModal;

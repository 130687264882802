import React from 'react';
import { HiOutlineDotsVertical } from "react-icons/hi";
import styles from './CardComponent.module.scss';

const CardComponent = ({
  title,
  author,
  progress,
  completed,
  sections,
  lessons,
  imageUrl,
}) => {
  const isInProgress = !completed && progress > 0;
  const buttonText = completed
    ? 'Download certificate'
    : progress === 0
      ? 'View Course'
      : 'Continue learning';

  const progressContent = completed ? (
    <div>
      <div className={styles.completedBar}></div>
      <div className={styles.progressTextContainer}>
        <span className={styles.progressText}>100% completed</span>
      </div>
    </div>
  ) : isInProgress ? (
    <div>
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className={styles.progressTextContainer}>
        <span className={styles.progressText}>{progress}%</span>
      </div>
    </div>
  ) : null;

  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <img
          src={imageUrl}
          alt="Course thumbnail"
          className={styles.image}
        />
      </div>
      <div className={styles.content}>
        <HiOutlineDotsVertical className={styles.icon} />
        <p className={styles.info}>
          {sections} sections | {lessons} lessons
        </p>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.author}>{author}</p>

        <div className={styles.progressContainer}>
          {progressContent}
          <div className={styles.buttonContainer}>
            <button className={styles.button}>{buttonText}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;

import React, { useState } from 'react';
import styles from './Publications.module.scss';
import img from 'assets/images/star.webp';
import Arrow from 'assets/icons/arrowright.svg?react';
import { EditInput } from 'components/form';
import { isSharedMode, removeObjectElements } from 'helpers';
import { handleAsync } from 'helpers';
import {
  useDeletePublicationMutation,
  useGetPublicationsByUserIdQuery,
  useSavePublicationMutation,
  useUpdatePublicationMutation
} from 'api/individual-profile';
import Card from './Card';
import CreatePublicationModal from './CreatePublicationModal';
import { Loader } from 'components/ui';
import { useUpdatePublicationDescMutation } from 'api/individual-profile';
import { useHandleRtkPagination } from 'hooks';
import useSelectCacheData from 'hooks/useSelectCacheData';
import { showErrorToast } from 'utils/toast';
import { Button } from 'components/ui';
import Options from 'components/atoms/Options/Options';
import MWEditorLite from 'components/atoms/MWEditor/indexLite';
import {
  serialize,
  isJsonString,
  convertStringToParagraph
} from 'components/atoms/MWEditor/Helpers';
import { useTranslation } from 'react-i18next';

export default function Publications({ userId, isCurrentUserProfile, publicationDescription }) {
  const sharedData = useSelectCacheData('getSharedProfileBySlug', {});
  const { t } = useTranslation();
  const [create, setCreate] = useState(false);
  const [updatePublication] = useUpdatePublicationMutation();
  const [savePublication, { isLoading: isCreatingLoading }] = useSavePublicationMutation();
  const [deletePublication] = useDeletePublicationMutation();
  const [updatePublicationDesc] = useUpdatePublicationDescMutation();
  const [editDescription, setDescription] = useState(false);
  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getPublicationsByUserId',
    rtkQuery: useGetPublicationsByUserIdQuery,
    rtkQueryArgs: { userId },
    rtkQueryOptions: {
      skip: isSharedMode()
    }
  });
  const { data, isFetching, isLoading, isError, error, isSuccess } = !isSharedMode()
    ? result
    : sharedData;
  const [updatedDesc, setUpdatedDesc] = useState(publicationDescription);
  const onDescriptionSave = async () => {
    const publicationDescDto = {
      description: JSON.stringify(updatedDesc)
    };
    setDescription(false);
    const result = await handleAsync(updatePublicationDesc(publicationDescDto).unwrap());
    if (!result[0]) showErrorToast(t('Error updating description'));
  };
  const onUpdateSubmit = async (publication) => {
    const { id, ...rest } = publication;
    const publicationDto = removeObjectElements(rest, ['img', 'userId']);
    await handleAsync(
      updatePublication({
        userId,
        publicationId: id,
        publication: publicationDto
      }).unwrap()
    );
  };
  const onDeleteSubmit = async (publicationId) => {
    const status = await handleAsync(deletePublication({ publicationId, userId }).unwrap());
    return status[0];
  };
  const onCreateSubmit = async (publication) => {
    const status = await handleAsync(savePublication({ publication, userId }).unwrap());
    return status[0];
  };

  const staticDesc = t('i-am-pleased-to-share-my-experiences');

  let content = null;
  if (isLoading) content = <Loader />;
  if (isError) content = <p style={{ color: 'red' }}>Error: {error.data.message}</p>;
  if (isSuccess) {
    const publications = isSharedMode() ? data.bio.publications : data?.result;
    content = (
      <>
        {' '}
        <div className={styles.publicationsContainer}>
          {publications?.map((item) => {
            return (
              <Card
                img={img}
                key={item.id}
                width={52}
                height={52}
                name={item.name}
                reference={item.reference}
                description={item.description}
                date={item.date}
                id={item.id}
                isCurrentUserProfile={isCurrentUserProfile}
                onUpdateSubmit={onUpdateSubmit}
                onDeleteSubmit={onDeleteSubmit}
              />
            );
          })}
          {isFetching && <Loader />}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.publications}>
        {isCurrentUserProfile && (
          <Options
            extraClass="topOptions"
            handleAdd={() => setCreate(true)}
            handleDiscard={() => setDescription(false)}
            handleSave={onDescriptionSave}
            handleEdit={() => setDescription(true)}
            edit={editDescription}
          />
        )}
        <div>
          <h3 className={styles.title} id="publications-title">
            {t('Publications')}
          </h3>
          <div className={styles.desc}>
            {editDescription ? (
              <div className={styles.editDesc}>
                <MWEditorLite
                  data={
                    publicationDescription
                      ? isJsonString(publicationDescription)
                        ? JSON.parse(publicationDescription)
                        : convertStringToParagraph(publicationDescription)
                      : undefined
                  }
                  placeholderText={t('Describe your publication')}
                  setData={setUpdatedDesc}
                />
              </div>
            ) : (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: publicationDescription
                    ? serialize(
                        publicationDescription
                          ? isJsonString(publicationDescription)
                            ? JSON.parse(publicationDescription)
                            : convertStringToParagraph(publicationDescription)
                          : undefined
                      )
                    : staticDesc
                }}
              ></div>
            )}
          </div>
        </div>
        {content}
        <div className={!data?.page?.hasNextPage ? `${styles.removebtn}` : `${styles.addmore}`}>
          <Button
            onClick={() => setPage((pre) => pre + 1)}
            color="#bfbfbf"
            disabled={isFetching || !data?.page?.hasNextPage}
          >
            {t('Load More')} <Arrow />
          </Button>
        </div>
      </div>
      <CreatePublicationModal
        open={create}
        isSaving={isCreatingLoading}
        onClose={() => setCreate(false)}
        onSavePublication={(fieldsChange) => onCreateSubmit(fieldsChange)}
      />
    </>
  );
}

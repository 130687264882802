export const CONTRACTS = [
  {
    id: 1,
    text: 'Part-Time'
  },
  {
    id: 2,
    text: 'Full-Time'
  }
];

export const EDUCATION_DEGREES = [
  {
    id: 1,
    text: 'Certification'
  },
  {
    id: 2,
    text: 'Bachelor'
  },
  {
    id: 3,
    text: 'Master'
  },
  {
    id: 4,
    text: 'PhD'
  }
];
export const EDUCATION_GRADES = [
  {
    id: 1,
    text: 'Finished'
  },
  {
    id: 2,
    text: 'Ongoing'
  },
  {
    id: 3,
    text: 'Dropped'
  }
];

export const LANGUAGES_PROFICIENCY = [
  {
    text: 'Basic',
    id: 1
  },
  {
    text: 'Intermediate',
    id: 1
  },
  {
    text: 'Advanced',
    id: 1
  }
];

export const IT_ESSENTIALS_INPUTS = [
  {
    id: 1,
    title: 'Phone details',
    description: 'Example: iPhone 12, 2020, 126mb'
  },
  {
    id: 2,
    title: 'Internet details',
    description: 'Comcast provider, fixed line, 15mb up, 10mb download speed'
  },
  {
    id: 3,
    title: 'PC/Mac system details',
    description: 'Example: Macbook Apple, M1, 2O21, 256mb, 10 Cores GPU '
  },
  {
    id: 4,
    title: 'Typical use',
    description: 'Always use stationary computer, unless travelling'
  },
  {
    id: 5,
    title: 'External Monitor 1',
    description: 'Example: HP 2O inch, 1920x1080 '
  },
  {
    id: 6,
    title: 'External Monitor 2',
    description: 'Example: Dell 23 inch, 2K '
  },
  {
    id: 7,
    title: 'IT course participation',
    description: 'Yes, I would like to join monthly sessions '
  }
];

export const COUNTRIES_BY_CONTINENTS = [
  {
    text: 'Afghanistan',
    continent: 'Asia'
  },
  {
    text: 'Albania',
    continent: 'Europe'
  },
  {
    text: 'Algeria',
    continent: 'Africa'
  },
  {
    text: 'American Samoa',
    continent: 'Oceania'
  },
  {
    text: 'Andorra',
    continent: 'Europe'
  },
  {
    text: 'Angola',
    continent: 'Africa'
  },
  {
    text: 'Anguilla',
    continent: 'North America'
  },
  {
    text: 'Antarctica',
    continent: 'Antarctica'
  },
  {
    text: 'Antigua and Barbuda',
    continent: 'North America'
  },
  {
    text: 'Argentina',
    continent: 'South America'
  },
  {
    text: 'Armenia',
    continent: 'Asia'
  },
  {
    text: 'Aruba',
    continent: 'North America'
  },
  {
    text: 'Australia',
    continent: 'Oceania'
  },
  {
    text: 'Austria',
    continent: 'Europe'
  },
  {
    text: 'Azerbaijan',
    continent: 'Asia'
  },
  {
    text: 'Bahamas',
    continent: 'North America'
  },
  {
    text: 'Bahrain',
    continent: 'Asia'
  },
  {
    text: 'Bangladesh',
    continent: 'Asia'
  },
  {
    text: 'Barbados',
    continent: 'North America'
  },
  {
    text: 'Belarus',
    continent: 'Europe'
  },
  {
    text: 'Belgium',
    continent: 'Europe'
  },
  {
    text: 'Belize',
    continent: 'North America'
  },
  {
    text: 'Benin',
    continent: 'Africa'
  },
  {
    text: 'Bermuda',
    continent: 'North America'
  },
  {
    text: 'Bhutan',
    continent: 'Asia'
  },
  {
    text: 'Bolivia',
    continent: 'South America'
  },
  {
    text: 'Bosnia and Herzegovina',
    continent: 'Europe'
  },
  {
    text: 'Botswana',
    continent: 'Africa'
  },
  {
    text: 'Bouvet Island',
    continent: 'Antarctica'
  },
  {
    text: 'Brazil',
    continent: 'South America'
  },
  {
    text: 'British Indian Ocean Territory',
    continent: 'Africa'
  },
  {
    text: 'Brunei',
    continent: 'Asia'
  },
  {
    text: 'Bulgaria',
    continent: 'Europe'
  },
  {
    text: 'Burkina Faso',
    continent: 'Africa'
  },
  {
    text: 'Burundi',
    continent: 'Africa'
  },
  {
    text: 'Cambodia',
    continent: 'Asia'
  },
  {
    text: 'Cameroon',
    continent: 'Africa'
  },
  {
    text: 'Canada',
    continent: 'North America'
  },
  {
    text: 'Cape Verde',
    continent: 'Africa'
  },
  {
    text: 'Cayman Islands',
    continent: 'North America'
  },
  {
    text: 'Central African Republic',
    continent: 'Africa'
  },
  {
    text: 'Chad',
    continent: 'Africa'
  },
  {
    text: 'Chile',
    continent: 'South America'
  },
  {
    text: 'China',
    continent: 'Asia'
  },
  {
    text: 'Christmas Island',
    continent: 'Oceania'
  },
  {
    text: 'Cocos (Keeling) Islands',
    continent: 'Oceania'
  },
  {
    text: 'Colombia',
    continent: 'South America'
  },
  {
    text: 'Comoros',
    continent: 'Africa'
  },
  {
    text: 'Congo',
    continent: 'Africa'
  },
  {
    text: 'Cook Islands',
    continent: 'Oceania'
  },
  {
    text: 'Costa Rica',
    continent: 'North America'
  },
  {
    text: 'Croatia',
    continent: 'Europe'
  },
  {
    text: 'Cuba',
    continent: 'North America'
  },
  {
    text: 'Cyprus',
    continent: 'Asia'
  },
  {
    text: 'Czech Republic',
    continent: 'Europe'
  },
  {
    text: 'Denmark',
    continent: 'Europe'
  },
  {
    text: 'Djibouti',
    continent: 'Africa'
  },
  {
    text: 'Dominica',
    continent: 'North America'
  },
  {
    text: 'Dominican Republic',
    continent: 'North America'
  },
  {
    text: 'East Timor',
    continent: 'Asia'
  },
  {
    text: 'Ecuador',
    continent: 'South America'
  },
  {
    text: 'Egypt',
    continent: 'Africa'
  },
  {
    text: 'El Salvador',
    continent: 'North America'
  },
  {
    text: 'England',
    continent: 'Europe'
  },
  {
    text: 'Equatorial Guinea',
    continent: 'Africa'
  },
  {
    text: 'Eritrea',
    continent: 'Africa'
  },
  {
    text: 'Estonia',
    continent: 'Europe'
  },
  {
    text: 'Ethiopia',
    continent: 'Africa'
  },
  {
    text: 'Falkland Islands',
    continent: 'South America'
  },
  {
    text: 'Faroe Islands',
    continent: 'Europe'
  },
  {
    text: 'Fiji Islands',
    continent: 'Oceania'
  },
  {
    text: 'Finland',
    continent: 'Europe'
  },
  {
    text: 'France',
    continent: 'Europe'
  },
  {
    text: 'French Guiana',
    continent: 'South America'
  },
  {
    text: 'French Polynesia',
    continent: 'Oceania'
  },
  {
    text: 'French Southern territories',
    continent: 'Antarctica'
  },
  {
    text: 'Gabon',
    continent: 'Africa'
  },
  {
    text: 'Gambia',
    continent: 'Africa'
  },
  {
    text: 'Georgia',
    continent: 'Asia'
  },
  {
    text: 'Germany',
    continent: 'Europe'
  },
  {
    text: 'Ghana',
    continent: 'Africa'
  },
  {
    text: 'Gibraltar',
    continent: 'Europe'
  },
  {
    text: 'Greece',
    continent: 'Europe'
  },
  {
    text: 'Greenland',
    continent: 'North America'
  },
  {
    text: 'Grenada',
    continent: 'North America'
  },
  {
    text: 'Guadeloupe',
    continent: 'North America'
  },
  {
    text: 'Guam',
    continent: 'Oceania'
  },
  {
    text: 'Guatemala',
    continent: 'North America'
  },
  {
    text: 'Guinea',
    continent: 'Africa'
  },
  {
    text: 'Guinea-Bissau',
    continent: 'Africa'
  },
  {
    text: 'Guyana',
    continent: 'South America'
  },
  {
    text: 'Haiti',
    continent: 'North America'
  },
  {
    text: 'Heard Island and McDonald Islands',
    continent: 'Antarctica'
  },
  {
    text: 'Holy See (Vatican City State)',
    continent: 'Europe'
  },
  {
    text: 'Honduras',
    continent: 'North America'
  },
  {
    text: 'Hong Kong',
    continent: 'Asia'
  },
  {
    text: 'Hungary',
    continent: 'Europe'
  },
  {
    text: 'Iceland',
    continent: 'Europe'
  },
  {
    text: 'India',
    continent: 'Asia'
  },
  {
    text: 'Indonesia',
    continent: 'Asia'
  },
  {
    text: 'Iran',
    continent: 'Asia'
  },
  {
    text: 'Iraq',
    continent: 'Asia'
  },
  {
    text: 'Ireland',
    continent: 'Europe'
  },
  {
    text: 'Israel',
    continent: 'Asia'
  },
  {
    text: 'Italy',
    continent: 'Europe'
  },
  {
    text: 'Ivory Coast',
    continent: 'Africa'
  },
  {
    text: 'Jamaica',
    continent: 'North America'
  },
  {
    text: 'Japan',
    continent: 'Asia'
  },
  {
    text: 'Jordan',
    continent: 'Asia'
  },
  {
    text: 'Kazakhstan',
    continent: 'Asia'
  },
  {
    text: 'Kosovo',
    continent: 'Europe'
  },
  {
    text: 'Kenya',
    continent: 'Africa'
  },
  {
    text: 'Kiribati',
    continent: 'Oceania'
  },
  {
    text: 'Kuwait',
    continent: 'Asia'
  },
  {
    text: 'Kyrgyzstan',
    continent: 'Asia'
  },
  {
    text: 'Laos',
    continent: 'Asia'
  },
  {
    text: 'Latvia',
    continent: 'Europe'
  },
  {
    text: 'Lebanon',
    continent: 'Asia'
  },
  {
    text: 'Lesotho',
    continent: 'Africa'
  },
  {
    text: 'Liberia',
    continent: 'Africa'
  },
  {
    text: 'Libyan Arab Jamahiriya',
    continent: 'Africa'
  },
  {
    text: 'Liechtenstein',
    continent: 'Europe'
  },
  {
    text: 'Lithuania',
    continent: 'Europe'
  },
  {
    text: 'Luxembourg',
    continent: 'Europe'
  },
  {
    text: 'Macao',
    continent: 'Asia'
  },
  {
    text: 'North Macedonia',
    continent: 'Europe'
  },
  {
    text: 'Madagascar',
    continent: 'Africa'
  },
  {
    text: 'Malawi',
    continent: 'Africa'
  },
  {
    text: 'Malaysia',
    continent: 'Asia'
  },
  {
    text: 'Maldives',
    continent: 'Asia'
  },
  {
    text: 'Mali',
    continent: 'Africa'
  },
  {
    text: 'Malta',
    continent: 'Europe'
  },
  {
    text: 'Marshall Islands',
    continent: 'Oceania'
  },
  {
    text: 'Martinique',
    continent: 'North America'
  },
  {
    text: 'Mauritania',
    continent: 'Africa'
  },
  {
    text: 'Mauritius',
    continent: 'Africa'
  },
  {
    text: 'Mayotte',
    continent: 'Africa'
  },
  {
    text: 'Mexico',
    continent: 'North America'
  },
  {
    text: 'Micronesia, Federated States of',
    continent: 'Oceania'
  },
  {
    text: 'Moldova',
    continent: 'Europe'
  },
  {
    text: 'Monaco',
    continent: 'Europe'
  },
  {
    text: 'Mongolia',
    continent: 'Asia'
  },
  {
    text: 'Montenegro',
    continent: 'Europe'
  },
  {
    text: 'Montserrat',
    continent: 'North America'
  },
  {
    text: 'Morocco',
    continent: 'Africa'
  },
  {
    text: 'Mozambique',
    continent: 'Africa'
  },
  {
    text: 'Myanmar',
    continent: 'Asia'
  },
  {
    text: 'Namibia',
    continent: 'Africa'
  },
  {
    text: 'Nauru',
    continent: 'Oceania'
  },
  {
    text: 'Nepal',
    continent: 'Asia'
  },
  {
    text: 'Netherlands',
    continent: 'Europe'
  },
  {
    text: 'Netherlands Antilles',
    continent: 'North America'
  },
  {
    text: 'New Caledonia',
    continent: 'Oceania'
  },
  {
    text: 'New Zealand',
    continent: 'Oceania'
  },
  {
    text: 'Nicaragua',
    continent: 'North America'
  },
  {
    text: 'Niger',
    continent: 'Africa'
  },
  {
    text: 'Nigeria',
    continent: 'Africa'
  },
  {
    text: 'Niue',
    continent: 'Oceania'
  },
  {
    text: 'Norfolk Island',
    continent: 'Oceania'
  },
  {
    text: 'North Korea',
    continent: 'Asia'
  },
  {
    text: 'Northern Ireland',
    continent: 'Europe'
  },
  {
    text: 'Northern Mariana Islands',
    continent: 'Oceania'
  },
  {
    text: 'Norway',
    continent: 'Europe'
  },
  {
    text: 'Oman',
    continent: 'Asia'
  },
  {
    text: 'Pakistan',
    continent: 'Asia'
  },
  {
    text: 'Palau',
    continent: 'Oceania'
  },
  {
    text: 'Palestine',
    continent: 'Asia'
  },
  {
    text: 'Panama',
    continent: 'North America'
  },
  {
    text: 'Papua New Guinea',
    continent: 'Oceania'
  },
  {
    text: 'Paraguay',
    continent: 'South America'
  },
  {
    text: 'Peru',
    continent: 'South America'
  },
  {
    text: 'Philippines',
    continent: 'Asia'
  },
  {
    text: 'Pitcairn',
    continent: 'Oceania'
  },
  {
    text: 'Poland',
    continent: 'Europe'
  },
  {
    text: 'Portugal',
    continent: 'Europe'
  },
  {
    text: 'Puerto Rico',
    continent: 'North America'
  },
  {
    text: 'Qatar',
    continent: 'Asia'
  },
  {
    text: 'Reunion',
    continent: 'Africa'
  },
  {
    text: 'Romania',
    continent: 'Europe'
  },
  {
    text: 'Russian Federation',
    continent: 'Europe'
  },
  {
    text: 'Rwanda',
    continent: 'Africa'
  },
  {
    text: 'Saint Helena',
    continent: 'Africa'
  },
  {
    text: 'Saint Kitts and Nevis',
    continent: 'North America'
  },
  {
    text: 'Saint Lucia',
    continent: 'North America'
  },
  {
    text: 'Saint Pierre and Miquelon',
    continent: 'North America'
  },
  {
    text: 'Saint Vincent and the Grenadines',
    continent: 'North America'
  },
  {
    text: 'Samoa',
    continent: 'Oceania'
  },
  {
    text: 'San Marino',
    continent: 'Europe'
  },
  {
    text: 'Sao Tome and Principe',
    continent: 'Africa'
  },
  {
    text: 'Saudi Arabia',
    continent: 'Asia'
  },
  {
    text: 'Scotland',
    continent: 'Europe'
  },
  {
    text: 'Senegal',
    continent: 'Africa'
  },
  {
    text: 'Serbia',
    continent: 'Europe'
  },
  {
    text: 'Seychelles',
    continent: 'Africa'
  },
  {
    text: 'Sierra Leone',
    continent: 'Africa'
  },
  {
    text: 'Singapore',
    continent: 'Asia'
  },
  {
    text: 'Slovakia',
    continent: 'Europe'
  },
  {
    text: 'Slovenia',
    continent: 'Europe'
  },
  {
    text: 'Solomon Islands',
    continent: 'Oceania'
  },
  {
    text: 'Somalia',
    continent: 'Africa'
  },
  {
    text: 'South Africa',
    continent: 'Africa'
  },
  {
    text: 'South Georgia and the South Sandwich Islands',
    continent: 'Antarctica'
  },
  {
    text: 'South Korea',
    continent: 'Asia'
  },
  {
    text: 'South Sudan',
    continent: 'Africa'
  },
  {
    text: 'Spain',
    continent: 'Europe'
  },
  {
    text: 'Sri Lanka',
    continent: 'Asia'
  },
  {
    text: 'Sudan',
    continent: 'Africa'
  },
  {
    text: 'Suriname',
    continent: 'South America'
  },
  {
    text: 'Svalbard and Jan Mayen',
    continent: 'Europe'
  },
  {
    text: 'Swaziland',
    continent: 'Africa'
  },
  {
    text: 'Sweden',
    continent: 'Europe'
  },
  {
    text: 'Switzerland',
    continent: 'Europe'
  },
  {
    text: 'Syria',
    continent: 'Asia'
  },
  {
    text: 'Tajikistan',
    continent: 'Asia'
  },
  {
    text: 'Tanzania',
    continent: 'Africa'
  },
  {
    text: 'Thailand',
    continent: 'Asia'
  },
  {
    text: 'The Democratic Republic of Congo',
    continent: 'Africa'
  },
  {
    text: 'Togo',
    continent: 'Africa'
  },
  {
    text: 'Tokelau',
    continent: 'Oceania'
  },
  {
    text: 'Tonga',
    continent: 'Oceania'
  },
  {
    text: 'Trinidad and Tobago',
    continent: 'North America'
  },
  {
    text: 'Tunisia',
    continent: 'Africa'
  },
  {
    text: 'Turkey',
    continent: 'Asia'
  },
  {
    text: 'Turkmenistan',
    continent: 'Asia'
  },
  {
    text: 'Turks and Caicos Islands',
    continent: 'North America'
  },
  {
    text: 'Tuvalu',
    continent: 'Oceania'
  },
  {
    text: 'Uganda',
    continent: 'Africa'
  },
  {
    text: 'Ukraine',
    continent: 'Europe'
  },
  {
    text: 'United Arab Emirates',
    continent: 'Asia'
  },
  {
    text: 'United Kingdom',
    continent: 'Europe'
  },
  {
    text: 'United States',
    continent: 'North America'
  },
  {
    text: 'United States Minor Outlying Islands',
    continent: 'Oceania'
  },
  {
    text: 'Uruguay',
    continent: 'South America'
  },
  {
    text: 'Uzbekistan',
    continent: 'Asia'
  },
  {
    text: 'Vanuatu',
    continent: 'Oceania'
  },
  {
    text: 'Venezuela',
    continent: 'South America'
  },
  {
    text: 'Vietnam',
    continent: 'Asia'
  },
  {
    text: 'Virgin Islands, British',
    continent: 'North America'
  },
  {
    text: 'Virgin Islands, U.S.',
    continent: 'North America'
  },
  {
    text: 'Wales',
    continent: 'Europe'
  },
  {
    text: 'Wallis and Futuna',
    continent: 'Oceania'
  },
  {
    text: 'Western Sahara',
    continent: 'Africa'
  },
  {
    text: 'Yemen',
    continent: 'Asia'
  },
  {
    text: 'Zambia',
    continent: 'Africa'
  },
  {
    text: 'Zimbabwe',
    continent: 'Africa'
  }
];

export const AVAILABILITY_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

export const LANGUAGES = [
  { code: 'aa', name: 'Afar', countryCode: '231' },
  { code: 'ab', name: 'Abkhazian', countryCode: '268' },
  { code: 'af', name: 'Afrikaans', countryCode: '710' },
  { code: 'ak', name: 'Akan', countryCode: '288' },
  { code: 'am', name: 'Amharic', countryCode: '231' },
  { code: 'an', name: 'Aragonese', countryCode: '724' },
  { code: 'ar', name: 'Arabic Algeria', countryCode: '012' },
  { code: 'ar', name: 'Arabic Bahrain', countryCode: '048' },
  { code: 'ar', name: 'Arabic Chad', countryCode: '148' },
  { code: 'ar', name: 'Arabic Comoros', countryCode: '174' },
  { code: 'ar', name: 'Arabic Djibouti', countryCode: '262' },
  { code: 'ar', name: 'Arabic Egypt', countryCode: '818' },
  { code: 'ar', name: 'Arabic Iraq', countryCode: '368' },
  { code: 'ar', name: 'Arabic Jordan', countryCode: '400' },
  { code: 'ar', name: 'Arabic Kuwait', countryCode: '414' },
  { code: 'ar', name: 'Arabic Lebanon', countryCode: '422' },
  { code: 'ar', name: 'Arabic Libya', countryCode: '434' },
  { code: 'ar', name: 'Arabic Mauritania', countryCode: '478' },
  { code: 'ar', name: 'Arabic Morocco', countryCode: '504' },
  { code: 'ar', name: 'Arabic Palestine', countryCode: '275' },
  { code: 'ar', name: 'Arabic Qatar', countryCode: '634' },
  { code: 'ar', name: 'Arabic Saudi Arabia', countryCode: '682' },
  { code: 'ar', name: 'Arabic Somalia', countryCode: '706' },
  { code: 'ar', name: 'Arabic Sudan', countryCode: '729' },
  { code: 'ar', name: 'Arabic Syria', countryCode: '760' },
  { code: 'ar', name: 'Arabic Tunisia', countryCode: '788' },
  { code: 'ar', name: 'Arabic United Arab Emirates', countryCode: '784' },
  { code: 'ar', name: 'Arabic Yemen', countryCode: '887' },
  { code: 'as', name: 'Assamese', countryCode: '356' },
  { code: 'av', name: 'Avaric', countryCode: '643' },
  { code: 'ay', name: 'Aymara', countryCode: '068' },
  { code: 'az', name: 'Azerbaijani', countryCode: '031' },
  { code: 'ba', name: 'Bashkir', countryCode: '643' },
  { code: 'be', name: 'Belarusian', countryCode: '112' },
  { code: 'bg', name: 'Bulgarian', countryCode: '100' },
  { code: 'bh', name: 'Bihari languages', countryCode: '356' },
  { code: 'bi', name: 'Bislama', countryCode: '548' },
  { code: 'bm', name: 'Bambara', countryCode: '466' },
  { code: 'bn', name: 'Bengali', countryCode: '050' },
  { code: 'bo', name: 'Tibetan', countryCode: '156' },
  { code: 'br', name: 'Breton', countryCode: '250' },
  { code: 'bs', name: 'Bosnian', countryCode: '070' },
  { code: 'ca', name: 'Catalan; Valencian', countryCode: '020' },
  { code: 'ce', name: 'Chechen', countryCode: '643' },
  { code: 'ch', name: 'Chamorro', countryCode: '316' },
  { code: 'co', name: 'Corsican', countryCode: '250' },
  { code: 'cr', name: 'Cree', countryCode: '840' },
  { code: 'cs', name: 'Czech', countryCode: '203' },
  {
    code: 'cu',
    name: 'Church Slavic',
    countryCode: ''
  },
  { code: 'cv', name: 'Chuvash', countryCode: '643' },
  { code: 'cy', name: 'Welsh', countryCode: '826' },
  { code: 'da', name: 'Danish', countryCode: '208' },
  { code: 'de', name: 'German', countryCode: '276' },
  { code: 'dv', name: 'Divehi; Dhivehi; Maldivian', countryCode: '462' },
  { code: 'dz', name: 'Dzongkha', countryCode: '064' },
  { code: 'ee', name: 'Ewe', countryCode: '288' },
  { code: 'el', name: 'Greek, Modern (1453-)', countryCode: '300' },
  { code: 'en', name: 'English UK', countryCode: '826' },
  { code: 'en', name: 'English Australıa', countryCode: '036' },
  { code: 'en', name: 'English United States', countryCode: '840' },
  { code: 'eo', name: 'Esperanto', countryCode: '' },
  { code: 'es', name: 'Spanish; Castilian', countryCode: '724' },
  { code: 'et', name: 'Estonian', countryCode: '233' },
  { code: 'eu', name: 'Basque', countryCode: '724' },
  { code: 'fa', name: 'Persian', countryCode: '364' },
  { code: 'ff', name: 'Fulah', countryCode: '854' },
  { code: 'fi', name: 'Finnish', countryCode: '246' },
  { code: 'fj', name: 'Fijian', countryCode: '242' },
  { code: 'fo', name: 'Faroese', countryCode: '234' },
  { code: 'fr', name: 'French', countryCode: '250' },
  { code: 'fy', name: 'Western Frisian', countryCode: '528' },
  { code: 'ga', name: 'Irish', countryCode: '372' },
  { code: 'gd', name: 'Gaelic; Scomttish Gaelic', countryCode: '826' },
  { code: 'gl', name: 'Galician', countryCode: '724' },
  { code: 'gn', name: 'Guarani', countryCode: '600' },
  { code: 'gu', name: 'Gujarati', countryCode: '356' },
  { code: 'gv', name: 'Manx', countryCode: '833' },
  { code: 'ha', name: 'Hausa', countryCode: '566' },
  { code: 'he', name: 'Hebrew', countryCode: '376' },
  { code: 'hi', name: 'Hindi', countryCode: '356' },
  { code: 'ho', name: 'Hiri Motu', countryCode: '598' },
  { code: 'hr', name: 'Croatian', countryCode: '191' },
  { code: 'ht', name: 'Haitian; Haitian Creole', countryCode: '332' },
  { code: 'hu', name: 'Hungarian', countryCode: '348' },
  { code: 'hy', name: 'Armenian', countryCode: '051' },
  { code: 'hz', name: 'Herero', countryCode: '516' },
  {
    code: 'ia',
    name: 'Interlingua (International Auxiliary Language Association)',
    countryCode: ''
  },
  { code: 'id', name: 'Indonesian', countryCode: '360' },
  { code: 'ie', name: 'Interlingue; Occidental', countryCode: '' },
  { code: 'ig', name: 'Igbo', countryCode: '566' },
  { code: 'ii', name: 'Sichuan Yi; Nuosu', countryCode: '156' },
  { code: 'ik', name: 'Inupiaq', countryCode: '840' },
  { code: 'io', name: 'Ido', countryCode: '' },
  { code: 'is', name: 'Icelandic', countryCode: '352' },
  { code: 'it', name: 'Italian', countryCode: '380' },
  { code: 'iu', name: 'Inuktitut', countryCode: '124' },
  { code: 'ja', name: 'Japanese', countryCode: '392' },
  { code: 'jv', name: 'Javanese', countryCode: '360' },
  { code: 'ka', name: 'Georgian', countryCode: '268' },
  { code: 'kg', name: 'Kongo', countryCode: '180' },
  { code: 'ki', name: 'Kikuyu; Gikuyu', countryCode: '404' },
  { code: 'kj', name: 'Kuanyama; Kwanyama', countryCode: '516' },
  { code: 'kk', name: 'Kazakh', countryCode: '398' },
  { code: 'kl', name: 'Kalaallisut; Greenlandic', countryCode: '304' },
  { code: 'km', name: 'Central Khmer', countryCode: '116' },
  { code: 'kn', name: 'Kannada', countryCode: '356' },
  { code: 'ko', name: 'Korean', countryCode: '410' },
  { code: 'kr', name: 'Kanuri', countryCode: '566' },
  { code: 'ks', name: 'Kashmiri', countryCode: '356' },
  { code: 'ku', name: 'Kurdish', countryCode: '368' },
  { code: 'kv', name: 'Komi', countryCode: '643' },
  { code: 'kw', name: 'Cornish', countryCode: '826' },
  { code: 'ky', name: 'Kirghiz; Kyrgyz', countryCode: '417' },
  { code: 'la', name: 'Latin', countryCode: '336' },
  { code: 'lb', name: 'Luxembourgish; Letzeburgesch', countryCode: '442' },
  { code: 'lg', name: 'Ganda', countryCode: '800' },
  { code: 'li', name: 'Limburgan; Limburger; Limburgish', countryCode: '528' },
  { code: 'ln', name: 'Lingala', countryCode: '180' },
  { code: 'lo', name: 'Lao', countryCode: '418' },
  { code: 'lt', name: 'Lithuanian', countryCode: '440' },
  { code: 'lu', name: 'Luba-Katanga', countryCode: '180' },
  { code: 'lv', name: 'Latvian', countryCode: '428' },
  { code: 'mg', name: 'Malagasy', countryCode: '450' },
  { code: 'mh', name: 'Marshallese', countryCode: '584' },
  { code: 'mi', name: 'Maori', countryCode: '554' },
  { code: 'mk', name: 'Macedonian', countryCode: '807' },
  { code: 'ml', name: 'Malayalam', countryCode: '356' },
  { code: 'mn', name: 'Mongolian', countryCode: '496' },
  { code: 'mr', name: 'Marathi', countryCode: '356' },
  { code: 'ms', name: 'Malay', countryCode: '096' },
  { code: 'mt', name: 'Maltese', countryCode: '470' },
  { code: 'my', name: 'Burmese', countryCode: '104' },
  { code: 'na', name: 'Nauru', countryCode: '520' },
  {
    code: 'nb',
    name: 'Bokmål, Norwegian; Norwegian Bokmål',
    countryCode: '578'
  },
  { code: 'nd', name: 'Ndebele, North; North Ndebele', countryCode: '716' },
  { code: 'ne', name: 'Nepali', countryCode: '524' },
  { code: 'ng', name: 'Ndonga', countryCode: '516' },
  { code: 'nl', name: 'Dutch; Flemish', countryCode: '528' },
  { code: 'nn', name: 'Norwegian Nynorsk; Nynorsk, Norwegian', countryCode: '578' },
  { code: 'no', name: 'Norwegian', countryCode: '578' },
  { code: 'nr', name: 'Ndebele, South; South Ndebele', countryCode: '710' },
  { code: 'nv', name: 'Navajo; Navaho', countryCode: '840' },
  { code: 'ny', name: 'Chichewa; Chewa; Nyanja', countryCode: '454' },
  { code: 'oc', name: 'Occitan (post 1500)', countryCode: '724' },
  { code: 'oj', name: 'Ojibwa', countryCode: '124' },
  { code: 'om', name: 'Oromo', countryCode: '231' },
  { code: 'or', name: 'Oriya', countryCode: '356' },
  { code: 'os', name: 'Ossetian; Ossetic', countryCode: '643' },
  { code: 'pa', name: 'Panjabi; Punjabi', countryCode: '586' },
  { code: 'pi', name: 'Pali', countryCode: '144' },
  { code: 'pl', name: 'Polish', countryCode: '616' },
  { code: 'ps', name: 'Pushto; Pashto', countryCode: '586' },
  { code: 'pt', name: 'Portuguese', countryCode: '620' },
  { code: 'qu', name: 'Quechua', countryCode: '032' },
  { code: 'rm', name: 'Romansh', countryCode: '756' },
  { code: 'rn', name: 'Rundi', countryCode: '108' },
  { code: 'ro', name: 'Romanian; Moldavian; Moldovan', countryCode: '642' },
  { code: 'ru', name: 'Russian', countryCode: '643' },
  { code: 'rw', name: 'Kinyarwanda', countryCode: '646' },
  { code: 'sa', name: 'Sanskrit', countryCode: '356' },
  { code: 'sc', name: 'Sardinian', countryCode: '380' },
  { code: 'sd', name: 'Sindhi', countryCode: '586' },
  { code: 'se', name: 'Northern Sami', countryCode: '578' },
  { code: 'sg', name: 'Sango', countryCode: '140' },
  { code: 'si', name: 'Sinhala; Sinhalese', countryCode: '144' },
  { code: 'sk', name: 'Slovak', countryCode: '703' },
  { code: 'sl', name: 'Slovenian', countryCode: '705' },
  { code: 'sm', name: 'Samoan', countryCode: '016' },
  { code: 'sn', name: 'Shona', countryCode: '716' },
  { code: 'so', name: 'Somali', countryCode: '706' },
  { code: 'sq', name: 'Albanian', countryCode: '008' },
  { code: 'sr', name: 'Serbian', countryCode: '688' },
  { code: 'ss', name: 'Swati', countryCode: '710' },
  { code: 'st', name: 'Sotho, Southern', countryCode: '710' },
  { code: 'su', name: 'Sundanese', countryCode: '360' },
  { code: 'sv', name: 'Swedish', countryCode: '752' },
  { code: 'sw', name: 'Swahili', countryCode: '834' },
  { code: 'ta', name: 'Tamil', countryCode: '356' },
  { code: 'te', name: 'Telugu', countryCode: '356' },
  { code: 'tg', name: 'Tajik', countryCode: '762' },
  { code: 'th', name: 'Thai', countryCode: '764' },
  { code: 'ti', name: 'Tigrinya', countryCode: '232' },
  { code: 'tk', name: 'Turkmen', countryCode: '795' },
  { code: 'tl', name: 'Tagalog', countryCode: '608' },
  { code: 'tn', name: 'Tswana', countryCode: '072' },
  { code: 'to', name: 'Tonga (Tonga Islands)', countryCode: '776' },
  { code: 'tr', name: 'Turkish', countryCode: '792' },
  { code: 'ts', name: 'Tsonga', countryCode: '710' },
  { code: 'tt', name: 'Tatar', countryCode: '643' },
  { code: 'tw', name: 'Twi', countryCode: '288' },
  { code: 'ty', name: 'Tahitian', countryCode: '258' },
  { code: 'ug', name: 'Uighur; Uyghur', countryCode: '156' },
  { code: 'uk', name: 'Ukrainian', countryCode: '804' },
  { code: 'ur', name: 'Urdu', countryCode: '586' },
  { code: 'uz', name: 'Uzbek', countryCode: '860' },
  { code: 've', name: 'Venda', countryCode: '710' },
  { code: 'vi', name: 'Vietnamese', countryCode: '704' },
  { code: 'vo', name: 'Volapük', countryCode: '' },
  { code: 'wa', name: 'Walloon', countryCode: '056' },
  { code: 'wo', name: 'Wolof', countryCode: '686' },
  { code: 'xh', name: 'Xhosa', countryCode: '710' },
  { code: 'yi', name: 'Yiddish', countryCode: '376' },
  { code: 'yo', name: 'Yoruba', countryCode: '566' },
  { code: 'za', name: 'Zhuang; Chuang', countryCode: '156' },
  { code: 'zh', name: 'Chinese', countryCode: '156' },
  { code: 'zu', name: 'Zulu', countryCode: '710' }
];

export const TZ_STRING = [
  { label: ' CET (Central European Time) - Europe/Paris', value: 'Europe/Paris' },
  { label: 'Athens, Bucharest, Istanbul - Europe/Athens', value: 'Europe/Athens' },
  { label: 'International Date Line West - Etc/GMT+12', value: 'Etc/GMT+12' },
  { label: 'Midway Island, Samoa - Pacific/Midway', value: 'Pacific/Midway' },
  { label: 'Hawaii - Pacific/Honolulu', value: 'Pacific/Honolulu' },
  { label: 'Alaska - US/Alaska', value: 'US/Alaska' },
  { label: 'Pacific Time (US & Canada) - America/Los_Angeles', value: 'America/Los_Angeles' },
  { label: 'Tijuana, Baja California - America/Tijuana', value: 'America/Tijuana' },
  { label: 'Arizona - US/Arizona', value: 'US/Arizona' },
  { label: 'Chihuahua, La Paz, Mazatlan - America/Chihuahua', value: 'America/Chihuahua' },
  { label: 'Mountain Time (US & Canada) - US/Mountain', value: 'US/Mountain' },
  { label: 'Central America - America/Managua', value: 'America/Managua' },
  { label: 'Central Time (US & Canada) - US/Central', value: 'US/Central' },
  {
    label: 'Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London - Etc/Greenwich',
    value: 'Etc/Greenwich'
  },
  {
    label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna - Europe/Amsterdam',
    value: 'Europe/Amsterdam'
  },
  {
    label: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague - Europe/Belgrade',
    value: 'Europe/Belgrade'
  },
  {
    label: 'Guadalajara, Mexico City, Monterrey - America/Mexico_City',
    value: 'America/Mexico_City'
  },
  { label: 'Saskatchewan - Canada/Saskatchewan', value: 'Canada/Saskatchewan' },
  { label: 'Bogota, Lima, Quito, Rio Branco - America/Bogota', value: 'America/Bogota' },
  { label: 'Eastern Time (US & Canada) - US/Eastern', value: 'US/Eastern' },
  { label: 'Indiana (East) - US/East-Indiana', value: 'US/East-Indiana' },
  { label: 'Atlantic Time (Canada) - Canada/Atlantic', value: 'Canada/Atlantic' },
  { label: 'Caracas, La Paz - America/Caracas', value: 'America/Caracas' },
  { label: 'Manaus - America/Manaus', value: 'America/Manaus' },
  { label: 'Santiago - America/Santiago', value: 'America/Santiago' },
  { label: 'Newfoundland - Canada/Newfoundland', value: 'Canada/Newfoundland' },
  { label: 'Brasilia - America/Sao_Paulo', value: 'America/Sao_Paulo' },
  {
    label: 'Buenos Aires, Georgetown - America/Argentina/Buenos_Aires',
    value: 'America/Argentina/Buenos_Aires'
  },
  { label: 'Greenland - America/Godthab', value: 'America/Godthab' },
  { label: 'Montevideo - America/Montevideo', value: 'America/Montevideo' },
  { label: 'Mid-Atlantic - America/Noronha', value: 'America/Noronha' },
  { label: 'Cape Verde Is. - Atlantic/Cape_Verde', value: 'Atlantic/Cape_Verde' },
  { label: 'Azores - Atlantic/Azores', value: 'Atlantic/Azores' },
  { label: 'Casablanca, Monrovia, Reykjavik - Africa/Casablanca', value: 'Africa/Casablanca' },

  { label: 'Brussels, Copenhagen, Madrid, Paris - Europe/Brussels', value: 'Europe/Brussels' },
  { label: 'Sarajevo, Skopje, Warsaw, Zagreb - Europe/Sarajevo', value: 'Europe/Sarajevo' },
  { label: 'West Central Africa - Africa/Lagos', value: 'Africa/Lagos' },
  { label: 'Amman - Asia/Amman', value: 'Asia/Amman' },
  { label: 'Beirut - Asia/Beirut', value: 'Asia/Beirut' },
  { label: 'Cairo - Africa/Cairo', value: 'Africa/Cairo' },
  { label: 'Harare, Pretoria - Africa/Harare', value: 'Africa/Harare' },
  {
    label: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius - Europe/Helsinki',
    value: 'Europe/Helsinki'
  },
  { label: 'Jerusalem - Asia/Jerusalem', value: 'Asia/Jerusalem' },
  { label: 'Minsk - Europe/Minsk', value: 'Europe/Minsk' },
  { label: 'Windhoek - Africa/Windhoek', value: 'Africa/Windhoek' },
  { label: 'Kuwait, Riyadh, Baghdad - Asia/Kuwait', value: 'Asia/Kuwait' },
  { label: 'Moscow, St. Petersburg, Volgograd - Europe/Moscow', value: 'Europe/Moscow' },
  { label: 'Nairobi - Africa/Nairobi', value: 'Africa/Nairobi' },
  { label: 'Tbilisi - Asia/Tbilisi', value: 'Asia/Tbilisi' },
  { label: 'Tehran - Asia/Tehran', value: 'Asia/Tehran' },
  { label: 'Abu Dhabi, Muscat - Asia/Muscat', value: 'Asia/Muscat' },
  { label: 'Baku - Asia/Baku', value: 'Asia/Baku' },
  { label: 'Yerevan - Asia/Yerevan', value: 'Asia/Yerevan' },
  { label: 'Kabul - Asia/Kabul', value: 'Asia/Kabul' },
  { label: 'Yekaterinburg - Asia/Yekaterinburg', value: 'Asia/Yekaterinburg' },
  { label: 'Islamabad, Karachi, Tashkent - Asia/Karachi', value: 'Asia/Karachi' },
  { label: 'Chennai, Kolkata, Mumbai, New Delhi - Asia/Calcutta', value: 'Asia/Calcutta' },
  { label: 'Sri Jayawardenapura - Asia/Calcutta', value: 'Asia/Calcutta' },
  { label: 'Kathmandu - Asia/Katmandu', value: 'Asia/Katmandu' },
  { label: 'Almaty, Novosibirsk - Asia/Almaty', value: 'Asia/Almaty' },
  { label: 'Astana, Dhaka - Asia/Dhaka', value: 'Asia/Dhaka' },
  { label: 'Yangon (Rangoon) - Asia/Rangoon', value: 'Asia/Rangoon' },
  { label: 'Bangkok, Hanoi, Jakarta - Asia/Bangkok', value: 'Asia/Bangkok' },
  { label: 'Krasnoyarsk - Asia/Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  { label: 'Beijing, Chongqing, Hong Kong, Urumqi - Asia/Hong_Kong', value: 'Asia/Hong_Kong' },
  { label: 'Kuala Lumpur, Singapore - Asia/Kuala_Lumpur', value: 'Asia/Kuala_Lumpur' },
  { label: 'Irkutsk, Ulaan Bataar - Asia/Irkutsk', value: 'Asia/Irkutsk' },
  { label: 'Perth - Australia/Perth', value: 'Australia/Perth' },
  { label: 'Taipei - Asia/Taipei', value: 'Asia/Taipei' },
  { label: 'Osaka, Sapporo, Tokyo - Asia/Tokyo', value: 'Asia/Tokyo' },
  { label: 'Seoul - Asia/Seoul', value: 'Asia/Seoul' },
  { label: 'Yakutsk - Asia/Yakutsk', value: 'Asia/Yakutsk' },
  { label: 'Adelaide - Australia/Adelaide', value: 'Australia/Adelaide' },
  { label: 'Darwin - Australia/Darwin', value: 'Australia/Darwin' },
  { label: 'Brisbane - Australia/Brisbane', value: 'Australia/Brisbane' },
  { label: 'Canberra, Melbourne, Sydney - Australia/Canberra', value: 'Australia/Canberra' },
  { label: 'Hobart - Australia/Hobart', value: 'Australia/Hobart' },
  { label: 'Guam, Port Moresby - Pacific/Guam', value: 'Pacific/Guam' },
  { label: 'Vladivostok - Asia/Vladivostok', value: 'Asia/Vladivostok' },
  { label: 'Magadan, Solomon Is., New Caledonia - Asia/Magadan', value: 'Asia/Magadan' },
  { label: 'Auckland, Wellington - Pacific/Auckland', value: 'Pacific/Auckland' },
  { label: 'Fiji, Kamchatka, Marshall Is. - Pacific/Fiji', value: 'Pacific/Fiji' },
  { label: "Nuku'alofa - Pacific/Tongatapu", value: 'Pacific/Tongatapu' }
];
export const COUNTRIES_DIALCODES = [
  {
    name: 'Afghanistan',
    dialCode: '+93',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/af.svg'
  },
  {
    name: 'Aland Islands',
    dialCode: '+358',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ax.svg'
  },
  {
    name: 'Albania',
    dialCode: '+355',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/al.svg'
  },
  {
    name: 'Algeria',
    dialCode: '+213',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dz.svg'
  },
  {
    name: 'American Samoa',
    dialCode: '+1684',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/as.svg'
  },
  {
    name: 'Andorra',
    dialCode: '+376',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ad.svg'
  },
  {
    name: 'Angola',
    dialCode: '+244',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ao.svg'
  },
  {
    name: 'Anguilla',
    dialCode: '+1264',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ai.svg'
  },
  {
    name: 'Antarctica',
    dialCode: '+672',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/aq.svg'
  },
  {
    name: 'Antigua and Barbuda',
    dialCode: '+1268',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ag.svg'
  },
  {
    name: 'Argentina',
    dialCode: '+54',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ar.svg'
  },
  {
    name: 'Armenia',
    dialCode: '+374',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/am.svg'
  },
  {
    name: 'Aruba',
    dialCode: '+297',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/aw.svg'
  },
  {
    name: 'Ascension Island',
    dialCode: '+247',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ac.svg'
  },
  {
    name: 'Australia',
    dialCode: '+61',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/au.svg'
  },
  {
    name: 'Austria',
    dialCode: '+43',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/at.svg'
  },
  {
    name: 'Azerbaijan',
    dialCode: '+994',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/az.svg'
  },
  {
    name: 'Bahamas',
    dialCode: '+1242',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bs.svg'
  },
  {
    name: 'Bahrain',
    dialCode: '+973',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bh.svg'
  },
  {
    name: 'Bangladesh',
    dialCode: '+880',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bd.svg'
  },
  {
    name: 'Barbados',
    dialCode: '+1246',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bb.svg'
  },
  {
    name: 'Belarus',
    dialCode: '+375',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/by.svg'
  },
  {
    name: 'Belgium',
    dialCode: '+32',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/be.svg'
  },
  {
    name: 'Belize',
    dialCode: '+501',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bz.svg'
  },
  {
    name: 'Benin',
    dialCode: '+229',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bj.svg'
  },
  {
    name: 'Bermuda',
    dialCode: '+1441',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bm.svg'
  },
  {
    name: 'Bhutan',
    dialCode: '+975',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bt.svg'
  },
  {
    name: 'Bolivia',
    dialCode: '+591',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bo.svg'
  },
  {
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ba.svg'
  },
  {
    name: 'Botswana',
    dialCode: '+267',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bw.svg'
  },
  {
    name: 'Brazil',
    dialCode: '+55',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/br.svg'
  },
  {
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/io.svg'
  },
  {
    name: 'Brunei Darussalam',
    dialCode: '+673',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bn.svg'
  },
  {
    name: 'Bulgaria',
    dialCode: '+359',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bg.svg'
  },
  {
    name: 'Burkina Faso',
    dialCode: '+226',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bf.svg'
  },
  {
    name: 'Burundi',
    dialCode: '+257',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bi.svg'
  },
  {
    name: 'Cambodia',
    dialCode: '+855',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kh.svg'
  },
  {
    name: 'Cameroon',
    dialCode: '+237',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cm.svg'
  },
  {
    name: 'Canada',
    dialCode: '+1',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ca.svg'
  },
  {
    name: 'Cape Verde',
    dialCode: '+238',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cv.svg'
  },
  {
    name: 'Cayman Islands',
    dialCode: '+1345',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ky.svg'
  },
  {
    name: 'Central African Republic',
    dialCode: '+236',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cf.svg'
  },
  {
    name: 'Chad',
    dialCode: '+235',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/td.svg'
  },
  {
    name: 'Chile',
    dialCode: '+56',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg'
  },
  {
    name: 'China',
    dialCode: '+86',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cn.svg'
  },
  {
    name: 'Christmas Island',
    dialCode: '+61',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cx.svg'
  },
  {
    name: 'Cocos (Keeling) Islands',
    dialCode: '+61',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cc.svg'
  },
  {
    name: 'Colombia',
    dialCode: '+57',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/co.svg'
  },
  {
    name: 'Comoros',
    dialCode: '+269',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/km.svg'
  },
  {
    name: 'Congo',
    dialCode: '+242',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cg.svg'
  },
  {
    name: 'Cook Islands',
    dialCode: '+682',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ck.svg'
  },
  {
    name: 'Costa Rica',
    dialCode: '+506',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cr.svg'
  },
  {
    name: 'Croatia',
    dialCode: '+385',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hr.svg'
  },
  {
    name: 'Cuba',
    dialCode: '+53',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cu.svg'
  },
  {
    name: 'Cyprus',
    dialCode: '+357',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cy.svg'
  },
  {
    name: 'Czech Republic',
    dialCode: '+420',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cz.svg'
  },
  {
    name: 'Democratic Republic of the Congo',
    dialCode: '+243',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cd.svg'
  },
  {
    name: 'Denmark',
    dialCode: '+45',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dk.svg'
  },
  {
    name: 'Djibouti',
    dialCode: '+253',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dj.svg'
  },
  {
    name: 'Dominica',
    dialCode: '+1767',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dm.svg'
  },
  {
    name: 'Dominican Republic',
    dialCode: '+1849',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/do.svg'
  },
  {
    name: 'Ecuador',
    dialCode: '+593',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ec.svg'
  },
  {
    name: 'Egypt',
    dialCode: '+20',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/eg.svg'
  },
  {
    name: 'El Salvador',
    dialCode: '+503',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sv.svg'
  },
  {
    name: 'Equatorial Guinea',
    dialCode: '+240',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gq.svg'
  },
  {
    name: 'Eritrea',
    dialCode: '+291',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/er.svg'
  },
  {
    name: 'Estonia',
    dialCode: '+372',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ee.svg'
  },
  {
    name: 'Eswatini',
    dialCode: '+268',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sz.svg'
  },
  {
    name: 'Ethiopia',
    dialCode: '+251',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/et.svg'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fk.svg'
  },
  {
    name: 'Faroe Islands',
    dialCode: '+298',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fo.svg'
  },
  {
    name: 'Fiji',
    dialCode: '+679',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fj.svg'
  },
  {
    name: 'Finland',
    dialCode: '+358',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fi.svg'
  },
  {
    name: 'France',
    dialCode: '+33',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fr.svg'
  },
  {
    name: 'French Guiana',
    dialCode: '+594',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gf.svg'
  },
  {
    name: 'French Polynesia',
    dialCode: '+689',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pf.svg'
  },
  {
    name: 'Gabon',
    dialCode: '+241',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ga.svg'
  },
  {
    name: 'Gambia',
    dialCode: '+220',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gm.svg'
  },
  {
    name: 'Georgia',
    dialCode: '+995',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ge.svg'
  },
  {
    name: 'Germany',
    dialCode: '+49',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/de.svg'
  },
  {
    name: 'Ghana',
    dialCode: '+233',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gh.svg'
  },
  {
    name: 'Gibraltar',
    dialCode: '+350',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gi.svg'
  },
  {
    name: 'Greece',
    dialCode: '+30',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gr.svg'
  },
  {
    name: 'Greenland',
    dialCode: '+299',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gl.svg'
  },
  {
    name: 'Grenada',
    dialCode: '+1473',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gd.svg'
  },
  {
    name: 'Guadeloupe',
    dialCode: '+590',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gp.svg'
  },
  {
    name: 'Guam',
    dialCode: '+1671',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gu.svg'
  },
  {
    name: 'Guatemala',
    dialCode: '+502',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gt.svg'
  },
  {
    name: 'Guernsey',
    dialCode: '+44-1481',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gg.svg'
  },
  {
    name: 'Guinea',
    dialCode: '+224',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gn.svg'
  },
  {
    name: 'Guinea-Bissau',
    dialCode: '+245',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gw.svg'
  },
  {
    name: 'Guyana',
    dialCode: '+592',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gy.svg'
  },
  {
    name: 'Haiti',
    dialCode: '+509',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ht.svg'
  },
  {
    name: 'Holy See (Vatican City State)',
    dialCode: '+379',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/va.svg'
  },
  {
    name: 'Honduras',
    dialCode: '+504',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hn.svg'
  },
  {
    name: 'Hong Kong',
    dialCode: '+852',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hk.svg'
  },
  {
    name: 'Hungary',
    dialCode: '+36',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hu.svg'
  },
  {
    name: 'Iceland',
    dialCode: '+354',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/is.svg'
  },
  {
    name: 'India',
    dialCode: '+91',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/in.svg'
  },
  {
    name: 'Indonesia',
    dialCode: '+62',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/id.svg'
  },
  {
    name: 'Iran',
    dialCode: '+98',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ir.svg'
  },
  {
    name: 'Iraq',
    dialCode: '+964',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/iq.svg'
  },
  {
    name: 'Ireland',
    dialCode: '+353',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ie.svg'
  },
  {
    name: 'Isle of Man',
    dialCode: '+44-1624',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/im.svg'
  },
  {
    name: 'Israel',
    dialCode: '+972',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/il.svg'
  },
  {
    name: 'Italy',
    dialCode: '+39',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/it.svg'
  },
  {
    name: "Ivory Coast / Cote d'Ivoire",
    dialCode: '+225',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ci.svg'
  },
  {
    name: 'Jamaica',
    dialCode: '+1876',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jm.svg'
  },
  {
    name: 'Japan',
    dialCode: '+81',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jp.svg'
  },
  {
    name: 'Jersey',
    dialCode: '+44-1534',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/je.svg'
  },
  {
    name: 'Jordan',
    dialCode: '+962',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jo.svg'
  },
  {
    name: 'Kazakhstan',
    dialCode: '+77',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kz.svg'
  },
  {
    name: 'Kenya',
    dialCode: '+254',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ke.svg'
  },
  {
    name: 'Kiribati',
    dialCode: '+686',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ki.svg'
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: '+850',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kp.svg'
  },
  {
    name: 'Korea, Republic of South Korea',
    dialCode: '+82',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kr.svg'
  },
  {
    name: 'Kosovo',
    dialCode: '+383',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/xk.svg'
  },
  {
    name: 'Kuwait',
    dialCode: '+965',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kw.svg'
  },
  {
    name: 'Kyrgyzstan',
    dialCode: '+996',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kg.svg'
  },
  {
    name: 'Laos',
    dialCode: '+856',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/la.svg'
  },
  {
    name: 'Latvia',
    dialCode: '+371',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lv.svg'
  },
  {
    name: 'Lebanon',
    dialCode: '+961',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lb.svg'
  },
  {
    name: 'Lesotho',
    dialCode: '+266',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ls.svg'
  },
  {
    name: 'Liberia',
    dialCode: '+231',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lr.svg'
  },
  {
    name: 'Libya',
    dialCode: '+218',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ly.svg'
  },
  {
    name: 'Liechtenstein',
    dialCode: '+423',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/li.svg'
  },
  {
    name: 'Lithuania',
    dialCode: '+370',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lt.svg'
  },
  {
    name: 'Luxembourg',
    dialCode: '+352',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lu.svg'
  },
  {
    name: 'Macau',
    dialCode: '+853',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mo.svg'
  },
  {
    name: 'Madagascar',
    dialCode: '+261',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mg.svg'
  },
  {
    name: 'Malawi',
    dialCode: '+265',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mw.svg'
  },
  {
    name: 'Malaysia',
    dialCode: '+60',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/my.svg'
  },
  {
    name: 'Maldives',
    dialCode: '+960',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mv.svg'
  },
  {
    name: 'Mali',
    dialCode: '+223',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ml.svg'
  },
  {
    name: 'Malta',
    dialCode: '+356',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mt.svg'
  },
  {
    name: 'Marshall Islands',
    dialCode: '+692',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mh.svg'
  },
  {
    name: 'Martinique',
    dialCode: '+596',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mq.svg'
  },
  {
    name: 'Mauritania',
    dialCode: '+222',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mr.svg'
  },
  {
    name: 'Mauritius',
    dialCode: '+230',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mu.svg'
  },
  {
    name: 'Mayotte',
    dialCode: '+262',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/yt.svg'
  },
  {
    name: 'Mexico',
    dialCode: '+52',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mx.svg'
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dialCode: '+691',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fm.svg'
  },
  {
    name: 'Moldova',
    dialCode: '+373',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/md.svg'
  },
  {
    name: 'Monaco',
    dialCode: '+377',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mc.svg'
  },
  {
    name: 'Mongolia',
    dialCode: '+976',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mn.svg'
  },
  {
    name: 'Montenegro',
    dialCode: '+382',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/me.svg'
  },
  {
    name: 'Montserrat',
    dialCode: '+1664',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ms.svg'
  },
  {
    name: 'Morocco',
    dialCode: '+212',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ma.svg'
  },
  {
    name: 'Mozambique',
    dialCode: '+258',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mz.svg'
  },
  {
    name: 'Myanmar',
    dialCode: '+95',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mm.svg'
  },
  {
    name: 'Namibia',
    dialCode: '+264',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/na.svg'
  },
  {
    name: 'Nauru',
    dialCode: '+674',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nr.svg'
  },
  {
    name: 'Nepal',
    dialCode: '+977',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/np.svg'
  },
  {
    name: 'Netherlands',
    dialCode: '+31',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nl.svg'
  },
  {
    name: 'Netherlands Antilles',
    dialCode: '+599',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/an.svg'
  },
  {
    name: 'New Caledonia',
    dialCode: '+687',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nc.svg'
  },
  {
    name: 'New Zealand',
    dialCode: '+64',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nz.svg'
  },
  {
    name: 'Nicaragua',
    dialCode: '+505',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ni.svg'
  },
  {
    name: 'Niger',
    dialCode: '+227',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ne.svg'
  },
  {
    name: 'Nigeria',
    dialCode: '+234',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ng.svg'
  },
  {
    name: 'Niue',
    dialCode: '+683',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nu.svg'
  },
  {
    name: 'Norfolk Island',
    dialCode: '+672',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nf.svg'
  },
  {
    name: 'North Macedonia',
    dialCode: '+389',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mk.svg'
  },
  {
    name: 'Northern Mariana Islands',
    dialCode: '+1670',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mp.svg'
  },
  {
    name: 'Norway',
    dialCode: '+47',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/no.svg'
  },
  {
    name: 'Oman',
    dialCode: '+968',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/om.svg'
  },
  {
    name: 'Pakistan',
    dialCode: '+92',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pk.svg'
  },
  {
    name: 'Palau',
    dialCode: '+680',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pw.svg'
  },
  {
    name: 'Palestine',
    dialCode: '+970',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ps.svg'
  },
  {
    name: 'Panama',
    dialCode: '+507',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pa.svg'
  },
  {
    name: 'Papua New Guinea',
    dialCode: '+675',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pg.svg'
  },
  {
    name: 'Paraguay',
    dialCode: '+595',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/py.svg'
  },
  {
    name: 'Peru',
    dialCode: '+51',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pe.svg'
  },
  {
    name: 'Philippines',
    dialCode: '+63',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ph.svg'
  },
  {
    name: 'Pitcairn',
    dialCode: '+872',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pn.svg'
  },
  {
    name: 'Poland',
    dialCode: '+48',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pl.svg'
  },
  {
    name: 'Portugal',
    dialCode: '+351',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pt.svg'
  },
  {
    name: 'Puerto Rico',
    dialCode: '+1939',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pr.svg'
  },
  {
    name: 'Qatar',
    dialCode: '+974',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/qa.svg'
  },
  {
    name: 'Reunion',
    dialCode: '+262',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/re.svg'
  },
  {
    name: 'Romania',
    dialCode: '+40',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ro.svg'
  },
  {
    name: 'Russia',
    dialCode: '+7',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ru.svg'
  },
  {
    name: 'Rwanda',
    dialCode: '+250',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/rw.svg'
  },
  {
    name: 'Saint Barthelemy',
    dialCode: '+590',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bl.svg'
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sh.svg'
  },
  {
    name: 'Saint Kitts and Nevis',
    dialCode: '+1869',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kn.svg'
  },
  {
    name: 'Saint Lucia',
    dialCode: '+1758',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lc.svg'
  },
  {
    name: 'Saint Martin',
    dialCode: '+590',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mf.svg'
  },
  {
    name: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pm.svg'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dialCode: '+1784',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vc.svg'
  },
  {
    name: 'Samoa',
    dialCode: '+685',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ws.svg'
  },
  {
    name: 'San Marino',
    dialCode: '+378',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sm.svg'
  },
  {
    name: 'Sao Tome and Principe',
    dialCode: '+239',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/st.svg'
  },
  {
    name: 'Saudi Arabia',
    dialCode: '+966',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sa.svg'
  },
  {
    name: 'Senegal',
    dialCode: '+221',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sn.svg'
  },
  {
    name: 'Serbia',
    dialCode: '+381',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/rs.svg'
  },
  {
    name: 'Seychelles',
    dialCode: '+248',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sc.svg'
  },
  {
    name: 'Sierra Leone',
    dialCode: '+232',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sl.svg'
  },
  {
    name: 'Singapore',
    dialCode: '+65',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sg.svg'
  },
  {
    name: 'Sint Maarten',
    dialCode: '+1721',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sx.svg'
  },
  {
    name: 'Slovakia',
    dialCode: '+421',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sk.svg'
  },
  {
    name: 'Slovenia',
    dialCode: '+386',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/si.svg'
  },
  {
    name: 'Solomon Islands',
    dialCode: '+677',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sb.svg'
  },
  {
    name: 'Somalia',
    dialCode: '+252',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/so.svg'
  },
  {
    name: 'South Africa',
    dialCode: '+27',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/za.svg'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gs.svg'
  },
  {
    name: 'South Sudan',
    dialCode: '+211',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ss.svg'
  },
  {
    name: 'Spain',
    dialCode: '+34',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/es.svg'
  },
  {
    name: 'Sri Lanka',
    dialCode: '+94',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lk.svg'
  },
  {
    name: 'Sudan',
    dialCode: '+249',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sd.svg'
  },
  {
    name: 'Suriname',
    dialCode: '+597',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sr.svg'
  },
  {
    name: 'Svalbard and Jan Mayen',
    dialCode: '+47',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sj.svg'
  },
  {
    name: 'Sweden',
    dialCode: '+46',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/se.svg'
  },
  {
    name: 'Switzerland',
    dialCode: '+41',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ch.svg'
  },
  {
    name: 'Syrian Arab Republic',
    dialCode: '+963',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sy.svg'
  },
  {
    name: 'Taiwan',
    dialCode: '+886',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tw.svg'
  },
  {
    name: 'Tajikistan',
    dialCode: '+992',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tj.svg'
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dialCode: '+255',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tz.svg'
  },
  {
    name: 'Thailand',
    dialCode: '+66',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/th.svg'
  },
  {
    name: 'Timor-Leste',
    dialCode: '+670',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tl.svg'
  },
  {
    name: 'Togo',
    dialCode: '+228',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tg.svg'
  },
  {
    name: 'Tokelau',
    dialCode: '+690',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tk.svg'
  },
  {
    name: 'Tonga',
    dialCode: '+676',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/to.svg'
  },
  {
    name: 'Trinidad and Tobago',
    dialCode: '+1868',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tt.svg'
  },
  {
    name: 'Tunisia',
    dialCode: '+216',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tn.svg'
  },
  {
    name: 'Turkey',
    dialCode: '+90',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tr.svg'
  },
  {
    name: 'Turkmenistan',
    dialCode: '+993',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tm.svg'
  },
  {
    name: 'Turks and Caicos Islands',
    dialCode: '+1649',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tc.svg'
  },
  {
    name: 'Tuvalu',
    dialCode: '+688',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tv.svg'
  },
  {
    name: 'Uganda',
    dialCode: '+256',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ug.svg'
  },
  {
    name: 'Ukraine',
    dialCode: '+380',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ua.svg'
  },
  {
    name: 'United Arab Emirates',
    dialCode: '+971',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ae.svg'
  },
  {
    name: 'United Kingdom',
    dialCode: '+44',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gb.svg'
  },
  {
    name: 'United States',
    dialCode: '+1',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/us.svg'
  },
  {
    name: 'United States Minor Outlying Islands',
    dialCode: '+246',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/umi.svg'
  },
  {
    name: 'Uruguay',
    dialCode: '+598',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/uy.svg'
  },
  {
    name: 'Uzbekistan',
    dialCode: '+998',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/uz.svg'
  },
  {
    name: 'Vanuatu',
    dialCode: '+678',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vu.svg'
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dialCode: '+58',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ve.svg'
  },
  {
    name: 'Vietnam',
    dialCode: '+84',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vn.svg'
  },
  {
    name: 'Virgin Islands, British',
    dialCode: '+1284',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vg.svg'
  },
  {
    name: 'Virgin Islands, U.S.',
    dialCode: '+1340',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vi.svg'
  },
  {
    name: 'Wallis and Futuna',
    dialCode: '+681',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/wf.svg'
  },
  {
    name: 'Yemen',
    dialCode: '+967',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ye.svg'
  },
  {
    name: 'Zambia',
    dialCode: '+260',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/zm.svg'
  },
  {
    name: 'Zimbabwe',
    dialCode: '+263',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/zw.svg'
  }
];
export const PROFILE_SECTIONS = {
  home: 'home',
  role: 'role',
  awards: 'awards',
  reviews: 'reviews',
  workingHours: 'workingHours',
  itEssentials: 'itEssentials'
};

export const OCCUPATIONS = [
  'Accountant',
  'Actor',
  'Actuary',
  'Adhesive Bonding Machine Tender',
  'Adjudicator',
  'Administrative Assistant',
  'Administrative Services Manager',
  'Adult Education Teacher',
  'Advertising Manager',
  'Advertising Sales Agent',
  'Aerobics Instructor',
  'Aerospace Engineer',
  'Aerospace Engineering Technician',
  'Agent',
  'Agricultural Engineer',
  'Agricultural Equipment Operator',
  'Agricultural Grader',
  'Agricultural Inspector',
  'Agricultural Manager',
  'Agricultural Sciences Teacher',
  'Agricultural Sorter',
  'Agricultural Technician',
  'Agricultural Worker',
  'Air Conditioning Installer',
  'Air Conditioning Mechanic',
  'Air Traffic Controller',
  'Aircraft Cargo Handling Supervisor',
  'Aircraft Mechanic',
  'Aircraft Service Technician',
  'Airline Copilot',
  'Airline Pilot',
  'Ambulance Dispatcher',
  'Ambulance Driver',
  'Amusement Machine Servicer',
  'Anesthesiologist',
  'Animal Breeder',
  'Animal Control Worker',
  'Animal Scientist',
  'Animal Trainer',
  'Animator',
  'Answering Service Operator',
  'Anthropologist',
  'Apparel Patternmaker',
  'Apparel Worker',
  'Arbitrator',
  'Archeologist',
  'Architect',
  'Architectural Drafter',
  'Architectural Manager',
  'Archivist',
  'Art Director',
  'Art Teacher',
  'Artist',
  'Assembler',
  'Astronomer',
  'Athlete',
  'Athletic Trainer',
  'ATM Machine Repairer',
  'Atmospheric Scientist',
  'Attendant',
  'Audio And Video Equipment Technician',
  'Audio-Visual And Multimedia Collections Specialist',
  'Audiologist',
  'Auditor',
  'Author',
  'Auto Damage Insurance Appraiser',
  'Automotive And Watercraft Service Attendant',
  'Automotive Glass Installer',
  'Automotive Mechanic',
  'Avionics Technician',
  'Back-End Developer',
  'Baggage Porter',
  'Bailiff',
  'Baker',
  'Barback',
  'Barber',
  'Bartender',
  'Basic Education Teacher',
  'Behavioral Disorder Counselor',
  'Bellhop',
  'Bench Carpenter',
  'Bicycle Repairer',
  'Bill And Account Collector',
  'Billing And Posting Clerk',
  'Biochemist',
  'Biological Technician',
  'Biomedical Engineer',
  'Biophysicist',
  'Blaster',
  'Blending Machine Operator',
  'Blockmason',
  'Boiler Operator',
  'Boilermaker',
  'Bookkeeper',
  'Boring Machine Tool Tender',
  'Brazer',
  'Brickmason',
  'Bridge And Lock Tender',
  'Broadcast News Analyst',
  'Broadcast Technician',
  'Brokerage Clerk',
  'Budget Analyst',
  'Building Inspector',
  'Bus Mechanic',
  'Butcher',
  'Buyer',
  'Cabinetmaker',
  'Cafeteria Attendant',
  'Cafeteria Cook',
  'Camera Operator',
  'Camera Repairer',
  'Cardiovascular Technician',
  'Cargo Agent',
  'Carpenter',
  'Carpet Installer',
  'Cartographer',
  'Cashier',
  'Caster',
  'Ceiling Tile Installer',
  'Cellular Equipment Installer',
  'Cement Mason',
  'Channeling Machine Operator',
  'Chauffeur',
  'Checker',
  'Chef',
  'Chemical Engineer',
  'Chemical Plant Operator',
  'Chemist',
  'Chemistry Teacher',
  'Chief Executive',
  'Child Social Worker',
  'Childcare Worker',
  'Chiropractor',
  'Choreographer',
  'Civil Drafter',
  'Civil Engineer',
  'Civil Engineering Technician',
  'Claims Adjuster',
  'Claims Examiner',
  'Claims Investigator',
  'Cleaner',
  'Clinical Laboratory Technician',
  'Clinical Laboratory Technologist',
  'Clinical Psychologist',
  'Coating Worker',
  'Coatroom Attendant',
  'Coil Finisher',
  'Coil Taper',
  'Coil Winder',
  'Coin Machine Servicer',
  'Commercial Diver',
  'Commercial Pilot',
  'Commodities Sales Agent',
  'Communications Equipment Operator',
  'Communications Teacher',
  'Community Association Manager',
  'Community Service Manager',
  'Compensation And Benefits Manager',
  'Compliance Officer',
  'Composer',
  'Computer Hardware Engineer',
  'Computer Network Architect',
  'Computer Operator',
  'Computer Programmer',
  'Computer Science Teacher',
  'Computer Support Specialist',
  'Computer Systems Administrator',
  'Computer Systems Analyst',
  'Concierge',
  'Conciliator',
  'Concrete Finisher',
  'Conservation Science Teacher',
  'Conservation Scientist',
  'Conservation Worker',
  'Conservator',
  'Construction Inspector',
  'Construction Manager',
  'Construction Painter',
  'Construction Worker',
  'Continuous Mining Machine Operator',
  'Convention Planner',
  'Conveyor Operator',
  'Cook',
  'Cooling Equipment Operator',
  'Copy Marker',
  'Correctional Officer',
  'Correctional Treatment Specialist',
  'Correspondence Clerk',
  'Correspondent',
  'Cosmetologist',
  'Cost Estimator',
  'Costume Attendant',
  'Counseling Psychologist',
  'Counselor',
  'Courier',
  'Court Reporter',
  'Craft Artist',
  'Crane Operator',
  'Credit Analyst',
  'Credit Checker',
  'Credit Counselor',
  'Criminal Investigator',
  'Criminal Justice Teacher',
  'Crossing Guard',
  'Curator',
  'Custom Sewer',
  'Customer Service Representative',
  'Cutter',
  'Cutting Machine Operator',
  'Dancer',
  'Data Entry Keyer',
  'Database Administrator',
  'Decorating Worker',
  'Delivery Services Driver',
  'Demonstrator',
  'Dental Assistant',
  'Dental Hygienist',
  'Dental Laboratory Technician',
  'Dentist',
  'Dermatologist',
  'Derrick Operator',
  'Designer',
  'Desktop Publisher',
  'Detective',
  'Developer',
  'Diagnostic Medical Sonographer',
  'Die Maker',
  'Diesel Engine Specialist',
  'Dietetic Technician',
  'Dietitian',
  'Dinkey Operator',
  'Director',
  'Dishwasher',
  'Dispatcher',
  'DJ',
  'Doctor',
  'Door-To-Door Sales Worker',
  'Drafter',
  'Dragline Operator',
  'Drama Teacher',
  'Dredge Operator',
  'Dressing Room Attendant',
  'Dressmaker',
  'Drier Operator',
  'Drilling Machine Tool Operator',
  'Dry-Cleaning Worker',
  'Drywall Installer',
  'Dyeing Machine Operator',
  'Earth Driller',
  'Economics Teacher',
  'Economist',
  'Editor',
  'Education Administrator',
  'Electric Motor Repairer',
  'Electrical Electronics Drafter',
  'Electrical Engineer',
  'Electrical Equipment Assembler',
  'Electrical Installer',
  'Electrical Power-Line Installer',
  'Electrician',
  'Electro-Mechanical Technician',
  'Elementary School Teacher',
  'Elevator Installer',
  'Elevator Repairer',
  'Embalmer',
  'Emergency Management Director',
  'Emergency Medical Technician',
  'Engine Assembler',
  'Engineer',
  'Engineering Manager',
  'Engineering Teacher',
  'English Language Teacher',
  'Engraver',
  'Entertainment Attendant',
  'Environmental Engineer',
  'Environmental Science Teacher',
  'Environmental Scientist',
  'Epidemiologist',
  'Escort',
  'Etcher',
  'Event Planner',
  'Excavating Operator',
  'Executive Administrative Assistant',
  'Executive Secretary',
  'Exhibit Designer',
  'Expediting Clerk',
  'Explosives Worker',
  'Extraction Worker',
  'Fabric Mender',
  'Fabric Patternmaker',
  'Fabricator',
  'Faller',
  'Family Practitioner',
  'Family Social Worker',
  'Family Therapist',
  'Farm Advisor',
  'Farm Equipment Mechanic',
  'Farm Labor Contractor',
  'Farmer',
  'Farmworker',
  'Fashion Designer',
  'Fast Food Cook',
  'Fence Erector',
  'Fiberglass Fabricator',
  'Fiberglass Laminator',
  'File Clerk',
  'Filling Machine Operator',
  'Film And Video Editor',
  'Financial Analyst',
  'Financial Examiner',
  'Financial Manager',
  'Financial Services Sales Agent',
  'Fine Artist',
  'Fire Alarm System Installer',
  'Fire Dispatcher',
  'Fire Inspector',
  'Fire Investigator',
  'Firefighter',
  'Fish And Game Warden',
  'Fish Cutter',
  'Fish Trimmer',
  'Fisher',
  'Fitness Studies Teacher',
  'Fitness Trainer',
  'Flight Attendant',
  'Floor Finisher',
  'Floor Layer',
  'Floor Sander',
  'Floral Designer',
  'Food Batchmaker',
  'Food Cooking Machine Operator',
  'Food Preparation Worker',
  'Food Science Technician',
  'Food Scientist',
  'Food Server',
  'Food Service Manager',
  'Food Technologist',
  'Foreign Language Teacher',
  'Foreign Literature Teacher',
  'Forensic Science Technician',
  'Forest Fire Inspector',
  'Forest Fire Prevention Specialist',
  'Forest Worker',
  'Forester',
  'Forestry Teacher',
  'Forging Machine Setter',
  'Foundry Coremaker',
  'Freight Agent',
  'Freight Mover',
  'Front-End Developer',
  'Fundraising Manager',
  'Funeral Attendant',
  'Funeral Director',
  'Funeral Service Manager',
  'Furnace Operator',
  'Furnishings Worker',
  'Furniture Finisher',
  'Gaming Booth Cashier',
  'Gaming Cage Worker',
  'Gaming Change Person',
  'Gaming Dealer',
  'Gaming Investigator',
  'Gaming Manager',
  'Gaming Surveillance Officer',
  'Garment Mender',
  'Garment Presser',
  'Gas Compressor',
  'Gas Plant Operator',
  'Gas Pumping Station Operator',
  'General Manager',
  'General Practitioner',
  'Geographer',
  'Geography Teacher',
  'Geological Engineer',
  'Geological Technician',
  'Geoscientist',
  'Glazier',
  'Government Program Eligibility Interviewer',
  'Graduate Teaching Assistant',
  'Graphic Designer',
  'Groundskeeper',
  'Groundskeeping Worker',
  'Gynecologist',
  'Hairdresser',
  'Hairstylist',
  'Hand Grinding Worker',
  'Hand Laborer',
  'Hand Packager',
  'Hand Packer',
  'Hand Polishing Worker',
  'Hand Sewer',
  'Hazardous Materials Removal Worker',
  'Head Cook',
  'Health And Safety Engineer',
  'Health Educator',
  'Health Information Technician',
  'Health Services Manager',
  'Health Specialties Teacher',
  'Healthcare Social Worker',
  'Hearing Officer',
  'Heat Treating Equipment Setter',
  'Heating Installer',
  'Heating Mechanic',
  'Heavy Truck Driver',
  'Highway Maintenance Worker',
  'Historian',
  'History Teacher',
  'Hoist And Winch Operator',
  'Home Appliance Repairer',
  'Home Economics Teacher',
  'Home Entertainment Installer',
  'Home Health Aide',
  'Home Management Advisor',
  'Host',
  'Hostess',
  'Hostler',
  'Hotel Desk Clerk',
  'Housekeeping Cleaner',
  'Human Resources Assistant',
  'Human Resources Manager',
  'Human Service Assistant',
  'Hunter',
  'Hydrologist',
  'Illustrator',
  'Industrial Designer',
  'Industrial Engineer',
  'Industrial Engineering Technician',
  'Industrial Machinery Mechanic',
  'Industrial Production Manager',
  'Industrial Truck Operator',
  'Industrial-Organizational Psychologist',
  'Information Clerk',
  'Information Research Scientist',
  'Information Security Analyst',
  'Information Systems Manager',
  'Inspector',
  'Instructional Coordinator',
  'Instructor',
  'Insulation Worker',
  'Insurance Claims Clerk',
  'Insurance Sales Agent',
  'Insurance Underwriter',
  'Intercity Bus Driver',
  'Interior Designer',
  'Internist',
  'Interpreter',
  'Interviewer',
  'Investigator',
  'Jailer',
  'Janitor',
  'Jeweler',
  'Judge',
  'Judicial Law Clerk',
  'Kettle Operator',
  'Kiln Operator',
  'Kindergarten Teacher',
  'Laboratory Animal Caretaker',
  'Landscape Architect',
  'Landscaping Worker',
  'Lathe Setter',
  'Laundry Worker',
  'Law Enforcement Teacher',
  'Law Teacher',
  'Lawyer',
  'Layout Worker',
  'Leather Worker',
  'Legal Assistant',
  'Legal Secretary',
  'Legislator',
  'Librarian',
  'Library Assistant',
  'Library Science Teacher',
  'Library Technician',
  'Licensed Practical Nurse',
  'Licensed Vocational Nurse',
  'Life Scientist',
  'Lifeguard',
  'Light Truck Driver',
  'Line Installer',
  'Literacy Teacher',
  'Literature Teacher',
  'Loading Machine Operator',
  'Loan Clerk',
  'Loan Interviewer',
  'Loan Officer',
  'Lobby Attendant',
  'Locker Room Attendant',
  'Locksmith',
  'Locomotive Engineer',
  'Locomotive Firer',
  'Lodging Manager',
  'Log Grader',
  'Logging Equipment Operator',
  'Logistician',
  'Machine Feeder',
  'Machinist',
  'Magistrate Judge',
  'Magistrate',
  'Maid',
  'Mail Clerk',
  'Mail Machine Operator',
  'Mail Superintendent',
  'Maintenance Painter',
  'Maintenance Worker',
  'Makeup Artist',
  'Management Analyst',
  'Manicurist',
  'Manufactured Building Installer',
  'Mapping Technician',
  'Marble Setter',
  'Marine Engineer',
  'Marine Oiler',
  'Market Research Analyst',
  'Marketing Manager',
  'Marketing Specialist',
  'Marriage Therapist',
  'Massage Therapist',
  'Material Mover',
  'Materials'
];

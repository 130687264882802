import React from 'react';
import styles from './styles.module.scss';

const SummaryStats = ({ results }) => {
  const totalQuestions = results.length;
  const correctCount = results.filter((result) => result.isCorrect === true).length;
  const incorrectCount = results.filter((result) => result.isCorrect === false).length;
  const needReviewCount = results.filter((result) => result.isCorrect === undefined).length;

  const formatDate = () => {
    const date = new Date();
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className={styles.summaryStatsContainer}>
      <div className={styles.dateText}>Finished on {formatDate()}</div>

      <div className={styles.statItem}>
        <div className={styles.iconContainer}>Q</div>
        <span>{totalQuestions} Questions</span>
      </div>

      <div className={styles.statItem}>
        <div className={styles.correctIndicator} />
        <span>Correct {correctCount}</span>
      </div>

      <div className={styles.statItem}>
        <div className={styles.incorrectIndicator} />
        <span>Incorrect {incorrectCount}</span>
      </div>

      <div className={styles.statItem}>
        <div className={styles.needReviewIndicator} />
        <span>Need review {needReviewCount}</span>
      </div>
    </div>
  );
};

export default SummaryStats;

import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styles from './Lesson.module.scss'
import { FaPencil } from "react-icons/fa6";
import { FaPlus, FaTrash } from "react-icons/fa";
import UploadModal from "components/molecules/UploadModal/UploadModal";
import { formatContentItem } from "helpers/course";
import ContentItem from "../ContentItem/ContentItem";
import { startMainLectureUploading, startUploading } from "./uploader";
import LessonInputUpload from "./LessonInputUpload";
import MainContentViewer from "./MainContentViewer/MainContentViewer";

const Lesson = ({ lesson, setLessons, courseMaterial, setCourseMaterial, courseId, deleteLessons, handleContentDelete }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [openUploadModal, setUploadModal] = useState(false);
    const [editable, setEditable] = useState(false)
    const { title, uuid } = lesson || {};
    const handleCloseUploadModal = () => {
        setUploadModal(false)
    }

    const handleOpenUploadModal = () => {
        setUploadModal(true)
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const editLessonName = (e) => {
        e.stopPropagation()
        setEditable(true);
    };

    const handleTitleChange = (e) => {
        const title = e.target.value
        setLessons((prev) => prev.map(_lesson => _lesson?.uuid === uuid ? {
            ..._lesson,
            title
        } : _lesson))
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            setEditable(false);
        }
    };

    const handleAddContent = (files) => {
        const formattedContent = files.map(file => {
            return {
                ...formatContentItem(file),
                lessonId: uuid
            }
        })
        setCourseMaterial(prev => [...prev, ...formattedContent])
        setUploadModal(false)
        startUploading(formattedContent, setCourseMaterial, courseId, uuid)
    }

    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];

    const thisCourseMaterial = courseMaterial.filter(item => item.lessonId === uuid);
    const totalVideoCount = thisCourseMaterial.filter(item => videoExtensions.includes(item?.fileDetails?.extension))?.length;

    const handleMainLessonUpload = (file) => {
        const formattedContentFile = formatContentItem(file)
        startMainLectureUploading(formattedContentFile, setLessons, courseId, uuid)
    }

    return (
        <div className={styles.container} >
            <div className={styles.top} aria-hidden="true" onClick={toggleDropdown}>
                <div className={styles.title}>
                    <div className={styles.collapse_icon}>
                        {
                            isOpen ? <IoIosArrowUp size={18} /> : <IoIosArrowDown size={18} />
                        }
                    </div>
                    {
                        editable ?
                            <input
                                type="text"
                                value={title}
                                onChange={handleTitleChange}
                                onClick={(e) => e.stopPropagation()}
                                onBlur={() => setEditable(false)}
                                onKeyUp={handleKeyUp}
                                autoFocus
                            /> :
                            <div className={styles.section_name} onClick={(e) => e.stopPropagation()} onDoubleClick={editLessonName}>
                                <div className={styles.section_name_div}>
                                    {title === "" ? "New Lesson" : title}
                                </div>{" "}
                                <div className={styles.icon}>
                                    <FaPencil onClick={editLessonName} />
                                </div>
                            </div>
                    }
                </div>
                <div className={styles.right}>
                    <p>{totalVideoCount} Videos</p>
                    <FaTrash onClick={(e) => {
                        e.stopPropagation()
                        deleteLessons([lesson])
                    }} />
                </div>
            </div>
            {isOpen && (
                <div className={styles.content}>
                    {
                        !(lesson?.content) ? (
                            <LessonInputUpload handleMainLessonUpload={handleMainLessonUpload} />
                        ) : <MainContentViewer content={lesson?.content} />
                    }

                    <div className={styles.data}>
                        {thisCourseMaterial.map((item) => {
                            return (
                                <ContentItem
                                    key={item.id}
                                    item={item}
                                    setCourseMaterial={setCourseMaterial}
                                    handleContentDelete={handleContentDelete} />
                            )
                        })}
                        <button
                            onClick={handleOpenUploadModal}
                            className={styles.add_content}
                        >
                            <FaPlus />
                            Add Attachments
                        </button>

                    </div>
                </div>
            )}
            <UploadModal isOpen={openUploadModal} onClose={handleCloseUploadModal} handleUploadDone={handleAddContent} />
        </div>
    )
}

export default Lesson;

import React from 'react';
import { Button } from 'components/ui';
import styles from './styles.module.scss';
import { answerTypes } from 'store/quiz.slice';

const QuizAnswerType = ({ selectedType, onSelectType }) => {

  const { 
    singleChoice,
    multiChoice,
    textAns,
    pictureChoice
  } = answerTypes

  return (
    <div className={styles.types}>
      <h2>Select Answer type</h2>
      <div className={styles.btns}>
        <Button
          color={'white'}
          bgColor={selectedType === singleChoice ? '#046799' : '#292929'}
          onClick={() => onSelectType(singleChoice)}
        >
          Single choice
        </Button>
        <Button
          color={'white'}
          bgColor={selectedType === multiChoice ? '#046799' : '#292929'}
          onClick={() => onSelectType(multiChoice)}
        >
          Multi choice
        </Button>
        <Button
          color={'white'}
          bgColor={selectedType === textAns ? '#046799' : '#292929'}
          onClick={() => onSelectType(textAns)}
        >
          Text
        </Button>
        <Button
          color={'white'}
          bgColor={selectedType === pictureChoice ? '#046799' : '#292929'}
          onClick={() => onSelectType(pictureChoice)}
        >
          Picture choice
        </Button>
      </div>
    </div>
  );
};

export default QuizAnswerType;

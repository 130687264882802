import React from 'react';
import { Quizzes, Notes, QA, Resources, Participants } from './components/PagesView';

export const tabNames = {
  notes: 'Notes',
  qa: 'Q&A',
  quizzes: 'Quizzes',
  resources: 'Resources',
  participants: 'Participants'
};

const links = [
  {
    value: tabNames.quizzes,
    path: '/course-preview/quizzes',
    page: <Quizzes />
  },
  {
    value: tabNames.notes,
    path: '/course-preview/notes',
    page: <Notes />
  },
  {
    value: tabNames.resources,
    path: '/course-preview/resources',
    page: <Resources />
  },
  {
    value: tabNames.qa,
    path: '/course-preview/qa',
    page: <QA />
  },
  {
    value: tabNames.participants,
    path: '/course-preview/participants',
    page: <Participants />
  },
];

export default links;

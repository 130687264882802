import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import React from 'react'
import popupList from './Popups/popupList'
import { togglePopup } from 'store/popups/popupSlice'

function PopupManager() {
  const { pointer, data } = useSelector((state) => state.popups)
  const dispatch = useDispatch()

  const { noVoidExit } = data || {}

  const closePopup = () => {
      dispatch(
        togglePopup({
          pointer: 0,
          data: null
        })
      )
  }

  if (pointer > 0) {
    return (
      <div className={styles.popup_overlay} onClick={!noVoidExit ? closePopup : null}>
        <div className={styles.popup_child_container} onClick={(e) => e.stopPropagation()}>
          {popupList({
            pointer,
            data,
            close: closePopup
          })}
        </div>
      </div>
    )
  }
}
export default PopupManager

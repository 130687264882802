import styles from './SkillsInput.module.scss';

const SkillsInput = ({
    skillInput,
    showSuggestions,
    filteredSuggestions,
    suggestionRef,
    onInputChange,
    onKeyPress,
    onAddSkill
}) => (
    <div className={styles.skills}>
        <label>Skills you'll gain:</label>
        <div className={styles.skillInput}>
            <input
                type="text"
                value={skillInput}
                onChange={onInputChange}
                onKeyPress={onKeyPress}
                placeholder="Example: Javascript"
            />
            {showSuggestions && (
                <ul ref={suggestionRef} className={styles.suggestionList}>
                    {filteredSuggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => onAddSkill(suggestion)}
                            className={styles.suggestionItem}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
            <button onClick={() => onAddSkill(skillInput)}>+ Add Skill</button>
        </div>
    </div>
);

export default SkillsInput;

import React from 'react';
import CourseCard from 'components/atoms/CourseCard/CourseCard';
import { coursesData } from 'constants/courses';
import styles from '../../../styles.module.scss';

export default function Quizzes() {
  const filterContent = coursesData.map(({ url, by, lessons, ...rest }) => rest).slice(0, 4);

  return (
    <>
      <div className={styles.cardlist}>
        {filterContent.map((course, index) => (
          <div key={index}>
            <CourseCard actions={true} {...course} extraClass="singleCourseCard" />
          </div>
        ))}
      </div>
    </>
  );
}

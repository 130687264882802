import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from './styles.module.scss';
import Timer from "../Timer";
import Question from "../Question";
import HintComponent from "../Hint";
import ProgressBar from "../ProgressBar";
import { previewStages, setPreviewStage, setQuizAnswers, setTimeSpent } from "store/quiz.slice";

const QuizTake = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([]);
    const { quizData, duration, title, description } = useSelector(state => state.quiz);
    const [timeLeft, setTimeLeft] = useState(duration);
    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (timeLeft === 0 || answers.length === quizData.length) {
            endQuiz();
        }
    }, [timeLeft, answers, quizData]);

    
    const endQuiz = () => {
        const filledAnswers = [...answers];
        
        while (filledAnswers.length < quizData.length) {
            filledAnswers.push(null);
        }
        
        dispatch(setQuizAnswers(filledAnswers));
        dispatch(setPreviewStage(previewStages.complete));
        dispatch(setTimeSpent(duration - timeLeft))
    };

    const handleAnswer = (answer) => {
        setAnswers((prevAnswers) => [...prevAnswers, answer]);
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < quizData.length) {
            setCurrentQuestion(nextQuestion);
        }
    };

    return (
        <div className={styles.quizContainer}>
            <div>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.description}>{description}</p>
                <Timer timeLeft={timeLeft} />
            </div>
            <Question data={quizData[currentQuestion]} qnNo={currentQuestion + 1} onAnswer={handleAnswer} />
            <div className={styles.hintWrap}>
                <HintComponent hint={quizData[currentQuestion]?.hint} />
            </div>
            <div className={styles.spacer} />
            <ProgressBar current={currentQuestion + 1} total={quizData.length} />
        </div>
    );
};

export default QuizTake;

import React from 'react';
import Button from '../Button/Button';
import styles from './LoadMoreButton.module.scss';
import Arrow from 'assets/icons/arrowright.svg?react';
import { useTranslation } from 'react-i18next';
function LoadMoreButton({ hidden, onClick, disabled, ...rest }) {
  const { t } = useTranslation();
  if (hidden) return null;
  return (
    <div className={`${styles.addmore} ${rest.className}`}>
      <Button {...rest} bgColor="transparent" color="#bfbfbf" onClick={onClick} disabled={disabled}>
        {t('Load More')} <Arrow />
      </Button>
    </div>
  );
}

export default LoadMoreButton;

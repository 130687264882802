import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import Course from 'components/organisms/Course/Course';

export default function StartCourse() {
  document.body.style.overflow = '';

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/course/forms" />} />
      <Route path="/:tab" element={<Course />} />
    </Routes>
  );
}

import HiddenFileInput from 'components/molecules/HiddenFileInput/HiddenFileInput';
import { Button, Loader } from 'components/ui';
import React from 'react';
import styles from './FileUploader.module.scss';
import UploadI from 'assets/icons/upload.svg?react';
const FileUploader = ({
  accept = 'image/*',
  multiple = false,
  onChange,
  isLoading = false,
  onRest = null,
  text = ''
}) => {
  const textHighlight = text ? text : `Upload...`;
  const ref = React.useRef(null);
  const [uploaderLabel, setUploaderLabel] = React.useState('');
  return (
    <div className={styles['wrapper']}>
      <HiddenFileInput
        id={`upload`}
        accept={accept}
        multiple={multiple}
        onChange={(e) => {
          const files = e.target.files;
          if (files.length > 1) setUploaderLabel(`${files.length} files selected`);
          else setUploaderLabel(files[0].name);
          onChange(files);
          ref.current.value = '';
        }}
        type="file"
        ref={ref}
      />
      <label className={styles['video-selector']} htmlFor="upload">
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <UploadI height={50} width={50} />
            <p>{uploaderLabel || textHighlight}</p>
            {onRest && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRest();
                }}
              >
                reset
              </Button>
            )}
          </>
        )}
      </label>
    </div>
  );
};

export default FileUploader;

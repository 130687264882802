import React, { useEffect, useState } from 'react';
import styles from './ItEssentialTab.module.scss';
import { objectsEqual_ByKeys, onFieldChangeProfileModules, trimObject } from 'helpers';
import { EditInput } from 'components/form';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { Loader } from 'components/ui';
import { useUpdateItEssentialMutation } from 'api/individual-profile';
import { handleAsync } from 'helpers';
import Options from 'components/atoms/Options/Options';
import { useTranslation } from 'react-i18next';

export default function ItEssentialTab({ userId, profileId, list = [], heading, ...rest }) {
  const { isLoading, isError, data, error, isSuccess } = useGetIndividualProfileByProfileIdQuery({
    profileId
  });
  const [updateItEssential, { isLoading: isUpdateLoading }] = useUpdateItEssentialMutation();
  const [fieldsChange, setFieldsChange] = useState({});
  const onFieldsChange = (name, value) => {
    onFieldChangeProfileModules(name, value, setFieldsChange, fieldsChange);
  };
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const placeholder = 'Not Yet Provided';

  const constructITEssentialList = (itEssentialObj) => {
    const itEssentialList = [];
    itEssentialList.push(
      ...[
        {
          id: 1,
          key: 'phoneDetails',
          value: itEssentialObj?.phoneDetails || placeholder,
          name: 'phone details'
        },
        {
          id: 2,
          key: 'internetDetails',
          value: itEssentialObj?.internetDetails || placeholder,
          name: 'internet details'
        },
        {
          id: 3,
          key: 'pcDetails',
          value: itEssentialObj?.pcDetails || placeholder,
          name: 'pc details'
        },
        {
          id: 4,
          key: 'typicalUse',
          value: itEssentialObj?.typicalUse || placeholder,
          name: 'typical use'
        },
        {
          id: 5,
          key: 'monitors',
          value: itEssentialObj?.monitors || placeholder,
          name: 'monitors'
        },
        {
          id: 6,
          key: 'itCourseParticipation',
          value: itEssentialObj?.itCourseParticipation || placeholder,
          name: 'it course participation'
        }
      ]
    );
    return itEssentialList;
  };

  useEffect(() => {
    if (data?.profile?.itEssential) {
      const {
        profile: { itEssential }
      } = data;
      const items = constructITEssentialList(itEssential);
      const newFieldsChange = {};
      items.forEach((item) => {
        newFieldsChange[item.key] = item.value;
      });
      setFieldsChange(newFieldsChange);
    }
  }, [data]);
  let content = null;
  if (isLoading) {
    content = <Loader section={true} />;
  }
  if (isError) {
    content = <p>{error.message}</p>;
  }
  if (isUpdateLoading) content = <Loader section={true} />;
  if (isSuccess) {
    const {
      profile: { itEssential }
    } = data;
    const items = constructITEssentialList(itEssential);
    content = (
      <div className={styles['fade-in']}>
        <div className={styles.availabilityInputs}>
          {rest.isCurrentUserProfile && (
            <Options
              extraClass="profileOptions"
              handleEdit={() => setEdit(true)}
              edit={edit}
              handleDiscard={() => setEdit(false)}
              handleSave={async () => {
                let dto = trimObject(fieldsChange);
                const objectsSame = objectsEqual_ByKeys(dto, itEssential);
                !objectsSame &&
                  handleAsync(
                    updateItEssential({
                      profileId: data.profile.id,
                      itEssential: dto
                    }).unwrap()
                  );
                setEdit(false);
              }}
            />
          )}
          <p className={styles.heading}>{heading}</p>
          <>
            <div>
              {items.map((item) => {
                const isPlaceholder = fieldsChange[item.key] === placeholder;
                return (
                  <div className={styles.content} key={item.id}>
                    <h3 className={styles.title}>{t(item.name)}</h3>
                    {!edit ? (
                      <p style={{ color: isPlaceholder ? '#069677' : '' }} className={styles.desc}>
                        {item.value}
                      </p>
                    ) : (
                      <>
                        <p className={`${styles.desc} ${styles.editField}`}>
                          <EditInput
                            maxLength={500}
                            onChange={({ target }) => onFieldsChange(item.key, target.value)}
                            value={fieldsChange[item.key]}
                          />
                        </p>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        </div>
      </div>
    );
  }

  return content;
}

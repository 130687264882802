import React, { useState, useEffect } from 'react';
import Dropdown from '../CourseDropdown/CourseDropdown';
import styles from './CourseStructure.module.scss';
import { Button } from 'components/ui';

export default function CourseStructure({}) {
  const [sections, setSections] = useState([
    {
      title: 'Getting Started',
      lesson: '0',
      time: '0',
      contentArray: [
        {
          title: 'Document',
          duration: 3,
          videoUrl: 'https://mpublisher-images-bucket.s3.amazonaws.com/info.pdf'
        },
        {
          title: 'Video 2',
          duration: 5,
          videoUrl:
            'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1720540672/Templates/WhatsApp_Video_2024-07-09_at_17.51.56_f3jycu.mp4'
        },
        {
          title: 'Video 3',
          duration: 7,
          videoUrl:
            'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708529174/9fc423c1-0fe1-4d4c-86a6-b9a9fb1a20b1_k9c5pv.mp4'
        }
      ]
    },
    {
      title: 'Another Section',
      lesson: '0',
      time: '0',
      contentArray: [
        {
          title: 'Video 1',
          duration: 4,
          videoUrl:
            'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708529174/9fc423c1-0fe1-4d4c-86a6-b9a9fb1a20b1_k9c5pv.mp4'
        }
      ]
    },
    {
      title: 'Name Section',
      lesson: '0',
      time: '0',
      contentArray: [
        {
          title: 'Video 1',
          duration: 6,
          videoUrl:
            'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1708529174/9fc423c1-0fe1-4d4c-86a6-b9a9fb1a20b1_k9c5pv.mp4'
        }
      ]
    }
  ]);

  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        lesson: section.contentArray.length.toString(),
        time: section.contentArray.reduce((acc, item) => acc + item.duration, 0).toString()
      }))
    );
  }, []);

  const toggleExpandAll = () => {
    setExpandAll((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div>
          <h2>Course structure</h2>
          <p>
            {sections.length} Sections |{' '}
            {sections.reduce(
              (totalLessons, section) => totalLessons + section.contentArray.length,
              0
            )}{' '}
            Lessons
          </p>
        </div>
        <button className={styles.expand} onClick={toggleExpandAll}>
          {expandAll ? 'Collapse all sections' : 'Expand all sections'}
        </button>
      </div>
      <div className={styles.data}>
        {sections.map((section, index) => (
          <Dropdown
            key={index}
            title={section?.title}
            lesson={section?.lesson}
            time={section?.time}
            contentArray={section?.contentArray}
            isOpen={expandAll}
          />
        ))}
      </div>
      <div className={styles.startBtn}>
        <Button bgColor="linear-gradient(45deg, #30a5e0, #00a480)">Start Course</Button>
      </div>
    </div>
  );
}

/* eslint-disable no-unused-vars */
import React from 'react'
import styles from './styles.module.scss'

const index = ({ data, close }) => {
  const { desc, validButtonText, validAction } = data

  const handleClick = () => {
    validAction && validAction()
    close()
  }
  return (
    <div className={styles.popup_wrapper}>
      <div className={styles.container}>
        <p className={styles.desc_text}>{desc}</p>
        <button className={`${styles.button} ${styles.valid}`} onClick={handleClick}>{validButtonText}</button>
      </div>
    </div>
  )
}
export default index
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileStatusSelector, getCurrentUserIdSelector } from 'store/login.slice';
import Navbar from '~organisms/Navbar/Navbar';
import './Layout.scss';
import { Outlet } from 'react-router-dom';
import UniversalMenuComponent from 'components/atoms/UniversalMenuComponent/UniversalMenuComponent';
import MWMenu from 'components/atoms/UniversalMenuComponent/MwMenu';
import useScrollToTop from 'hooks/useScrollToTop';
import { useUpdateCurrentUserProfile } from './hooks';
import Fullscreen from 'assets/icons/fullscreen.svg?react';
import ExitFullscreen from 'assets/icons/exitfullscreen.svg?react';
import { useTour } from '@reactour/tour';
import ProgressBar from 'components/molecules/ProgressBar/ProgressBar';
import loader from 'assets/images/stepgif.gif';
import { useTranslation } from 'react-i18next';
import { toggleMenu } from 'store/menu.slice';
import PopupManager from 'components/organisms/PopupManager';
const Layout = () => {
  useScrollToTop();
  // useUpdateCurrentUserProfile();
  const dispatch = useDispatch();
  const profileData = useSelector(getProfileStatusSelector);
  const currentUserId = useSelector(getCurrentUserIdSelector);
  const {
    i18n: { changeLanguage }
  } = useTranslation();
  const { showMenu, expandState } = useSelector((state) => state.menu);
  const isTouchScreen = () => {
    return 'ontouchstart' in document.documentElement;
  };
  // const [state, setState] = useState({});
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { t } = useTranslation();

  const showUniversalMenu = () => {
    dispatch(toggleMenu());
  };
  const { currentStep, setCurrentStep, steps, setIsOpen } = useTour();
  const [isLoading, setIsLoading] = useState(false);

  const checkElementInDOM = useCallback(async () => {
    const foundStep = steps[currentStep + 1];
    if (!foundStep) return;

    const elementInDOM = document.querySelector(foundStep?.selector);
    if (elementInDOM) {
      setIsOpen(true);
      setIsLoading(false);
      setCurrentStep(currentStep + 1);
    } else {
      setIsOpen(false);
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      checkElementInDOM();
    }
  }, [currentStep, setCurrentStep, setIsOpen, steps]);
  useEffect(() => {
    const handlePathnameChange = async () => {
      if (location.pathname.includes(steps[currentStep + 1]?.pathname)) {
        // Check if the element associated with the next step is in the DOM
        checkElementInDOM();
      }
    };
    if (!isLoading) {
      handlePathnameChange();
    }
    const popstateListener = () => {
      handlePathnameChange();
    };
    window.addEventListener('popstate', popstateListener);

    return () => {
      window.removeEventListener('popstate', popstateListener);
    };
  }, [location.pathname, currentStep, steps, checkElementInDOM, isLoading]);

  useEffect(() => {
    changeLanguage(profileData?.locale?.toLowerCase() || 'en');
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [profileData]);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const easyCloseMenu = () => {
    if (window.innerWidth < 500 && isTouchScreen() && !showMenu) {
      dispatch(toggleMenu());
    }
  };

  return (
    <div className={'generalpagewrapper'}>
      {isLoading && (
        <div className="loading__popover">
          <ProgressBar currentStep={currentStep} totalSteps={steps.length} />
          <div>
            {t('preparing-the-next-step')}...
            <img alt="stepgif" src={loader} />
          </div>
        </div>
      )}
      <Navbar showMenu={showMenu} profileData={profileData} />
      {profileData && currentUserId && (
        <>
          {showMenu && (
            <div className={'universalmenuicon'} onClick={showUniversalMenu}>
              <MWMenu />
            </div>
          )}
          <UniversalMenuComponent
            showMenu={showMenu}
            handleShowMenu={showUniversalMenu}
            currentUserId={currentUserId}
            currentUserProfile={profileData}
          />
        </>
      )}
      <div className={`layoutBody ${!showMenu && expandState}`} onClick={easyCloseMenu}>
        <Outlet />
        <button className="fullscreenBtn" onClick={toggleFullscreen} name="fullscreen">
          {isFullscreen ? (
            <>
              <ExitFullscreen />
              <div className="fullscreenTooltip">
                <h3 className="tooltip">{t('exit-full-screen')}</h3>
              </div>
            </>
          ) : (
            <>
              <Fullscreen />
              <div className="fullscreenTooltip">
                <h3 className="tooltip">{t('go-full-screen')}</h3>
              </div>
            </>
          )}
        </button>
      </div>
      <PopupManager />
    </div>
  );
};

export default Layout;

import React, { useEffect, useState } from 'react';
import styles from './Achievements.module.scss';
import img from 'assets/images/star.webp';
import Card from './Card';
import {
  useGetAchievementsByUserIdQuery,
  useUpdateAchievementMutation,
  useSaveAchievementMutation,
  useDeleteAchievementMutation
} from 'api/individual-profile';
import { isSharedMode, removeObjectElements } from 'helpers';
import { handleAsync } from 'helpers';
import Arrow from 'assets/icons/arrowright.svg?react';
import CreateAchievementModal from './CreateAchievementModal';
import { useDispatch } from 'react-redux';
import { Loader } from 'components/ui';
import useSelectCacheData from 'hooks/useSelectCacheData';
import { useHandleRtkPagination } from 'hooks';
import { Button } from 'components/ui';
import Options from 'components/atoms/Options/Options';
import { useTranslation } from 'react-i18next';

export default function Achievements({ userId, isCurrentUserProfile }) {
  const sharedData = useSelectCacheData('getSharedProfileBySlug', {});
  const { t } = useTranslation();

  const [updateAchievement] = useUpdateAchievementMutation();
  const [saveAchievement, { isLoading: isSaving }] = useSaveAchievementMutation();
  const [deleteAchievement] = useDeleteAchievementMutation();

  const [create, setCreate] = useState(false);
  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getAchievementsByUserId',
    rtkQuery: useGetAchievementsByUserIdQuery,
    rtkQueryArgs: { userId },
    rtkQueryOptions: {
      skip: isSharedMode()
    }
  });
  const { data, isFetching, isLoading, isError, error, isSuccess } = !isSharedMode()
    ? result
    : sharedData;
  const onCreateSubmit = async (achievement) => {
    const status = await handleAsync(
      saveAchievement({
        userId,
        achievement: achievement
      }).unwrap()
    );
    return status[0];
  };

  const onUpdateSubmit = async (achievement) => {
    const { id, ...rest } = achievement;
    const achievementDto = removeObjectElements(rest, ['img', 'userId']);
    await handleAsync(
      updateAchievement({
        userId,
        achievementId: id,
        achievement: achievementDto
      }).unwrap()
    );
  };

  const onDeleteSubmit = async (achievementId) => {
    const status = await handleAsync(
      deleteAchievement({
        userId,
        achievementId
      }).unwrap()
    );
    return status[0];
  };

  let content = null;
  if (isLoading) <Loader />;
  if (isError) content = <p style={{ color: 'red' }}>Error: {error.data.message}</p>;
  if (isSuccess) {
    const achievements = isSharedMode() ? data?.bio?.achievements : data?.result;
    content = (
      <>
        <div className={styles.achievementsContainer}>
          {achievements?.map((achievement) => {
            return (
              <Card
                img={img}
                key={achievement.id}
                width={52}
                height={52}
                name={achievement.name}
                institution={achievement.institution}
                description={achievement.description}
                location={achievement.location}
                achievement={achievement.achievement}
                from={achievement.from}
                to={achievement.to}
                id={achievement.id}
                isCurrentUserProfile={isCurrentUserProfile}
                onUpdateSubmit={onUpdateSubmit}
                onDeleteSubmit={onDeleteSubmit}
              />
            );
          })}
          {isFetching && <Loader />}
        </div>
      </>
    );
  }
  return (
    <div className={styles.achievements}>
      {isCurrentUserProfile && (
        <Options extraClass="roleOptions" handleAdd={() => setCreate(true)} />
      )}
      <div className={styles.desc}>
        <h3 className={styles.title} id="achievements-title">
          {t('Achievements')}
        </h3>
      </div>
      {content}
      <div className={!data?.page?.hasNextPage ? `${styles.removebtn}` : `${styles.addmore}`}>
        <Button
          onClick={() => setPage((pre) => pre + 1)}
          color="#bfbfbf"
          disabled={isFetching || !data?.page?.hasNextPage}
        >
          {t('Load More')} <Arrow />
        </Button>
      </div>
      <CreateAchievementModal
        isSaving={isSaving}
        open={create}
        onClose={() => setCreate(false)}
        userId={userId}
        onCreateSubmit={onCreateSubmit}
      />
    </div>
  );
}

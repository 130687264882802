import { useState } from "react";
import styles from './styles.module.scss';
import CustomRadioButton from "components/atoms/CustomRadioButton/CustomRadioButton";
import { answerTypes } from "store/quiz.slice";

const Question = ({ data, onAnswer, qnNo }) => {
  const { question, options, type } = data;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [singleOption, setSingleOption] = useState(null);
  const [textAnswer, setTextAnswer] = useState("");

  const {
    singleChoice,
    multiChoice,
    textAns,
    pictureChoice
  } = answerTypes

  const handleRadioChange = (optionText) => {
    if (selectedOptions.includes(optionText)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== optionText));
    } else {
      setSelectedOptions([...selectedOptions, optionText]);
    }
  };

  const handleOptionClick = (option) => {
    setSingleOption(option);
  };

  const handleSubmit = () => {
    if (type === singleChoice || type === pictureChoice) {
      onAnswer(singleOption);
      setSingleOption(null);
    } else if (type === multiChoice) {
      onAnswer(selectedOptions);
      setSelectedOptions([]);
    } else if (type === textAns) {
      onAnswer(textAnswer);
      setTextAnswer('');
    }
  };

  return (
    <div className={styles.questionContainer}>
      <div className={styles.questionNumber}>{qnNo}.</div>
      <div className={styles.questionContent}>
        <div className={styles.questionIcon}>Q</div>
        {question}
      </div>

      {type === textAns && (
        <textarea
          placeholder="Write your description"
          className={styles.textarea}
          rows={10}
          value={textAnswer}
          onChange={(e) => setTextAnswer(e.target.value)}
        />
      )}

      {type === singleChoice &&
        options.map((option, index) => (
          <button
            key={index}
            className={`${styles.option} ${singleOption === option ? styles.optionSelected : styles.optionUnselected}`}
            onClick={() => handleOptionClick(option)}
          >
            {option.text}
          </button>
        ))}

      {type === multiChoice &&
        options.map((option, index) => (
          <div key={index} className={styles.checkContainer} onClick={() => handleRadioChange(option)}>
            <CustomRadioButton checked={selectedOptions.includes(option)} bgChecked="#046799" />
            <label>{option.text}</label>
          </div>
        ))}

      {type === pictureChoice && (
        <div className={styles.pictureChoiceContainer}>
          {options.map((option, index) => (
            <div
              key={index}
              className={`${styles.imageContainer} ${singleOption === option ? styles.imageSelected : ""}`}
              onClick={() => handleOptionClick(option)}
            >
              <div className={styles.imageWrapper}>
                <img src={option.image} alt="option" />
              </div>
              <div className={styles.imageText}>{option.text}</div>
            </div>
          ))}
        </div>
      )}

      <button onClick={handleSubmit} className={styles.submitButton}>
        Submit
      </button>
    </div>
  );
};

export default Question;

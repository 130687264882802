import styles from './NoPostsFound.module.scss';
import Emoji from 'assets/icons/notFoundEmoji.svg?react';
export const NoPostsFound = ({ filter }) => {
  function capitalizeFirstLetterOfEachWord(str) {
    return str
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  }
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.header}>
        <h1 className={styles.notFoundHeader}>
          {`Opps, no posts found in the ${
            filter === '' ? 'Most Recent Posts' : capitalizeFirstLetterOfEachWord(filter)
          } category`}
        </h1>
        <Emoji />
      </div>
      <p className={styles.notFoundParagraph}>Try searching for other categories</p>
    </div>
  );
};

import img1 from 'assets/images/info1.webp';
import img2 from 'assets/images/info2.webp';
import img3 from 'assets/images/info3.webp';
import img4 from 'assets/images/info4.webp';
import img5 from 'assets/images/info5.webp';
import img7 from 'assets/images/infoMain.webp';
import Logo from 'assets/icons/wizrxlogo.svg?react';
import React from 'react';
export const INFO_CONTENT = [
  {
    img: img7,
    extraClass: 'infoBanner',
    title: (
      <>
        What is <Logo />
      </>
    ),
    description:
      'WiZR X is our solution that forges seamless connections that fuel unparalleled collaborations. Through an elevated visual interface, we transcend the ordinary, propelling collaboration into an entirely new dimension.'
  },
  {
    img: img1,
    title: 'Individual Profiles',
    description:
      'The Individual Profiles feature offers a concise snapshot of team members, including roles, experiences, availability, and projects. This streamlined resource promotes efficient collaboration, aiding in resource allocation and strategic decision-making for heightened productivity and allowing you to know more about your team members'
  },
  {
    img: img2,
    title: 'Team Profiles',
    description:
      "The Team Profiles feature provides a concise overview of each team, displaying its members, availability, and ongoing projects. This streamlined resource facilitates seamless coordination and enables quick access to essential information about each team's composition and capacity"
  },
  {
    img: img3,
    title: 'Working Hours',
    description:
      "The Availability feature provides instant visibility into team members' schedules, highlighting their specific hours of availability. This streamlined tool facilitates efficient planning and task allocation, ensuring projects align seamlessly with each team member's schedule for enhanced productivity"
  },
  {
    img: img4,
    title: 'Calendars',
    description:
      'The Calendars feature provides a visual snapshot of team members schedules, simplifying coordination and task planning. It enhances productivity by enabling seamless synchronization of appointments and deadlines'
  },
  {
    img: img5,
    title: 'Social board',
    description:
      'Our Social Boards combines  Info Hub and News Hub, serving as a central information platform. The Info Hub stores crucial updates and resources, while the News Hub provides internal news and other insights from team members. This streamlines communication, keeping team members informed and engaged, bolstering collaboration and awareness'
  }
];

export const INFO_STATIC_DATA = [
  {
    id: 1,
    url: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm',
    title: 'Video 1',
    posterImg: 'https://cdn.wizrx.wizrapps.com/Rectangle_54_shf7ua.webp'
  },
  {
    id: 2,
    url: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm',
    title: 'Video 2',
    posterImg: 'https://cdn.wizrx.wizrapps.com/Rectangle_55_velhxt.webp'
  },
  {
    id: 3,
    url: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm',
    title: 'Video 3',
    posterImg: 'https://cdn.wizrx.wizrapps.com/Rectangle_56_ytf9yi.webp'
  },
  {
    id: 4,
    url: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm',
    title: 'Video 2',
    posterImg: 'https://cdn.wizrx.wizrapps.com/Rectangle_54_shf7ua.webp'
  }
];

export const INFO_STATIC_DATA_2 = [
  {
    id: 1,
    url: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm',
    title: 'Video 1',
    posterImg: 'https://cdn.wizrx.wizrapps.com/Rectangle_51_pwxk3u.webp'
  },
  {
    id: 2,
    url: 'https://cdn.wizrx.wizrapps.com/Panorama_Screen_-_SoMe_Teaser_WiZR_Connect_V2_1_q6gxqy.webm',
    title: 'Video 2',
    posterImg: 'https://cdn.wizrx.wizrapps.com/Rectangle_52_yrif23.webp'
  }
];

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createCourse } from 'api/courses/coursesApis';
import { togglePopup } from './popups/popupSlice';

const initialState = {
  courses: [],
  fetching: false,
  displayView: 'grid'
};

export const createNewCourse = createAsyncThunk(
  'draft-courses/createNewCourse',
  async ({ data, callback }, { dispatch }) => {
    const response = await createCourse(data);
    dispatch(
      togglePopup({
        pointer: 0,
        text: ''
      })
    );
    const { result } = response;
    if (!result) {
      throw new Error('Draft creation failed');
    }
    callback && callback(response);
    return result;
  }
);

const courseSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setDisplayView(state, action) {
      state.displayView = action.payload;
    }
  },
  extraReducers: (builder) => {
    // creators
    builder.addCase(createNewCourse.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(createNewCourse.fulfilled, (state, action) => {
      state.courses = [...state.courses, action.payload];
      state.fetching = false;
    });
    builder.addCase(createNewCourse.rejected, (state, action) => {
      state.error = action.error;
      state.fetching = false;
    });
  }
});

export const { setDisplayView } = courseSlice.actions;
export default courseSlice.reducer;

import { Loader } from 'components/ui';
import React from 'react';
import styles from './UserRoleTab.module.scss';
import { isSharedMode } from 'helpers';
import useSelectCacheData from 'hooks/useSelectCacheData';
import { useGetUserRoleQuery } from 'api/individual-profile';
import { Projects, Publications, RoleDescription } from './sections';
function UserRoleTab({ profileId, userId, isCurrentUserProfile }) {
  const sharedData = useSelectCacheData('getSharedProfileBySlug', {});

  const result = useGetUserRoleQuery({ profileId }, { skip: isSharedMode() });

  const { isLoading, isError, data, error, isSuccess } = !isSharedMode() ? result : sharedData;
  let content;
  if (isLoading) {
    content = <Loader />;
  } else if (isError) {
    content = (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  } else if (isSuccess) {
    content = (
      <>
        <div className={styles['fade-in']}>
          <RoleDescription
            profileId={profileId}
            isCurrentUserProfile={isCurrentUserProfile}
            role={data?.role}
          />
          <Projects userId={userId} isCurrentUserProfile={isCurrentUserProfile} />
          <Publications
            userId={userId}
            publicationDescription={data?.publicationDesc}
            isCurrentUserProfile={isCurrentUserProfile}
          />
        </div>
      </>
    );
  }
  return content;
}

export default UserRoleTab;

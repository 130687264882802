import React from 'react';
import styles from './MediaItem.module.scss';
import Play from 'assets/icons/video.svg?react';
import File from 'assets/icons/document.svg?react';
import CheckInput from '../CheckInput/CheckInput';

export default function MediaItem({
  title,
  time,
  active,
  onClick,
  type,
  isChecked,
  onCheckChange,
  sectionIndex,
  mediaItemIndex
}) {
  return (
    <div
      onClick={onClick}
      aria-hidden="true"
      className={active ? `${styles.item} ${styles.active}` : styles.item}
    >
      <CheckInput
        id={`checkbox-${sectionIndex}-${mediaItemIndex}`}
        name={`mediaItem-${title}`}
        checked={isChecked}
        onChange={() => onCheckChange(sectionIndex, mediaItemIndex)}
        extraClass="courseCheck"
      />

      <div className={styles.title}>
        <h3>{title}</h3>
        <div className={styles.bottom}>
          {type === 'video' ? <Play /> : <File />}
          <p>{time}</p>
        </div>
      </div>
    </div>
  );
}

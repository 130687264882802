/* eslint-disable no-unused-vars */
import React from 'react'
import styles from './styles.module.scss'

const index = ({ data }) => {
  const { header, desc } = data
  return (
    <div className={styles.popup_wrapper}>
      <div className={styles.loading_wrapper}>
        
        {
          header && <h2 className={styles.loading_text}>{header}</h2>
        }

        <div className={styles.desc_container}>
          <p className={styles.loading_desc}>{desc}</p>
          <div className={styles.loading_spinner} />
        </div>

      </div>
    </div>
  )
}
export default index

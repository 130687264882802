export const coursesData = [
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15274_bzggyl.png',
    title: 'Computing',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '3 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15273_hxnvbz.png',
    title: 'Engineering',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '2 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15277_ov0htd.png',
    title: 'Design',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '6 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031953/Templates/Rectangle_15275_vy0ppi.png',
    title: 'Sales Training',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '2 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15276_lv1u8s.png',
    title: 'Finance and Accounting',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '1 lesson'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084641/Templates/Rectangle_15400_1_qqpf3y.png',
    title: 'Computing',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '3 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084640/Templates/Rectangle_15399_1_ebten7.png',
    title: 'Engineering',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '5 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084642/Templates/Rectangle_15401_1_yrbzvc.png',
    title: 'Design',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '9 lessons'
  }
];

export const startCourseData = [
  {
    title: 'Getting started',
    mediaItems: [
      {
        title: 'Introduction',
        document: 'https://mpublisher-images-bucket.s3.amazonaws.com/info.pdf',
        time: '10min'
      },
      {
        title: 'What is meditation',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1667838430/videos/Short_Sales_Teaser_Short_VO_rzof5f.mp4',
        time: '17min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      },
      {
        title: 'Well being',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1667838430/videos/Short_Sales_Teaser_Short_VO_rzof5f.mp4',
        time: '30min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      },
      {
        title: 'Programs',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1667838430/videos/Short_Sales_Teaser_Short_VO_rzof5f.mp4',
        time: '17min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      }
    ]
  },
  {
    title: 'Paint Techniques',
    mediaItems: [
      {
        title: 'What is meditation',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670960421/videos/Movie_1_Final_Video_Audio_Boost_fp5x8p.mp4',
        time: '17min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      },
      {
        title: 'Well being',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1667838430/videos/Short_Sales_Teaser_Short_VO_rzof5f.mp4',
        time: '30min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      },
      {
        title: 'Programs',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1667838430/videos/Short_Sales_Teaser_Short_VO_rzof5f.mp4',
        time: '17min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      }
    ]
  },
  {
    title: 'Art of Nature',
    mediaItems: [
      {
        title: 'Well being',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1667838411/videos/Express_yourself_with_MASTER_WiZR_g6iquj.mp4',
        time: '17min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      }
    ]
  },
  {
    title: 'Brushes',
    mediaItems: [
      {
        title: 'Programs',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670960477/videos/Movie_2_Final_Audio_Update_bkznmf.mp4',
        time: '17min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      },
      {
        title: 'Programs',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1667838430/videos/Short_Sales_Teaser_Short_VO_rzof5f.mp4',
        time: '17min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      }
    ]
  },
  {
    title: 'Colors',
    mediaItems: [
      {
        title: 'Society',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670961191/videos/Movie_4_Updated_Visuals_Final_ysuppv.mp4',
        time: '17min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      }
    ]
  },
  {
    title: 'Others',
    mediaItems: [
      {
        title: 'Program',
        video:
          'https://res.cloudinary.com/dsg2ktuqk/video/upload/v1673013986/videos/mwindex2_yyfiln.mp4',
        time: '17min',
        poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
      }
    ]
  }
];

export const courseNotes = [
  {
    title: 'Note 1',
    name: 'John Doe',
    position: 'Instructor',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet mi in dui pellentesque placerat sit amet id urna.'
  },
  {
    title: 'Note 2',
    name: 'Jane Smith',
    position: 'Instructor',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet mi in dui pellentesque placerat sit amet id urna.'
  },
  {
    title: 'Note 3',
    name: 'Sam Wilson',
    position: 'Me',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet mi in dui pellentesque placerat sit amet id urna.'
  },
  {
    title: 'Note 4',
    name: 'Alice Brown',
    position: 'Instructor',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet mi in dui pellentesque placerat sit amet id urna.'
  }
];

import React from 'react';
import { useParams } from 'react-router-dom';
import styles from "./NewCourse.module.scss";
import { FaImage } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { MdOutlineDataSaverOff } from "react-icons/md";
import { IoMdCloudUpload } from "react-icons/io";
import CourseStructure from 'components/organisms/UpDashboard/components/CreateCourse/CourseStructure/CourseStructure';
import AboutCourse from '../UpDashboard/components/CreateCourse/AboutCourse/AboutCourse';
import useCourseCreator from './useCourseCreator';
import GoBackButton from './GoBackButton';
import HeaderContent from './HeaderContent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';




const NewCourse = () => {
    const params = useParams();
    const { t } = useTranslation();
    const courseId = params?.id || 'undefined';
    const { headerData, headerBanners, chapters, setChapters,
        lessons, setLessons, courseMaterial, setCourseMaterial, changeHeader, handleBannerUpload,
        handleContentImgUpload, handleCoverDrop, handleContentDelete, deleteLessons, deleteChapter, canSave, saveDraft, uploadCourse
    } = useCourseCreator({ courseId });

    const { saving } = useSelector((state) => state.coursesDraft);
    const { banner, thumbnail } = headerBanners;
    const courseState = {
        headerData,
        banner,
        thumbnail,
        chapters,
        lessons,
        courseMaterial
    }
    const courseStructuredState = {
        ...headerData,
        banner,
        thumbnail,
        chapters: [...chapters].map((chapter) => {
            return {
                ...chapter,
                lessons: [...lessons].filter((lesson) => lesson.chapterId === chapter.id).map((lesson) => {
                    return {
                        ...lesson,
                        resources: [...courseMaterial].filter((content) => content.lessonId === lesson.id),
                    }
                })
            }
        })
    }

    console.log(courseStructuredState)



    return (
        <div className={styles.wrapper}>
            <div className={styles.headerSection}>
                <div className={styles.backgroundBanner}>
                    <div className={styles.bannerOverlay} onDrop={handleCoverDrop} />
                    <img id='img-banner' src={banner ? (banner + `?cacheBust=${new Date().getTime()}`) : 'https://cdn.wizrx.wizrapps.com/Frame_18_2_xvdh6b.webp'}
                        alt="banner" className={styles.bannerImage} />
                    <span id='banner-loader' className={styles.banner_load_bar} />
                </div>
                <GoBackButton t={t} />
                <HeaderContent headerData={headerData} changeHeader={changeHeader} />
                <div className={styles.control_save}>
                    <button className={styles.save_button}>
                        <label htmlFor='banner-file'>
                            <FaImage className={styles.uploadBannerIcon} />
                        </label>
                    </button>
                    <button className={`${styles.save_button} ${saving ? styles.saving : ''}`} disabled={!canSave} onClick={saveDraft}>
                        {
                            !saving ? <FaSave /> : <MdOutlineDataSaverOff className={styles.saving_icon} />
                        }
                    </button>
                </div>
                <div className={styles.upload_zone}>
                    <button className={`${styles.save_button}`} disabled={!canSave} onClick={() => uploadCourse(courseStructuredState)}>
                        <IoMdCloudUpload className={styles.saving_icon} />
                    </button>
                </div>
                <input
                    type="file"
                    className={styles.hidden}
                    accept="image/*"
                    id="banner-file"
                    multiple={false}
                    onChange={(e) => handleBannerUpload(e?.target?.files[0], 'banner-loader')}
                />
            </div>

            <div className={styles.aboutCourse}>
                <AboutCourse
                    headerData={headerData}
                    changeHeader={changeHeader}
                    thumbnail={thumbnail}
                    handleContentImgUpload={handleContentImgUpload}
                />
            </div>

            <CourseStructure
                chapters={chapters}
                setChapters={setChapters}
                lessons={lessons}
                setLessons={setLessons}
                courseMaterial={courseMaterial}
                setCourseMaterial={setCourseMaterial}
                courseId={courseId}
                deleteChapter={deleteChapter}
                deleteLessons={deleteLessons}
                handleContentDelete={handleContentDelete}
            />
        </div>
    );
};

export default NewCourse;

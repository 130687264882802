import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export default function NavTabs({ links, customStyles, extraClass, children, disableScroll }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = (linkPath) => {
    if (disableScroll) {
      const currentScrollY = window.scrollY;
      navigate(linkPath, { replace: true });
      setTimeout(() => {
        window.scrollTo(0, currentScrollY);
      }, 0);
    } else {
      navigate(linkPath);
    }
  };

  return (
    <div className={`${styles.nav_links} ${styles[extraClass]}`} style={customStyles}>
      <ul className={styles.nav_links_list}>
        {links?.map((link) => (
          <li key={link.value} className={styles.nav_links_item}>
            <button
              onClick={() => handleLinkClick(link.path)}
              className={`${styles.nav__link} ${
                location.pathname.endsWith(link.path.split('/').pop()) ? styles.active_link : ''
              }`}
            >
              {link.value}
            </button>
          </li>
        ))}
      </ul>
      {children}
    </div>
  );
}

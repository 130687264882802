import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

const NoResultsMessage = ({ searchText }) => {
  const navigate = useNavigate();

  const handleGoToCourses = () => {
    navigate('/courses');
  };

  const message = searchText
    ? `No results found for "${searchText}". Please try a different search term.`
    : "Start learning now 🙂";

  return (
    
    <div className={styles.noResultsContainer}>
      <p className={styles.noResultsMessage}>{message}</p>
      {!searchText && (
        <button className={styles.goToCoursesButton} onClick={handleGoToCourses}>
          Go to Courses
        </button>
      )}
    </div>
  );
};

export default NoResultsMessage;

import React, { useState, useRef, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { Button } from 'components/ui';
import Emoji from 'assets/icons/emoji.svg?react';
import Arrow from 'assets/icons/arrow.svg?react';
import ReplyIcon from 'assets/icons/reply.svg?react';
import styles from './styles.module.scss';
import profile from 'assets/images/portalofmonth.webp';
import profile2 from 'assets/images/wizzy.webp';

export default function Index() {
  const [userInput, setUserInput] = useState('');
  const [comments, setComments] = useState([]);
  const [replyData, setReplyData] = useState({ commentId: null, replyText: '' });
  const [shownReplies, setShownReplies] = useState([]);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState({
    mainMessage: false,
    replies: {}
  });
  const emojiPickerRef = useRef(null);

  const handleInputChange = (e) => setUserInput(e.target.value);

  const handleSave = () => {
    if (userInput.trim()) {
      const newComment = { id: comments.length + 1, text: userInput, replies: [] };
      setComments([...comments, newComment]);
      setUserInput('');
    }
  };

  const handleReply = (commentId, replyText) => {
    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.id === commentId
          ? { ...comment, replies: [...comment.replies, { text: replyText }] }
          : comment
      )
    );
    setReplyData({ commentId: null, replyText: '' });
    setShownReplies((prev) => (prev.includes(commentId) ? prev : [...prev, commentId]));
  };

  const toggleReplies = (commentId) => {
    setShownReplies((prev) =>
      prev.includes(commentId) ? prev.filter((id) => id !== commentId) : [...prev, commentId]
    );
  };

  const onEmojiClick = (emojiData, isMainMessage) => {
    if (isMainMessage) {
      setUserInput((prevInput) => prevInput + emojiData.emoji);
    } else {
      setReplyData((prevData) => ({
        ...prevData,
        replyText: prevData.replyText + emojiData.emoji
      }));
    }
  };

  const toggleEmojiPicker = (commentId, isMainMessage) => {
    setEmojiPickerVisible((prev) => ({
      ...prev,
      [isMainMessage ? 'mainMessage' : 'replies']: {
        ...prev.replies,
        [commentId]: prev.replies[commentId] ? false : true
      }
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setEmojiPickerVisible((prev) => ({
          ...prev,
          mainMessage: false,
          replies: {}
        }));
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className={styles.container}>
        <div className={`${styles.upper_panel} courseComment`}>
          <textarea
            placeholder="Type message here"
            onChange={handleInputChange}
            value={userInput}
          />
          <button
            type="button"
            className={styles.emojiBtn}
            onClick={() => toggleEmojiPicker(null, true)}
          >
            <Emoji />
          </button>
          {emojiPickerVisible.mainMessage && (
            <div ref={emojiPickerRef}>
              <EmojiPicker onEmojiClick={(emoji) => onEmojiClick(emoji, true)} />
            </div>
          )}
        </div>

        <div className={styles.lower_panel}>
          <Button
            bgColor={userInput.trim() ? '#046799' : '#37363687'}
            onClick={handleSave}
            disabled={!userInput.trim()}
          >
            Post
          </Button>
        </div>

        <div className={styles.comments_list}>
          {comments.map((comment) => (
            <div className={styles.commentBox} key={comment.id}>
              <img src={profile} alt="profile" />
              <div className={styles.commentWrapper}>
                <div className={styles.nameHolder}>
                  <p>Alisa</p>
                  <span>● Just now</span>
                </div>
                <h1 className={styles.comment}>{comment.text}</h1>
                <div className={styles.actions}>
                  <ReplyIcon
                    onClick={() => setReplyData({ commentId: comment.id, replyText: '' })}
                    className={styles.replyIcon}
                  />
                  {comment.replies.length > 0 && (
                    <button
                      className={styles.showRepliesButton}
                      onClick={() => toggleReplies(comment.id)}
                    >
                      {shownReplies.includes(comment.id)
                        ? `Hide replies (${comment.replies.length})`
                        : `Show replies (${comment.replies.length})`}
                    </button>
                  )}
                </div>

                <div
                  className={`${styles.repliesContainer} ${
                    shownReplies.includes(comment.id) ? styles.show : styles.hide
                  }`}
                >
                  {comment.replies.map((reply, index) => (
                    <div key={index} className={styles.replyBox}>
                      <img src={profile2} alt="profile" />
                      <div className={styles.replyWrapper}>
                        <div className={styles.nameHolder}>
                          <p>Blertina</p>
                          <span>● Just now</span>
                        </div>
                        <p className={styles.reply}>{reply.text}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {replyData.commentId === comment.id && (
                  <div className={styles.replyContainer}>
                    <textarea
                      placeholder="Write your reply"
                      onChange={(e) =>
                        setReplyData((prevData) => ({
                          ...prevData,
                          replyText: e.target.value
                        }))
                      }
                      value={replyData.replyText}
                    />
                    <div>
                      <button
                        type="button"
                        className={styles.emojiBtn}
                        onClick={() => toggleEmojiPicker(comment.id, false)}
                      >
                        <Emoji />
                      </button>
                      {emojiPickerVisible.replies[comment.id] && (
                        <div
                          ref={emojiPickerRef}
                          className={`${styles.upper_panel} ${styles.emojiPanel} courseComment`}
                        >
                          <EmojiPicker onEmojiClick={(emoji) => onEmojiClick(emoji, false)} />
                        </div>
                      )}
                    </div>
                    <button
                      className={styles.replyButton}
                      onClick={() => handleReply(comment.id, replyData.replyText)}
                    >
                      <Arrow />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { useGetProjectsByUserIdQuery } from 'api/individual-profile';
import { useProjectUtils } from 'components/organisms/Profile/hooks/templates';
import { LoadMoreButton, Loader } from 'components/ui';
import { isSharedMode } from 'helpers';
import { useHandleRtkPagination } from 'hooks';
import { getCurrentUserIdSelector } from 'store/login.slice';
import styles from './ProjectsSection.module.scss';
import Options from 'components/atoms/Options/Options';
import ps from 'assets/images/PS.webp';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import ProjectCard from './ProjectCard';
import CreateProjectModal from './CreateProjectModal';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CreateModal from 'components/molecules/CreateModal/CreateModal';
import { Textarea } from 'components/form';
import NextArrow from 'assets/icons/arrowSlider.svg?react';
const defaultSectionTitle = 'Projects';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';

function ProjectsSectionView({
  data: projects,
  hasNextPage,
  isFetching,
  setPage,
  sectionTitle,
  isPlaceholdersActive
}) {
  const userId = useSelector(getCurrentUserIdSelector);
  const {
    onUpdateSubmit,
    onDeleteSubmit,
    onCreateSubmit,
    isSaving,
    edit,
    setEdit,
    isCurrentUserProfile,
    create,
    setCreate,
    handelUpload,
    handleMediaUpload
  } = useProjectUtils({ userId });

  const slidesToShow = Math.min(4, projects.length);
  const slidesToScroll = Math.min(3, projects.length);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: Math.min(2, projects.length),
          slidesToScroll: Math.min(2, projects.length)
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: Math.min(1, projects.length),
          slidesToScroll: Math.min(1, projects.length)
        }
      }
    ]
  };

  const [hyperlinkModal, setHyperlinkModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentLink, setCurrentLink] = useState('');
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [updatedProjects, setUpdatedProjects] = useState(projects);

  const handleHyperlinkClick = (projectId, link) => {
    setCurrentProjectId(projectId);
    setCurrentLink(link || '');
    setHyperlinkModal(true);
  };

  const handleSaveLink = () => {
    const newProjects = updatedProjects.map((proj) => {
      if (proj.id === currentProjectId) {
        return { ...proj, link: currentLink };
      }
      return proj;
    });

    setUpdatedProjects(newProjects);
    onUpdateSubmit(newProjects.find((proj) => proj.id === currentProjectId));
    setHyperlinkModal(false);
  };

  const handleDeleteProject = (projectId) => {
    if (!deleteModal) {
      setCurrentProjectId(projectId);
      setDeleteModal(true);
    } else {
      onDeleteSubmit(currentProjectId);
      setDeleteModal(false);
    }
  };

  return (
    <SectionWrapper sectionTitle={sectionTitle ?? defaultSectionTitle}>
      {isCurrentUserProfile && (
        <Options
          extraClass="skillsOptions"
          handleDiscard={() => {
            setEdit(false);
          }}
          handleAdd={() => setCreate(true)}
          handleSave={() => {
            setEdit(false);
          }}
          edit={edit}
        />
      )}
      <div className={styles.education}>
        <Slider {...settings} className="projectProfileSlider">
          {updatedProjects?.map((proj) => (
            <ProjectCard
              key={proj?.id}
              id={proj?.id}
              description={proj?.description}
              name={proj?.name}
              link={proj?.link}
              isCurrentUserProfile={isCurrentUserProfile}
              isPlaceholdersActive={isPlaceholdersActive}
              onUpdateSubmit={onUpdateSubmit}
              onDeleteSubmit={onDeleteSubmit}
              hyperlinkClick={() => handleHyperlinkClick(proj.id, proj.link)}
              handleDelete={() => handleDeleteProject(proj.id)}
              handelUpload={handelUpload}
              handleMediaUpload={handleMediaUpload}
              cover={proj?.cover}
            />
          ))}
        </Slider>
        {isFetching && <Loader />}
        {hyperlinkModal && (
          <CreateModal
            textSave="Activate"
            handleClose={() => setHyperlinkModal(false)}
            clickEvent={handleSaveLink}
          >
            <Textarea
              placeholder="Add link"
              value={currentLink}
              onChange={(e) => setCurrentLink(e.target.value)}
            />
          </CreateModal>
        )}
        {deleteModal && (
          <DeleteModal
            title="Are you sure you want to delete this project?"
            handleClose={() => setDeleteModal(false)}
            handleDelete={() => handleDeleteProject()}
          />
        )}
      </div>

      <CreateProjectModal
        isSaving={isSaving}
        open={create}
        onClose={() => setCreate(false)}
        onSaveProject={(project) => onCreateSubmit(project)}
      />
    </SectionWrapper>
  );
}

function ProjectsSection({ userId, sectionTitle }) {
  const [sharedDate] = useGetSharedProfile();
  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getProjectsByUserId',
    rtkQuery: useGetProjectsByUserIdQuery,
    rtkQueryArgs: { userId },
    rtkQueryOptions: {
      skip: isSharedMode()
    }
  });
  const { data, isLoading, isError, isFetching, error } = !isSharedMode()
    ? result
    : (sharedDate ?? {});
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const projects = isSharedMode() ? data?.projects : data?.result;
  const isPlaceholdersActive = projects?.length === 0;

  return (
    <ProjectsSectionView
      data={projects && projects?.length !== 0 ? projects : placeHolder}
      hasNextPage={data?.page?.hasNextPage}
      isPlaceholdersActive={isPlaceholdersActive}
      setPage={setPage}
      isFetching={isFetching}
      sectionTitle={sectionTitle}
    />
  );
}
export default ProjectsSection;

const placeHolder = [
  {
    id: 1,
    company: 'Google - Placeholder',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien.',
    position: 'Software Engineer',
    tools: [
      {
        name: 'React'
      },
      {
        name: 'Redux'
      },
      {
        name: 'Node.js'
      }
    ],
    name: 'Chatgpt - Placeholder',
    location: 'San Francisco, California',
    datepicker: '2021-05-05',
    to: '2021-05-05',
    from: '2021-05-05',
    ongoing: false
  }
];

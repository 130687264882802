import React, { useState, useRef, useEffect } from 'react';
import styles from './TeamEmployeeCard.module.scss';
import { Link } from 'react-router-dom';
import { getS3Url } from 'helpers/s3Handler';
import defaultUserImage from '../../../assets/images/avatar.webp';
import techDefaultImage from '../../../assets/images/avatar.webp';
import designDefaultImage from '../../../assets/images/avatar.webp';
import Options from 'assets/icons/dots.svg?react';
import DeleteModal from '../DeleteModal/DeleteModal';
import { useKickProfilesFromTeamMutation } from 'api/team';
import { Button } from 'components/ui';

const TeamEmployeeCard = ({
  profile,

  department,
  teamId,
  teamName,
  isCreatedByYou,
  deleteTeamById,
  lastMember
}) => {
  const [options, setOptions] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const cardRef = useRef(null);
  const optionsRef = useRef(null);
  const [kickProfileFromTeam, { loading }] = useKickProfilesFromTeamMutation();

  function defaultImg() {
    switch (department) {
      case 'Tech':
        return techDefaultImage;
      case 'Design':
        return designDefaultImage;
      default:
        return defaultUserImage;
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        cardRef.current &&
        !cardRef.current.contains(event.target) &&
        options &&
        event.target !== optionsRef.current &&
        !optionsRef.current.contains(event.target)
      ) {
        setOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [cardRef, optionsRef, options]);

  return (
    <div
      className={styles.employeeCard}
      style={{
        backgroundImage: `url(${profile?.picture ? getS3Url(profile?.picture) : defaultImg()})`,
        backgroundSize: 'cover'
      }}
    >
      <span
        className={styles.employeeName}
      >{`${profile?.contact?.firstName} ${profile?.contact?.lastName}`}</span>
      <span className={styles.employeeJob}>{profile?.jobPosition || 'Software developer (D)'}</span>
      {isCreatedByYou && (
        <div ref={optionsRef} className={styles.options}>
          <Options
            onClick={(event) => {
              event.stopPropagation();
              setOptions(!options);
            }}
          />
          {options && (
            <ul>
              <li onClick={() => setDeleteModal(true)}>Delete</li>
            </ul>
          )}
        </div>
      )}
      <div className={styles.profileButton}>
        <Link to={`/profile/${profile.id}`}>
          <Button bgColor="#2c2c2c7d" color="white">
            View Profile
          </Button>
        </Link>
      </div>
      {deleteModal && (
        <div className={styles.popupDelete}>
          <DeleteModal
            title={`Are you sure you want to delete ${profile?.contact?.firstName} ${profile?.contact?.lastName} from ${teamName} ?`}
            handleClose={() => setDeleteModal(false)}
            handleDelete={async () => {
              await kickProfileFromTeam({ teamId, profileIds: [profile.id] });
              setDeleteModal(false);
              setOptions(false);
              lastMember && deleteTeamById(teamId);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TeamEmployeeCard;

import { useDispatch, useSelector } from "react-redux";
import { previewStages, setPreviewStage } from "store/quiz.slice";
import { useNavigate } from "react-router-dom";
import styles from './styles.module.scss';

const QuizComplete = () => {
    const dispatch = useDispatch();
    const { title, description } = useSelector((state) => state.quiz);
    const navigate = useNavigate();

    const showResults = () => dispatch(setPreviewStage(previewStages.results));
    const backToQuestions = () => navigate('/quiz');

    return (
        <div className={styles.quizCompleteWrapper}>
            <div className={styles.quizCompleteContainer}>
                <div>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.description}>{description}</p>
                </div>
                <div>
                    <h2>Congrats! You have completed this quiz.</h2>
                    <div className={styles.buttonGroup}>
                        <button onClick={showResults} className={styles.showResultsButton}>
                            Show Results
                        </button>
                        <button onClick={backToQuestions} className={styles.backToQuestionsButton}>
                            Back to Questions
                        </button>
                    </div>
                </div>
                <div className={styles.spacer}></div>
            </div>
        </div>
    );
};

export default QuizComplete;

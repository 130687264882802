import ReactDOM from 'react-dom';
import styles from './FilePreview.module.scss';

const FilePreview = ({ isOpen, onClose, content }) => {
    if (!isOpen) return null;

    const { path, type, ext, title } = content;

    const renderPreview = () => {        
        if (type === 'file' && path) {
            const extension = ext.toLowerCase();
            if (extension === 'png' || extension === 'jpeg' || extension === 'jpg') {
                return <img src={path} alt={title} style={{ maxWidth: '100%' }} />;
            }
            if (extension === 'mp4') {
                return (
                    <video controls style={{ maxWidth: '100%' }}>
                        <source src={path} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                );
            }
            if (extension === 'pdf') {
                return (
                    <embed src={path} type="application/pdf" style={{ width: '100%', height: '500px' }} />
                );
            }
        }
        return <p>Preview not available for this file type.</p>;
    };

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    return ReactDOM.createPortal(
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.closeButton} onClick={onClose}>
                    &times;
                </button>
                <h4>{truncateText(title, 20)}</h4>
                <div className={styles.modalMedia}>
                    {renderPreview()}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default FilePreview;
import PropTypes from 'prop-types';
import styles from './ImageOptimized.module.scss';

export const ImageOptimized = ({ src, alt }) => (
  <img loading='lazy' src={src} effect="blur" alt={alt} className={styles.image} />
);

ImageOptimized.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
};

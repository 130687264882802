import { useState } from "react";
import styles from './styles.module.scss'
import { FaPlus } from "react-icons/fa";
import VideoViwer from "./DataViwers/VideoViewer/VideoViwer";

const MainContentViewer = ({ content }) => {

    // {
    //     "id": "1cb7e663-d26f-4be9-a3b3-35cdd8d9f688",
    //     "type": "file",
    //     "title": "istockphoto-1693366804-640_adpp_is.mp4",
    //     "source": "https://cdn.wizrx.wizrapps.com/courses/673b160469a4d539e8b8e2c2/84b6f9ef-0768-4c93-9b73-cbebdff5eadb/1733143429707-istockphoto-1693366804-640_adpp_is.mp4",
    //     "size": 4033145,
    //     "mimeType": "video/mp4",
    //     "lastModified": 1721659632817,
    //     "extension": "mp4",
    //     "isLoading": false
    // }

    const { mimeType, id } = content;

    return (
        <div className={styles.main_lecture_view_container}
        >
            {
                mimeType.includes('video') && (
                    <VideoViwer content={content} />
                )
            }
        </div>
    )
}

export default MainContentViewer;

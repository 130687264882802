export const skillSuggestions = [
  'HTML',
  'CSS',
  'JavaScript',
  'React.js',
  'Node.js',
  'Angular',
  'Vue.js',
  'TypeScript',
  'Python',
  'Django',
  'Flask',
  'Java',
  'Spring',
  'Kotlin',
  'Swift',
  'Objective-C',
  'Ruby on Rails',
  'PHP',
  'Laravel',
  'MySQL',
  'PostgreSQL',
  'MongoDB',
  'GraphQL',
  'RESTful APIs',
  'Docker',
  'Kubernetes',
  'AWS (Amazon Web Services)',
  'Azure',
  'Google Cloud',
  'DevOps',
  'Git',
  'CI/CD',
  'Jenkins',
  'Terraform',
  'Ansible',
  'Linux',
  'Bash Scripting',
  'C++',
  'C#',
  'ASP.NET',
  'Redux',
  'Sass',
  'LESS',
  'Webpack',
  'Babel',
  'ESLint',
  'Jest',
  'Mocha',
  'Cypress',
  'Selenium',
  'Tailwind CSS',
  'Bootstrap',
  'Material-UI',
  'Figma',
  'Sketch',
  'Adobe XD',
  'Photoshop',
  'Illustrator',
  'InDesign',
  'User Experience (UX) Design',
  'User Interface (UI) Design',
  'Wireframing',
  'Prototyping',
  'SEO (Search Engine Optimization)',
  'Google Analytics',
  'Content Marketing',
  'Email Marketing',
  'Social Media Marketing',
  'Facebook Ads',
  'Google Ads',
  'Copywriting',
  'Blogging',
  'Video Editing',
  'Final Cut Pro',
  'Adobe Premiere Pro',
  'After Effects',
  'Motion Graphics',
  'Data Analysis',
  'Data Visualization',
  'Tableau',
  'Power BI',
  'Excel',
  'SQL',
  'NoSQL',
  'Machine Learning',
  'Deep Learning',
  'Natural Language Processing (NLP)',
  'TensorFlow',
  'Keras',
  'PyTorch',
  'R Programming',
  'MATLAB',
  'Hadoop',
  'Spark',
  'Agile Methodologies',
  'Scrum',
  'Kanban',
  'Project Management',
  'Leadership',
  'Communication',
  'Teamwork',
  'Time Management',
  'Problem Solving',
  'Critical Thinking',
  'Creativity',
  'Adaptability',
  'Conflict Resolution',
  'Negotiation',
  'Decision Making',
  'Emotional Intelligence',
  'Organizational Skills',
  'Risk Management',
  'Business Strategy',
  'Market Research',
  'Product Management',
  'Product Design',
  'Customer Service',
  'Sales',
  'Presentation Skills',
  'Public Speaking',
  'Client Relationship Management',
  'CRM (Customer Relationship Management)',
  'Business Development',
  'Account Management',
  'Networking',
  'Lead Generation',
  'Branding',
  'Graphic Design',
  'Typography',
  '3D Modeling',
  '3D Animation',
  'Blender',
  'Maya',
  'AutoCAD',
  'SolidWorks',
  'Fusion 360',
  'Cinema 4D',
  'ZBrush',
  'Unity',
  'Unreal Engine',
  'Game Development',
  'Mobile App Development',
  'iOS Development',
  'Android Development',
  'Flutter',
  'React Native',
  'Xamarin',
  'SwiftUI',
  'Ionic',
  'Augmented Reality (AR)',
  'Virtual Reality (VR)',
  'Blockchain',
  'Cryptocurrency',
  'Smart Contracts',
  'Solidity',
  'Ethereum',
  'Bitcoin',
  'Cybersecurity',
  'Penetration Testing',
  'Ethical Hacking',
  'Information Security',
  'Network Security',
  'Firewalls',
  'Intrusion Detection',
  'Encryption',
  'Data Privacy',
  'GDPR Compliance',
  'ISO 27001',
  'Risk Assessment',
  'Incident Response',
  'Digital Forensics',
  'Cloud Computing',
  'Hybrid Cloud',
  'Multi-Cloud',
  'SaaS',
  'PaaS',
  'IaaS',
  'Serverless Architecture',
  'Edge Computing',
  'Internet of Things (IoT)',
  'Automation',
  'Robotics',
  'Industrial Automation',
  'Process Automation',
  'Artificial Intelligence',
  'Robotic Process Automation (RPA)',
  'Natural Language Understanding (NLU)',
  'Computer Vision',
  'Image Processing',
  'Speech Recognition',
  'Chatbot Development',
  'OpenCV',
  'DeepMind',
  'Data Mining',
  'Big Data',
  'Data Warehousing',
  'ETL (Extract, Transform, Load)',
  'Data Engineering',
  'Apache Kafka',
  'Apache Flink',
  'Stream Processing',
  'Real-time Data Analytics',
  'Business Intelligence',
  'CRM Integration',
  'ERP (Enterprise Resource Planning)',
  'SAP',
  'Oracle',
  'Microsoft Dynamics',
  'Salesforce',
  'HubSpot',
  'Marketo',
  'Zoho CRM',
  'Asana',
  'Trello',
  'Slack',
  'Microsoft Teams',
  'Jira',
  'Confluence',
  'Basecamp',
  'Monday.com',
  'ClickUp',
  'Notion',
  'Toggl',
  'GitHub',
  'Bitbucket',
  'GitLab',
  'Version Control',
  'Continuous Integration',
  'Continuous Delivery',
  'Automation Testing',
  'Unit Testing',
  'Integration Testing',
  'End-to-End Testing',
  'API Testing',
  'Load Testing',
  'Performance Testing',
  'Security Testing',
  'Usability Testing',
  'Acceptance Testing',
  'Test-Driven Development (TDD)',
  'Behavior-Driven Development (BDD)',
  'Software Development Lifecycle (SDLC)',
  'Requirements Gathering',
  'Systems Analysis',
  'Database Design',
  'Software Architecture',
  'Microservices',
  'Event-Driven Architecture',
  'SOA (Service-Oriented Architecture)',
  'REST Architecture',
  'GraphQL APIs',
  'WebSockets',
  'JSON',
  'XML',
  'OAuth',
  'JWT (JSON Web Token)',
  'Session Management',
  'Authentication',
  'Authorization',
  'Password Management',
  'Multi-Factor Authentication',
  'Biometrics',
  'Single Sign-On (SSO)',
  'SSL/TLS',
  'DNS',
  'IP Networking',
  'TCP/IP',
  'HTTP',
  'HTTPS',
  'VPN',
  'Proxy Servers',
  'Load Balancers',
  'Reverse Proxies',
  'CDNs (Content Delivery Networks)',
  'Web Performance Optimization',
  'Web Accessibility',
  'WAI-ARIA',
  'Responsive Web Design',
  'Cross-Browser Compatibility',
  'Mobile-First Design',
  'Progressive Web Apps (PWA)',
  'Service Workers',
  'Client-Side Storage',
  'LocalStorage',
  'SessionStorage',
  'IndexedDB',
  'WebAssembly',
  'Performance Tuning',
  'Memory Management',
  'Garbage Collection',
  'Concurrency',
  'Multithreading',
  'Parallel Processing',
  'Distributed Systems',
  'Load Balancing',
  'Caching',
  'Message Queues',
  'RabbitMQ',
  'ActiveMQ',
  'Apache Pulsar',
  'Redis',
  'Memcached',
  'Service Discovery',
  'Circuit Breaker Pattern',
  'Rate Limiting',
  'Throttling',
  'API Gateways',
  'Nginx',
  'Apache HTTP Server',
  'Reverse Proxy Configuration',
  'HAProxy',
  'OpenResty',
  'Grafana',
  'Prometheus',
  'ELK Stack (Elasticsearch, Logstash, Kibana)',
  'Splunk',
  'New Relic',
  'Datadog',
  'CloudWatch',
  'Nagios',
  'Zabbix',
  'Monitoring',
  'Alerting',
  'Incident Management',
  'Cost Optimization',
  'Capacity Planning',
  'System Administration',
  'Windows Server',
  'Linux Administration',
  'Active Directory',
  'LDAP',
  'Group Policy',
  'PowerShell',
  'Command Line Interface (CLI)',
  'Shell Scripting',
  'Automated Backups',
  'Disaster Recovery',
  'Business Continuity',
  'DevSecOps',
  'Infrastructure as Code (IaC)',
  'Monitoring and Logging',
  'Security Compliance',
  'Vulnerability Management',
  'Penetration Testing',
  'Identity and Access Management (IAM)',
  'Security Audits',
  'Privacy by Design',
  'Cloud Security',
  'Data Encryption',
  'Network Protocols',
  'Load Testing',
  'Threat Modeling',
  'Security Frameworks',
  'OWASP',
  'PCI-DSS',
  'HIPAA',
  'Data Governance',
  'Risk Management',
  'Governance, Risk, and Compliance (GRC)',
  'Crisis Management',
  'Vendor Management',
  'Policy Development',
  'Contract Negotiation',
  'Procurement',
  'Supply Chain Management',
  'Inventory Management',
  'Logistics',
  'Lean Manufacturing',
  'Six Sigma',
  'Kaizen',
  'Total Quality Management (TQM)',
  'Quality Assurance',
  'Product Lifecycle Management',
  'Product Roadmap Development',
  'User Research',
  'A/B Testing',
  'Multivariate Testing',
  'Heatmaps',
  'Conversion Rate Optimization',
  'Customer Journey Mapping',
  'Usability Testing',
  'User Feedback Analysis',
  'Agile Project Management',
  'Waterfall Project Management',
  'Hybrid Project Management',
  'Project Scheduling',
  'Resource Allocation',
  'Budget Management',
  'Stakeholder Management',
  'Team Leadership',
  'Conflict Resolution',
  'Risk Mitigation',
  'Change Management',
  'Time Management',
  'Documentation',
  'Knowledge Transfer',
  'Onboarding',
  'Mentorship',
  'Coaching',
  'Strategic Planning',
  'Market Research',
  'Competitive Analysis',
  'SWOT Analysis',
  'Cost-Benefit Analysis',
  'Feasibility Studies',
  'KPI Development',
  'Data-Driven Decision Making',
  'OKRs (Objectives and Key Results)',
  'Balanced Scorecard',
  'Business Process Reengineering',
  'Operational Efficiency',
  'Customer Relationship Management (CRM)',
  'Salesforce',
  'HubSpot',
  'Marketing Automation',
  'Email Marketing',
  'SEO',
  'Content Strategy',
  'Lead Generation',
  'Funnel Optimization',
  'Growth Hacking',
  'Social Media Strategy',
  'PPC Advertising',
  'Google Ads',
  'Facebook Ads',
  'LinkedIn Ads',
  'Instagram Ads',
  'Influencer Marketing',
  'Affiliate Marketing',
  'Referral Marketing',
  'Video Marketing',
  'Podcasting',
  'Public Relations',
  'Brand Management',
  'Brand Strategy',
  'Product Marketing',
  'Go-To-Market Strategy',
  'Pricing Strategy',
  'Partnership Development',
  'Channel Sales',
  'Enterprise Sales',
  'B2B Sales',
  'B2C Sales',
  'Account Management',
  'Customer Success',
  'Customer Retention',
  'Churn Reduction',
  'Net Promoter Score (NPS)',
  'Customer Feedback',
  'User Experience Research',
  'Design Thinking',
  'Innovation Management',
  'Creative Problem Solving',
  'Cross-Functional Team Leadership',
  'Employee Engagement',
  'Talent Acquisition',
  'Recruitment Marketing',
  'Candidate Experience',
  'Employee Onboarding',
  'Performance Reviews',
  'Compensation and Benefits',
  'Payroll Management',
  'Employee Relations',
  'Labor Law Compliance',
  'Workforce Planning',
  'Learning and Development',
  'Succession Planning',
  'Diversity and Inclusion',
  'Corporate Social Responsibility',
  'Sustainability',
  'Environmental Impact Assessment',
  'Energy Management',
  'Renewable Energy',
  'Carbon Footprint Reduction',
  'Waste Management',
  'Circular Economy',
  'Sustainable Supply Chains',
  'Green Building',
  'Smart Cities',
  'Urban Planning',
  'Public Policy',
  'Government Relations',
  'Advocacy',
  'Community Outreach',
  'Nonprofit Management',
  'Fundraising',
  'Grant Writing',
  'Volunteer Management',
  'Program Evaluation'
];

import { useState, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

export function usePagination(data = [], itemsPerPage = 10) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentPage = Number(searchParams.get('page')) || 1;

  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginatedData = useMemo(() => {
    return data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [data, currentPage, itemsPerPage]);

  const navigateToPage = (page) => {
    searchParams.set('page', page);
    navigate(`?${searchParams.toString()}`);
  };

  const hasNextPage = currentPage < totalPages;
  const hasPreviousPage = currentPage > 1;

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return {
    paginatedData,
    currentPage,
    totalPages,
    totalItems,
    pageNumbers,
    hasNextPage,
    hasPreviousPage,
    navigateToPage
  };
}

import React from 'react';
import styles from './DataTable.module.scss';

const DataTable = ({ data }) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Answers</th>
            <th>
              Responses (
              {data?.questionData?.reduce((total, item) => total + item.numberOfResponses, 0)})
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.questionData?.map((item, index) => (
            <tr key={index}>
              <td>{item.answer}</td>
              <td>{item.numberOfResponses}</td>
              <td className={styles.actionsColumn}>
                <a href={`/users/${item.answer.toLowerCase()}`}>Show users</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;

import React, { useState } from 'react';
import styles from './styles.module.scss';
import Options from 'components/atoms/Options/Options';
import { EditInput } from 'components/form';
import { Button } from 'components/ui';

export default function NoteCard({ title, note, name, position, handleEdit, handleDelete }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedNote, setEditedNote] = useState(note);
  const [editedNamePosition, setEditedNamePosition] = useState(`${name} (${position})`);

  const handleSave = () => {
    setIsEditing(false);
    handleEdit({
      title: editedTitle,
      note: editedNote,
      name: updatedName,
      position: updatedPosition
    });
  };

  return (
    <div className={styles.noteCard}>
      <div className={styles.top}>
        {isEditing ? (
          <EditInput
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            fontWeight="bold"
            fontSize="1.2rem"
            extraClass="noteTitleInput"
          />
        ) : (
          <h1>{title}</h1>
        )}
        <div className={styles.dots}>
          <Options
            handleEdit={() => setIsEditing(true)}
            handleDelete={handleDelete}
            extraClass="notesOptions"
          />
        </div>
      </div>
      {isEditing ? (
        <EditInput
          value={editedNote}
          onChange={(e) => setEditedNote(e.target.value)}
          fontWeight="normal"
          fontSize="1rem"
          extraClass="noteInput"
        />
      ) : (
        <p className={styles.noteText}>{note}</p>
      )}
      <span className={styles.time}>5 min ago</span>
      {isEditing ? (
        <>
          <EditInput
            value={editedNamePosition}
            onChange={(e) => setEditedNamePosition(e.target.value)}
            fontWeight="normal"
            fontSize="1rem"
            extraClass="noteInput"
          />
          <div className={styles.save}>
            <Button bgColor="linear-gradient(45deg, #30a5e0, #00a480)" onClick={handleSave}>
              Save
            </Button>
          </div>
        </>
      ) : (
        <h2 className={styles.name}>
          {name} ({position})
        </h2>
      )}
    </div>
  );
}

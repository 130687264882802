import DashboardView from 'components/organisms/UpDashboard/DashboardViewNew';
import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';

function UpDashboard() {
  document.body.style.overflow = '';

  return (
    <Routes>
      <Route path="/" element={<Navigate to="courses/ongoing" />} />
      <Route path="courses/:tab" element={<DashboardView />} />
    </Routes>
  );
}

export default UpDashboard;

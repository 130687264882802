import React from 'react';
import { useDropzone } from 'react-dropzone';
import Slider from 'react-slick';
import NextArrowIcon from 'assets/icons/arrowSlider.svg?react';
import PrevArrowIcon from 'assets/icons/arrowrotate.svg?react';
import styles from './MultiFileUploader.module.scss';
import SliderArrow from 'components/atoms/SliderArrow/SliderArrow';
import FilePreview from '../UploaderPreview/UploaderPreview';

const MultiFileUploader = ({ files, setFiles }) => {
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <SliderArrow position={'right'} Icon={PrevArrowIcon} iconStyles={styles.arrowIcon} />
    ),
    prevArrow: <SliderArrow position={'left'} Icon={NextArrowIcon} iconStyles={styles.arrowIcon} />
  };

  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const deleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div className={styles.uploaderTitle}>
        <h4>Uploading Files ({files.length})</h4>
        {files.length > 0 && <button onClick={() => setFiles([])}>Clear All</button>}
      </div>
      {files.length > 0 ? (
        <Slider {...sliderSettings} className={styles.fileCarousel}>
          {files.map((file, index) => (
            <FilePreview key={index} file={file} onDelete={() => deleteFile(index)} />
          ))}
        </Slider>
      ) : (
        <div {...getRootProps({ className: styles['drag-drop-area'] })}>
          <input {...getInputProps()} />
          Drag and drop your files here
        </div>
      )}
    </>
  );
};

export default MultiFileUploader;

import React from 'react';
import styles from './styles.module.scss';
const Input = ({
  label,
  type,
  onChange,
  value,
  placeholder,
  bgColor,
  name,
  extraClass,
  ...rest
}) => {
  return (
    <label className={`${styles.input} ${styles[extraClass]}`}>
      {label}
      <input
        {...rest}
        onChange={onChange}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        className={styles.input}
        style={{ backgroundColor: bgColor }}
      />
    </label>
  );
};

export default Input;

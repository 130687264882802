import { useLanguageUtils } from 'components/organisms/Profile/hooks/templates';
import { LANGUAGES } from 'constants';
import React from 'react';
import Flag from 'react-world-flags';
import styles from './LanguagesSection.module.scss';
import Options from 'components/atoms/Options/Options';
import Icontext from 'components/molecules/IconText/IconText';
import { CustomDropdown } from 'components/form';
import { LANGUAGES_PROFICIENCY } from 'constants';
import { useGetIndividualProfileByProfileIdQuery } from 'api/individual-profile';
import { useSelector } from 'react-redux';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/ui';
import { isSharedMode } from 'helpers';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import defaultLogo from 'assets/icons/worldFlag.svg?react';
import { useTranslation } from 'react-i18next';
function LanguagesSectionView({ languages }) {
  const {
    edit,
    selectedLanguage,
    setSelectedLanguage,
    list,
    setList,
    setEdit,
    isCurrentUserProfile,
    languagesList,
    handleDiscard,
    handleSave
  } = useLanguageUtils({ languages });

  const { t } = useTranslation();

  return (
    <div className={styles.languagesContainer}>
      <div>
        {isCurrentUserProfile && (
          <Options
            extraClass="languageOptions"
            handleDiscard={handleDiscard}
            handleSave={handleSave}
            handleEdit={() => setEdit(true)}
            edit={edit}
          />
        )}
      </div>
      <div>
        <h1 className={styles.title}>{t('Languages')}</h1>
      </div>
      <div className={styles.contentIcons}>
        <div className={styles.content}>
          {(edit ? list : languages)?.map((item) => {
            const found = LANGUAGES.filter(function (country) {
              return country.name === item.name;
            });
            const countryCode = found[0]?.countryCode;
            return (
              <div key={item.name} className={edit ? styles.editIcons : styles.iconswrapper}>
                <Icontext
                  extraClass="languagesIcon"
                  text={item.level}
                  subheading={
                    !edit && item.name.length > 10 ? `${item.name.slice(0, 9)}...` : item.name
                  }
                  opacity={1}
                  padding="7px 15px 10px 15px"
                  bgColor="#212121a1"
                  radius="10px"
                  tooltip={!edit && item.name.length > 10 ? item.name : ''}
                  icon={
                    <Flag
                      code={countryCode}
                      height="30"
                      width="40"
                      fallback={<img height="30" width="40" src={defaultLogo} alt="logo" />}
                    />
                  }
                  key={item.id}
                />
                {edit ? (
                  <span
                    onClick={() => {
                      const newList = [...list];
                      const index = newList.findIndex((val) => val.name === item.name);
                      newList.splice(index, 1);
                      setList(newList);
                    }}
                  >
                    x
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {edit && (
        <div className={styles.languageEdit}>
          <div className={styles.languageWrapper}>
            <div className={styles.selectLang}>
              <CustomDropdown
                search
                placeholder={selectedLanguage.name ? selectedLanguage.name : t('Choose Language')}
                extraClass="langDropdown"
                list={languagesList}
                onChange={(value) => setSelectedLanguage({ ...selectedLanguage, name: value })}
              />
              <CustomDropdown
                placeholder={selectedLanguage.level ? selectedLanguage.level : 'Choose Proficiency'}
                extraClass="langDropdown"
                list={LANGUAGES_PROFICIENCY}
                onChange={(value) => setSelectedLanguage({ ...selectedLanguage, level: value })}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
function LanguagesSection() {
  const [sharedDate] = useGetSharedProfile();
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const { t } = useTranslation();
  const placeHolder = [
    {
      level: t('Advanced'),
      name: 'English United States'
    },
    {
      level: t('Beginner'),
      name: 'French'
    }
  ];
  const result = useGetIndividualProfileByProfileIdQuery(
    {
      profileId
    },
    {
      skip: !profileId || isSharedMode()
    }
  );
  const { data, isLoading, isError, error } = !isSharedMode() ? result : (sharedDate ?? {});
  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }
  const languages = data?.bio?.languages;
  return (
    <LanguagesSectionView
      languages={languages && languages?.length !== 0 ? data?.bio?.languages : placeHolder}
    />
  );
}

export default LanguagesSection;

import React, { useState, useRef, useEffect } from 'react';
import { IoPlayOutline, IoShareSocialOutline } from 'react-icons/io5';
import { FaEllipsisV } from 'react-icons/fa';
import styles from './styles.module.scss';
import ShareModal from 'components/organisms/QuizBuilder/components/ShareModal';
import { useDispatch, useSelector } from 'react-redux';
import { previewStages, setPreviewStage } from 'store/quiz.slice';
import { validateQuizData } from 'components/organisms/QuizBuilder/utils';
import { useNavigate } from 'react-router-dom';

const dropdown = {
    makeCopy: 'Make a Copy',
    delete: 'Delete',
}

export default function RightMenu() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { quizData, title, description } = useSelector((state) => state.quiz);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePreview = () => {
    if (!validateQuizData(title, description, quizData)) return;
    dispatch(setPreviewStage(previewStages.description));
    navigate('/quiz/preview');
  };

  const handleShare = () => {
    setIsShareModalOpen(true);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleDropdownOption = (option) => {
    if (option === dropdown.makeCopy) {
      
    } else if (option === dropdown.delete) {
      
    }
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.nav_controls}>
      <button onClick={handlePreview} className={styles.icon_button}>
        <IoPlayOutline size={20} />
        <span>Preview</span>
      </button>
      <button onClick={handleShare} className={styles.icon_button}>
        <IoShareSocialOutline size={20} />
        <span>Share</span>
      </button>
      <div className={styles.dropdown} ref={dropdownRef}>
        <button onClick={handleDropdownToggle} className={styles.more}>
          <FaEllipsisV size={16} />
        </button>
        {isDropdownOpen && (
          <ul className={styles.dropdown_menu}>
            <li className={styles.dynamic_menuitem} onClick={handlePreview}>
              Preview
            </li>
            <li className={styles.dynamic_menuitem} onClick={handleShare}>
              Share
            </li>
            <li onClick={() => handleDropdownOption(dropdown.makeCopy)}>Make a Copy</li>
            <li onClick={() => handleDropdownOption(dropdown.delete)}>Delete</li>
          </ul>
        )}
      </div>
      <ShareModal isOpen={isShareModalOpen} onClose={closeShareModal} />
    </div>
  );
}

import React, { useEffect, useLayoutEffect, useState } from 'react';
import styles from './DashboardView.module.scss';
import SearchContainer from './components/SimpleSearchContainer';
import { useNavigate, useParams } from 'react-router-dom';
import links from './constants';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';

function DashboardView() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const validTab = links.find((link) => link.path === `/dashboard/courses/${tab}`);

  useLayoutEffect(() => {
    if (!tab || !validTab) {
      navigate('/dashboard/courses/ongoing');
      return;
    }
  }, [tab]);

  return (
    <ResponsiveWrapper>
      <div className={styles['main-wrapper']}>
        <div className={styles.search_container}>
          <SearchContainer links={links} />
        </div>
        <main className={styles.view_container}>{validTab?.page || links[0].page}</main>
      </div>
    </ResponsiveWrapper>
  );
}

export default DashboardView;

import { axiosInstance } from 'api/apiSlice';

const mainUrl = '/v1/courses';

export async function createCourseDraft() {
  const response = await axiosInstance.post(`${mainUrl}/draft`);
  return response.data;
}

export async function getDrafts() {
  const response = await axiosInstance.get(`${mainUrl}/draft`);
  return response.data;
}

export async function getDraftById(id) {
  const response = await axiosInstance.get(`${mainUrl}/${id}/draft`);
  return response.data;
}

export async function updateDraftById(id, data) {
  const response = await axiosInstance.patch(`${mainUrl}/${id}/draft`, data);
  return response.data;
}

export async function createCourse(data) {
  const response = await axiosInstance.post(`${mainUrl}`, data);
  return response.data;
}

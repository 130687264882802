import React, { useEffect } from 'react';
import styles from './QuizCard.module.scss';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import QuizTake from './components/QuizTake';
import QuizComplete from './components/QuizComplete';
import QuizResult from './components/QuizResult';
import QuizSummary from './components/QuizSummary';
import QuizDescription from './components/QuizDescription';
import { previewStages } from 'store/quiz.slice';

const QuizPreview = () => {
  const { previewStage: stage, title, description, quizData } = useSelector((state) => state.quiz);
  const navigate = useNavigate();

  useEffect(() => {
    if (!title || !description || !quizData.length) {
      navigate('/quiz');
    }
  }, [title, description, quizData, navigate]);

  const renderStage = () => {
    switch (stage) {
      case previewStages.description:
        return <QuizDescription />;
      case previewStages.take:
        return <QuizTake />;
      case previewStages.complete:
        return <QuizComplete />;
      case previewStages.results:
        return <QuizResult />;
      case previewStages.summary:
        return <QuizSummary />;
      default:
        return <QuizDescription />;
    }
  };

  return <div className={styles.container}>{renderStage()}</div>;
};

export default QuizPreview;

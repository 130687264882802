import React, { useState, Suspense } from 'react';
import styles from './MediaList.module.scss';
import MediaItem from 'components/atoms/MediaItem/MediaItem';
import { startCourseData } from 'constants/courses';
import Arrow from 'assets/icons/arrowrotate.svg?react';
import { BiSolidHide } from 'react-icons/bi';
import { BiShow } from 'react-icons/bi';
import { Loader } from 'components/ui';

export default function MediaList() {
  const initialMediaItem = startCourseData[0]?.mediaItems[0] || {};
  const initialType = initialMediaItem.video ? 'video' : 'document';
  const [activeMedia, setActiveMedia] = useState({
    type: initialType,
    src: initialMediaItem.video || initialMediaItem.document,
    poster: initialMediaItem.poster,
    sectionIndex: 0,
    index: 0
  });

  const [openSections, setOpenSections] = useState(startCourseData.map((_, index) => index === 0));
  const [isListVisible, setIsListVisible] = useState(true);
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckChange = (sectionIndex, mediaItemIndex) => {
    const itemId = `${sectionIndex}-${mediaItemIndex}`;
    setCheckedItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId]
    }));
  };

  const handleItemClick = (sectionIndex, mediaItemIndex, mediaItem) => {
    setActiveMedia({
      type: mediaItem.video ? 'video' : 'document',
      src: mediaItem.video || mediaItem.document,
      poster: mediaItem.poster,
      sectionIndex,
      index: mediaItemIndex
    });
  };

  const toggleSection = (index) => {
    setOpenSections((prevState) => prevState.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };

  const toggleListVisibility = () => {
    setIsListVisible((prev) => !prev);
  };

  return (
    <div className={`${styles.container} ${!isListVisible ? styles.hidden : ''}`}>
      {activeMedia.type === 'video' ? (
        <video src={activeMedia.src} poster={activeMedia.poster} controls>
          <source src={activeMedia.src} type="video/mp4" />
          <track src={activeMedia.src} kind="captions" srcLang="en" label="English" default />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div>
          <Suspense fallback={<Loader section />}>
            <iframe src={activeMedia.src} className={styles.pdfViewer} title="PDF Viewer" />
          </Suspense>
          {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
            <Suspense fallback={<div>Loading PDF...</div>}>
              <Viewer fileUrl={activeMedia.src} />
            </Suspense>
          </Worker> */}
        </div>
      )}

      <div className={styles.controls}>
        <button onClick={toggleListVisibility}>
          {isListVisible ? (
            <div>
              <p>Hide Content</p>
              <BiSolidHide />
            </div>
          ) : (
            <div>
              <p>Show Content</p>
              <BiShow />
            </div>
          )}
        </button>
      </div>

      {isListVisible && (
        <div className={styles.list}>
          {startCourseData.map((section, sectionIndex) => {
            let totalLessons = section.mediaItems.length;
            let totalMinutes = 0;

            section.mediaItems.forEach((item) => {
              totalMinutes += parseInt(item.time.replace('min', '').trim(), 10);
            });

            return (
              <div key={sectionIndex}>
                <div
                  className={`${styles.titleContainer} ${openSections[sectionIndex] ? styles.active : ''}`}
                  onClick={() => toggleSection(sectionIndex)}
                  aria-hidden="true"
                >
                  <div className={styles.topWrapper}>
                    <h2 className={styles.mainTitle}>
                      Section {sectionIndex + 1}: {section.title}
                    </h2>
                    <p className={styles.contain}>
                      {totalLessons} {totalLessons === 1 ? 'lesson' : 'lessons'} | {totalMinutes}{' '}
                      min
                    </p>
                  </div>
                  <Arrow
                    className={openSections[sectionIndex] ? styles.arrowOpen : styles.arrowClosed}
                  />
                </div>
                <div
                  className={`${styles.mediaItems} ${openSections[sectionIndex] ? styles.open : ''}`}
                >
                  {section.mediaItems.map((mediaItem, mediaIndex) => {
                    const itemId = `${sectionIndex}-${mediaIndex}`;
                    return (
                      <div
                        key={mediaIndex}
                        aria-hidden="true"
                        onClick={() => handleItemClick(sectionIndex, mediaIndex, mediaItem)}
                      >
                        <MediaItem
                          active={
                            activeMedia.sectionIndex === sectionIndex &&
                            activeMedia.index === mediaIndex
                          }
                          poster={mediaItem?.poster}
                          {...mediaItem}
                          type={mediaItem.video ? 'video' : 'document'}
                          isChecked={checkedItems[itemId] || false}
                          onCheckChange={handleCheckChange}
                          sectionIndex={sectionIndex}
                          mediaItemIndex={mediaIndex}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

import React, { useEffect } from 'react';
import styles from './TelInput.module.scss';
import { COUNTRIES_DIALCODES } from 'constants';
import { getDialAndNumber } from 'helpers';
import SearchI from 'assets/icons/search.svg?react';
const getSelectedCountryByDialCode = (dialCode_number) => {
  const dialCode = getDialAndNumber(dialCode_number)[0];
  if (!dialCode) return COUNTRIES_DIALCODES[0];
  return COUNTRIES_DIALCODES.find((item) => item.dialCode === dialCode) || COUNTRIES_DIALCODES[0];
};
function TelInput({ onChange, value, placeholder, title, name, ...rest }) {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(getSelectedCountryByDialCode(value));
  const [searchedCountries, setSearchedCountries] = React.useState(COUNTRIES_DIALCODES);
  const menuRef = React.useRef();

  const onOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onOutsideClick);
    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, []);

  return (
    <div style={{ margin: '10px 0px' }} ref={menuRef}>
      <div className={styles['wrapper']}>
        <div
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
          className={styles['codes']}
        >
          <h4>{selectedCountry?.dialCode}</h4>
        </div>
        {showDropdown && (
          <div className={styles['drop-down']}>
            <div className={styles['drop-down-search']}>
              <SearchI />
              <input
                placeholder="Search for a country..."
                onChange={(e) => {
                  if (e.target.value === '') {
                    setSearchedCountries(COUNTRIES_DIALCODES);
                    return;
                  }
                  const { value } = e.target;
                  const searchedCountries = COUNTRIES_DIALCODES.filter((item) =>
                    item.name.toLowerCase().startsWith(value.toLowerCase())
                  );
                  setSearchedCountries(searchedCountries);
                }}
                type="text"
              />
            </div>

            {searchedCountries.map((item) => (
              <div
                className={styles['drop-down-item']}
                onClick={() => {
                  setSelectedCountry(item);
                  setShowDropdown(false);
                  setSearchedCountries(COUNTRIES_DIALCODES);
                }}
              >
                <div style={{ display: 'flex', gap: '5px' }}>
                  <img loading="lazy" src={item.flag} alt="Flag" />
                  <h4>{item.name}</h4>
                </div>
                {item.dialCode}
              </div>
            ))}
          </div>
        )}
        <input
          placeholder="Whatsapp"
          type="text"
          name={name}
          value={getDialAndNumber(value)[1]}
          onChange={(e) => {
            const { value } = e.target;
            if (value.length > 15) return;
            if (value[0] === '0') return;
            if (isNaN(value)) return;
            onChange({ target: { value: `${selectedCountry.dialCode}_${value}`, name } });
          }}
          className={styles['tel-input']}
        />
      </div>
    </div>
  );
}

export default TelInput;

import React from 'react';
import styles from "./NewCourse.module.scss";
import { Button } from 'components/ui';
import Arrow from 'assets/icons/arrowright.svg?react';
import { useNavigate } from 'react-router-dom';

const GoBackButton = ({ t }) => {
  const navigate = useNavigate();
  const goToPreviousPage = () => navigate(-1);
  return (
      <div className={styles.goBack}>
          <Button bgColor="transparent" extraClass="backButton" onClick={goToPreviousPage}>
              <Arrow />
              {t('Go Back')}
          </Button>
      </div>
  )
};

export default GoBackButton;
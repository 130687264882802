import Text from 'components/atoms/Text/Text';
import { ProfilePic } from 'components/ui';
import React, { useEffect } from 'react';
import styles from './Review.module.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCurrentUserProfileId } from 'store/login.slice';
import { EditInput } from 'components/form';
import { getS3Url } from 'helpers/s3Handler';
import defaultImage from 'assets/images/avatar.webp';
import Edit from 'assets/icons/editicon.svg?react';
import TrashIcon from 'assets/icons/trash.svg?react';
import Save from 'assets/icons/icon-save.svg?react';

import RatingStarsDropdown from 'components/molecules/RatingStarsDropdown/RatingStarsDropdown';
import RatingStars from 'components/molecules/RatingStars/RatingStars';
import { useGetUrlQuery, useScrollToItem } from 'hooks';

export default function Review({ onDelete, onUpdate, originReview }) {
  const activeReview = useGetUrlQuery('entityId') === originReview.id;
  const img = originReview?.reviewerProfile?.picture
    ? getS3Url(originReview?.reviewerProfile?.picture)
    : defaultImage;
  const name = `${originReview?.reviewerProfile?.contact?.firstName} ${originReview?.reviewerProfile?.contact?.lastName}`;
  const position = originReview?.reviewerProfile?.jobPosition || '';
  const reviewerProfileId = originReview?.reviewerProfile?.id;

  const [edit, setEdit] = React.useState(false);
  const [review, setReview] = React.useState({
    description: originReview.description,
    rating: originReview.rating
  });
  const prepareReview = () => {
    return {
      //The originReview is the review from the server
      ...originReview,
      // Only the id of the reviewerProfile must be sent
      reviewerProfile: originReview.reviewerProfile.id,
      // the review that is being edited
      ...review
    };
  };
  useScrollToItem(originReview.id, activeReview);
  const currentProfileId = useSelector(getCurrentUserProfileId);
  return (
    <div
      id={originReview.id}
      style={
        activeReview
          ? {
              border: '1px solid #E5E5E5',
              borderRadius: '10px'
            }
          : {}
      }
      className={styles.review}
    >
      <div className={styles.profileinfo}>
        <ProfilePic clickable profileId={reviewerProfileId} width={73} height={73} image={img} />
        <div>
          <Text align="left" title={name} paragraph={position} />
          <div className={styles.reviewContent}>
            <div className={styles.reviewpart}>
              {edit ? (
                <>
                  <RatingStarsDropdown
                    onChooseRating={(value) => {
                      setReview((prev) => ({ ...prev, rating: value }));
                    }}
                    rating={review.rating}
                  />
                  <EditInput
                    extraClass="reviewInput"
                    placeholder="Review"
                    fontWeight="bold"
                    value={review.description}
                    maxLength={200}
                    onChange={({ target: { value } }) => {
                      setReview((prev) => ({ ...prev, description: value }));
                    }}
                  />
                </>
              ) : (
                <>
                  <RatingStars size={3} value={Math.round(originReview.rating)} />
                  <Text
                    extraClass="reviewparagraph"
                    align="left"
                    paragraph={originReview.description}
                  />
                </>
              )}
            </div>
            {currentProfileId === reviewerProfileId && (
              <div className={styles.buttons}>
                <button onClick={onDelete} name="delete">
                  <TrashIcon />
                </button>
                {edit && (
                  <button
                    onClick={async () => {
                      setEdit(false);
                      await onUpdate(prepareReview());
                    }}
                  >
                    <Save />
                  </button>
                )}
                {!edit && (
                  <button
                    onClick={() => {
                      setReview({
                        description: originReview.description,
                        rating: originReview.rating
                      });
                      setEdit(!edit);
                    }}
                  >
                    <Edit />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Review.propTypes = {
  originReview: PropTypes.object,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func
};

import React from 'react';
import styles from './FileUploader.module.scss';
import { IoClose } from 'react-icons/io5';

export default function FileUploader({ isOpen, onClose, onImageSelect }) {
  const handleFiles = (files) => {
    const fileList = Array.from(files);

    if (fileList.length > 0) {
      const imageUrl = URL.createObjectURL(fileList[0]);
      onImageSelect(imageUrl);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleBrowse = (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  return (
    isOpen && (
      <div className={styles.modalContainer}>
        <div
          className={styles.container}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <input
            type="file"
            id="file-input"
            multiple
            onChange={handleBrowse}
            style={{ display: 'none' }}
          />
          <label className={styles.labelText} htmlFor="file-input">
            Drag and drop your images <br /> -or-
            <br />
            <div>
              <span className={styles.browse}> Browse files</span>
            </div>
          </label>
          <button className={styles.closeBtn} onClick={onClose}>
            <IoClose />
          </button>
        </div>
      </div>
    )
  );
}

import React from 'react';
import { useSetBioLanguagesMutation } from 'api/individual-profile';
import { LANGUAGES } from 'constants';
import { handleAsync } from 'helpers';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Flag from 'react-world-flags';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import { getCurrentUserProfileId } from 'store/login.slice';
import defaultLogo from 'assets/icons/worldFlag.svg?react';
function useLanguageUtils({ languages }) {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const globalEdit = useSelector(getGlobalEditSelector);
  const [edit, setEdit] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: '',
    level: ''
  });
  const [list, setList] = useState(languages);
  const memoSortedLanguages = useMemo(() => {
    return [...LANGUAGES].sort((a, b) => a.name.localeCompare(b.name));
  }, []);
  const [updateLanguages] = useSetBioLanguagesMutation();
  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);

  const addLanguage = async (newList) => {
    setSelectedLanguage({ name: '', level: '' });
    setEdit(false);
    // const hasNothingChangedArr = languages
    //   .map((l) => newList.find((la) => la.name === l.name && la.level === l.level))
    //   .filter(Boolean);
    // if (languages.length === hasNothingChangedArr.length) return;
    const [status] = await handleAsync(updateLanguages({ languages: newList }).unwrap());
    status && setList(newList);
  };

  const languagesList = Object.values(memoSortedLanguages).map((val) => ({
    text: val.name,
    icon: (
      <>
        <Flag
          code={val.countryCode}
          height="16"
          width="30"
          fallback={<img height="16" width="30" src={defaultLogo} alt="flag image" />}
        />
      </>
    )
  }));

  const handleDiscard = () => {
    setList(languages);
    setEdit(false);
  };

  const handleSave = () => {
    const newList = [...list];
    if (selectedLanguage.name && selectedLanguage.level) {
      const index = newList.findIndex((val) => val.name === selectedLanguage.name);
      if (index !== -1) {
        setEdit(false);
        setSelectedLanguage({ name: '', level: '' });
        return;
      } else newList.push(selectedLanguage);
    }
    addLanguage(newList);
  };
  return {
    edit,
    setEdit,
    selectedLanguage,
    setSelectedLanguage,
    list,
    setList,
    isCurrentUserProfile,
    languagesList,
    handleDiscard,
    handleSave
  };
}

export default useLanguageUtils;

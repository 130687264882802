import { Input, Textarea } from 'components/form';
import React from 'react';
import { CompanyButton, SectionTag, Wrapper } from '../../components';
import styles from './Contact.module.scss';
import {
  handleAsync,
  isStringLimited,
  isValidEmail,
  isValidPhoneNumber,
  max,
  required
} from 'helpers';
import { showErrorToast, showWarnToast } from 'utils/toast';
import { getTheme } from 'helpers/general';
import { useContactCompanyMutation } from 'api/company-profile';

const inputs_data = [
  {
    id: 1,
    name: 'fullName',
    type: 'text',
    placeholder: 'Full Name',
    limit: 100
  },
  {
    id: 2,
    name: 'email',
    type: 'email',
    placeholder: 'Email',
    limit: 100
  },
  {
    id: 3,
    name: 'phone',
    type: 'number',
    placeholder: 'Phone',
    limit: 15,
    handleKeyDown: (event) => {
      if (event.key === 'e') {
        event.preventDefault();
      }
    }
  },
  {
    id: 4,
    name: 'location',
    type: 'text',
    placeholder: 'Location',
    limit: 300
  }
];

const appNav = document.getElementById('app-nav');
const appNavHeight = appNav?.offsetHeight || 0;
function Contact({ companyTheme, contact }) {
  const [formState, setFormState] = React.useState({
    fullName: {
      value: '',
      errorMessage: '',
      validators: [required]
    },
    email: { value: '', errorMessage: '', validators: [required, isValidEmail] },
    phone: { value: '', errorMessage: '', validators: [required, isValidPhoneNumber] },
    location: { value: '', errorMessage: '', validators: [required] },
    message: { value: '', errorMessage: '', validators: [required, max(1000)] }
  });
  const resetFormState = () => {
    setFormState({
      fullName: {
        ...formState.fullName,
        value: ''
      },
      email: {
        ...formState.email,
        value: ''
      },
      phone: {
        ...formState.phone,
        value: ''
      },
      location: {
        ...formState.location,
        value: ''
      },
      message: {
        ...formState.message,
        value: ''
      }
    });
  };
  const [sendEmailToCompany, { isLoading: isSending }] = useContactCompanyMutation();

  const validateInputs = () => {
    const newState = { ...formState };
    let valid = true;
    // loop through all the inputs
    for (const key in newState) {
      if (!valid) break;
      // reset the error message
      newState[key].errorMessage = '';
      // check if the input has validators
      if (Object.prototype.hasOwnProperty.call(newState, key)) {
        const element = newState[key];
        // check if the input has validators && loop through them
        if (element.validators) {
          for (let i = 0; i < element.validators.length; i++) {
            const validator = element.validators[i];
            // check if the input is valid
            const { isValid, message } = validator(element.value);
            // if the input is not valid, set the error message and break the loop
            if (!isValid) {
              valid = false;
              newState[key].errorMessage = message;
              break;
            }
          }
        }
      }
    }
    // update the state
    setFormState(newState);
    if (!valid) {
      // send the message
      // show the error message
      const errorsInputs = Object.keys(newState).filter((key) => newState[key].errorMessage);
      const errors = errorsInputs.map((name) => newState[name].errorMessage);
      showErrorToast(`${errorsInputs[0]}: ${errors[0]}`);
    }

    return valid;
  };

  const theme = getTheme(companyTheme, 'companyPage');

  return (
    <>
      <div
        id="company-contact us"
        style={{
          position: 'relative',
          top: `-${appNavHeight}px`
        }}
      />

      <Wrapper px={4} py={3}>
        <SectionTag title="Contact" />
        <h2 className={styles['contact__title']}>For Any question or remarks</h2>
        <form
          className={styles['contact__form']}
          onSubmit={async (e) => {
            e.preventDefault();
            const validation = validateInputs();
            if (!validation) return;
            const prepareData = {
              fullName: formState.fullName.value,
              sender: formState.email.value,
              phone: formState.phone.value,
              location: formState.location.value,
              message: formState.message.value,
              recipient: contact?.email
            };
            const [state] = await handleAsync(
              sendEmailToCompany({ contact: prepareData }).unwrap()
            );
            if (state) resetFormState();
          }}
        >
          {inputs_data.map((item) => (
            <>
              <div className={styles['contact__form__input']} key={item.id}>
                <Input
                  onChange={(e) => {
                    const newState = { ...formState };
                    const [isIt, value] = isStringLimited(e.target.value, item.limit);
                    if (!isIt)
                      showWarnToast(
                        `You have reached the maximum number of characters (${item.limit})`
                      );

                    newState[item.name].value = value;
                    setFormState(newState);
                  }}
                  value={formState[item.name].value}
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  handleKeyDown={item.handleKeyDown}
                  bgColor="#1C1C1C"
                  key={item.id}
                  extraClass="contactInput"
                />
              </div>
            </>
          ))}

          <div className={styles['contact__form__textarea']}>
            <Textarea
              placeholder={'Message'}
              extraClass="messageTextarea"
              value={formState.message.value}
              key={'item.id'}
              change={(e) => {
                const newState = { ...formState };
                const [isIt, value] = isStringLimited(e.target.value, 1000);
                if (!isIt)
                  showWarnToast('You have reached the maximum number of characters (1000)');
                newState['message'].value = value;
                setFormState(newState);
              }}
            />
          </div>

          <CompanyButton mt="1rem" bg={theme.contactGradient} textColor="white" type="submit">
            Send message
          </CompanyButton>
        </form>
      </Wrapper>
    </>
  );
}

export default Contact;

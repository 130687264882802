import React from 'react';
import styles from './CustomRadioButton.module.scss';
import PropTypes from 'prop-types';

export default function CustomRadioButton({ checked, onChange, bgChecked, extraClass, size = 'medium' }) {

  const sizeStyles = {
    small: {
      outer: { width: '15px', height: '15px' },
      inner: { width: '7px', height: '7px' },
    },
    medium: {
      outer: { width: '20px', height: '20px' },
      inner: { width: '10px', height: '10px' },
    },
    large: {
      outer: { width: '25px', height: '25px' },
      inner: { width: '12px', height: '12px' },
    },
  };

  const selectedSize = sizeStyles[size] || sizeStyles.medium;

  return (
    <div
      className={`${styles.radioButton} ${checked ? styles.checked : ''} ${styles[extraClass]}`}
      style={{ ...selectedSize.outer }}
      onClick={onChange}
    >
      <div className={styles.innerCircle} style={{ ...selectedSize.inner, background: checked && bgChecked }}></div>
    </div>
  );
}

CustomRadioButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  bgChecked: PropTypes.string
};

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import styles from './Chapter.module.scss';
import { FaPencil, FaRegTrashCan } from 'react-icons/fa6';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import Lesson from '../Lesson/Lesson';
import { randomUUId } from 'helpers/general';

const new_lesson_obj = {
  uuid: null,
  title: 'New Lesson',
  content: null
};

const Chapter = ({ title, uuid, setChapters, lessons, setLessons,
  courseMaterial, setCourseMaterial, courseId,
   deleteChapter, deleteLessons,
   handleContentDelete
   }) => {
  const [collapseBody, setCollapseBody] = useState(false);
  const [editable, setEditable] = useState(false);

  const toggleCollapsible = () => {
    setCollapseBody(!collapseBody);
  };

  const handleAddLesson = () => {
    setLessons((prev) => [...prev, { ...new_lesson_obj, uuid: randomUUId(), chapteruuid: uuid }]);
  };

  const editChapterName = (e) => {
    e.stopPropagation();
    setEditable(true);
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;
    setChapters((prev) => {
      return prev.map((chapter) => {
        if (chapter.uuid === uuid) {
          return { ...chapter, title };
        }
        return chapter;
      });
    });
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      setEditable(false);
    }
  };

  const videoExtensions = new Set(['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv']);

  const totalVideoCount = 0;

  const thisChapterLessons = lessons.filter(lesson => lesson?.chapteruuid === uuid)

  return (
    <div className={styles.container}>
      <div className={styles.lesson_header} onClick={toggleCollapsible}>
        <div className={styles.header_left} onClick={(e) => e.stopPropagation()} onDoubleClick={editChapterName}>
          {collapseBody ? <IoIosArrowDown size={18} onClick={toggleCollapsible} /> : <IoIosArrowUp size={18} onClick={toggleCollapsible} />}
          {editable ? (
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              onBlur={() => setEditable(false)}
              onKeyUp={handleKeyUp}
              autoFocus
            />
          ) : (
            <>
              {title}
              <div className={styles.icon}>
                <FaPencil onClick={editChapterName} />
              </div>
            </>
          )}
        </div>
        <div className={styles.header_right}>
          <span>
            {thisChapterLessons.length} {thisChapterLessons.length === 1 ? 'Lesson' : 'Lessons'}
          </span>
          <span> | </span>
          <span>{totalVideoCount} Videos</span>
          <FaRegTrashCan style={{ cursor: 'pointer' }} onClick={(e) => deleteChapter(e, uuid)} />
        </div>
      </div>
      {!collapseBody && (
        <div className={styles.lesson_body}>
          {thisChapterLessons && thisChapterLessons.map((lesson) => <Lesson
            lesson={lesson}
            key={lesson.uuid}
            setLessons={setLessons}
            courseMaterial={courseMaterial}
            setCourseMaterial={setCourseMaterial}
            courseId={courseId}
            deleteLessons={deleteLessons}
            handleContentDelete={handleContentDelete}
          />)}
          <button className={styles.add} onClick={handleAddLesson}>
            <FaPlus /> Add Lesson
          </button>
        </div>
      )}
    </div>
  );
};

export default Chapter;

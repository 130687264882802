import React from 'react';
import styles from "./NewCourse.module.scss";

const HeaderContent = ({ headerData, changeHeader }) => {
  const { title, subtitle } = headerData || {};

  return (
      <div className={styles.headerContent}>
          <div className={styles.tileForm}>
              <label htmlFor="title">Course Title</label>
              <input value={title || ''} type="text" id="title" onChange={(e) => changeHeader({ title: e.target.value })} />
              <label htmlFor="subtitle">Subtitle</label>
              <input value={subtitle || ''} type="text" id="subtitle" onChange={(e) => changeHeader({ subtitle: e.target.value })} />
          </div>
      </div>
  )
};

export default HeaderContent;
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styles from './styles.module.scss';
import Arrow from 'assets/icons/arrow.svg?react';
import { Button } from 'components/ui';
import { useNavigate } from 'react-router-dom';
import QuizAnswerType from 'components/molecules/QuizAnswerType/QuizAnswerType';
import QuizOptions from 'components/molecules/QuizOptions/QuizOptions';
import { GoTrash } from 'react-icons/go';
import {
  addQuestion,
  updateQuestion,
  updateAnswerType,
  updateOptions,
  updateHint,
  updateAnswer,
  toggleContentVisibility,
  deleteQuestion,
  updatePoints,
} from 'store/quiz.slice';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { validateQuizData } from 'components/organisms/QuizBuilder/utils';

export default function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { quizData, title, description } = useSelector((state) => state.quiz);

  const handleOptionSelect = (option, index) => {
    dispatch(updateAnswerType({ index, type: option }));
  };

  const handleQuestionChange = (value, index) => {
    dispatch(updateQuestion({ index, question: value }));
  };

  const handleAddQuestion = () => {
    dispatch(addQuestion());
  };

  const handleHintChange = (value, index) => {
    dispatch(updateHint({ index, hint: value }));
  };

  const handlePointsChange = (value, index) => {
    dispatch(updatePoints({ index, points: value }));
  };

  const handleAnswerChange = (index, answer) => {    
    dispatch(updateAnswer({ index, answer }));
  };

  const handleOptionChange = (index, options) => {
    dispatch(updateOptions({ index, options }));
  };

  const toggleVisibility = (index) => {
    dispatch(toggleContentVisibility(index));
  };

  const handleDeleteQuestion = (index) => {
    dispatch(deleteQuestion(index));
  };

  const saveQuizData = () => {
    if (!validateQuizData( title, description, quizData )) return;
    navigate('/quiz/settings');
  };

  return (
    <div className={styles.container}>
      {quizData.map((questionData, index) => {
        const {
          question,
          type,
          options,
          hint,
          points,
          answer,
          isContentVisible = true,
        } = questionData;

        return (
          <div className={styles.dropdown} key={index}>
            <div className={styles.topContainer}>
              <div className={styles.top}>
                <div className={styles.inputContainer}>
                  <span>Q</span>
                  <p>Question:</p>
                  <input
                    type="text"
                    value={question || ''}
                    onChange={(e) => handleQuestionChange(e.target.value, index)}
                    placeholder="Your question goes here"
                  />
                </div>
                <button onClick={() => toggleVisibility(index)}>
                  {
                    isContentVisible
                    ? <FaCaretUp size={20} />
                    : <FaCaretDown size={20} />
                  }
                </button>
              </div>
              <button onClick={() => handleDeleteQuestion(index)} className={styles.deleteBtn}>
                <GoTrash />
              </button>
            </div>
            {isContentVisible && (
              <>
                <div className={styles.content}>
                  <QuizAnswerType
                    selectedType={type}
                    onSelectType={(type) => handleOptionSelect(type, index)}
                  />
                </div>
              
                <div className={styles.selectedType}>
                  <QuizOptions
                    options={options}
                    selectedType={type}
                    onChangeOptions={(options) => handleOptionChange(index, options)}
                    points={points}
                    hint={hint}
                    onHintChange={(value) => handleHintChange(value, index)}
                    onPointsChange={(value) => handlePointsChange(value, index)}
                    onAnswerChange={(answer) => handleAnswerChange(index, answer)}
                    answer={answer && answer[type]}
                  />
                </div>
              </>
            )}
          </div>
        );
      })}
      <div aria-hidden="true" onClick={handleAddQuestion} className={styles.addQuestion}>
        <p>Add Question</p>
        <span>+</span>
      </div>
      {quizData.length > 0 && (
        <div className={styles.buttons}>
          <Button bgColor={'#434343'} color={'white'}>
            Cancel
          </Button>
          <Button onClick={saveQuizData} bgColor={'#046799'} color={'white'}>
            Save & Continue
          </Button>
        </div>
      )}
    </div>
  );
}

import styles from './styles.module.scss';

const ProgressBar = ({ current, total }) => {
  const progressPercentage = (current / total) * 100;

  return (
    <div className={styles.progressBar}>
      <span>
        {current}/{total} Questions
      </span>
      <div className={styles.progressBarBackground}>
        <div className={styles.filler} style={{ width: `${progressPercentage}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;

import { createSlice } from '@reduxjs/toolkit';

export const previewStages = {
  description: 'description',
  take: 'take',
  complete: 'complete',
  results: 'results',
  summary: 'summary'
};

export const answerTypes = {
  multiChoice: 'Multi choice',
  singleChoice: 'Single choice',
  textAns: 'Text',
  pictureChoice: 'Picture choice'
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    title: '',
    description: '',
    quizData: [],
    duration: 600,
    timeSpent: 0,
    previewStage: previewStages.description,
    answers: []
  },
  reducers: {
    setQuizData: (state, action) => {
      state.quizData = action.payload.quizData;
      if (action.payload.duration) {
        state.duration = action.payload.duration || null;
      }
    },
    clearQuizData: (state) => {
      state.quizData = [];
      state.duration = null;
    },
    setQuizDuration: (state, action) => {
      state.duration = action.payload;
    },
    setTimeSpent: (state, action) => {
      state.timeSpent = action.payload;
    },
    setQuizTitle: (state, action) => {
      state.title = action.payload;
    },
    setQuizDescription: (state, action) => {
      state.description = action.payload;
    },
    setPreviewStage: (state, action) => {
      state.previewStage = action.payload;
    },
    setQuizAnswers: (state, action) => {
      state.answers = action.payload;
    },
    addQuestion: (state) => {
      state.quizData.push({
        question: '',
        type: answerTypes.singleChoice,
        options: [],
        hint: '',
        answer: null,
        points: 1,
        isContentVisible: true
      });
    },
    updateQuestion: (state, action) => {
      const { index, question } = action.payload;
      if (state.quizData[index]) {
        state.quizData[index].question = question;
      }
    },
    updateAnswerType: (state, action) => {
      const { index, type } = action.payload;
      if (state.quizData[index]) {
        state.quizData[index].type = type;
      }
    },
    updateOptions: (state, action) => {
      const { index, options } = action.payload;
      if (state.quizData[index]) {
        state.quizData[index].options = options;
      }
    },
    updateHint: (state, action) => {
      const { index, hint } = action.payload;
      if (state.quizData[index]) {
        state.quizData[index].hint = hint;
      }
    },
    updatePoints: (state, action) => {
      const { index, points } = action.payload;
      if (state.quizData[index]) {
        state.quizData[index].points = points;
      }
    },
    updateAnswer: (state, action) => {
      const { index, answer } = action.payload;
      if (state.quizData[index]) {
        const answerType = state.quizData[index].type;
        state.quizData[index].answer = {
          [answerType]: answer
        };
      }
    },
    toggleContentVisibility: (state, action) => {
      const index = action.payload;
      if (state.quizData[index]) {
        state.quizData[index].isContentVisible = !state.quizData[index].isContentVisible;
      }
    },
    deleteQuestion: (state, action) => {
      const index = action.payload;
      if (index >= 0 && index < state.quizData.length) {
        state.quizData.splice(index, 1);
      }
    }
  }
});

export const {
  setQuizData,
  setQuizDuration,
  setTimeSpent,
  clearQuizData,
  setQuizTitle,
  setQuizDescription,
  setPreviewStage,
  setQuizAnswers,
  addQuestion,
  updateQuestion,
  updateAnswerType,
  updateOptions,
  updateHint,
  updatePoints,
  updateAnswer,
  toggleContentVisibility,
  deleteQuestion
} = quizSlice.actions;
export default quizSlice.reducer;

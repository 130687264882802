import React from 'react';
import CourseCard from 'components/atoms/CourseCard/CourseCard';
import { coursesData } from 'constants/courses';
import styles from '../../../styles.module.scss';

const filterContent = coursesData.map(({ url, by, date, lessons, ...rest }) => rest).slice(0, 3);

export default function index() {
  return (
    <>
      <div className={styles.cardlist}>
        {filterContent?.map((d, i) => {
          return <CourseCard {...d} key={i} actions={true} />;
        })}
      </div>
    </>
  );
}

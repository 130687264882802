const data = [
  {
    _id: Math.random(),
    name: 'Alice Brown',
    spent_time: 0,
    progress: 0,
    purchase_date: new Date('2023-10-10T14:48:00.000Z'),
    expiry_date: new Date('2024-10-10T14:48:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'John Smith',
    spent_time: 40,
    progress: 100,
    purchase_date: new Date('2023-11-05T09:15:00.000Z'),
    expiry_date: new Date('2024-11-05T09:15:00.000Z'),
    certified: false
  },
  {
    _id: Math.random(),
    name: 'Ella W.',
    spent_time: 15,
    progress: 100,
    purchase_date: new Date('2024-01-20T08:30:00.000Z'),
    expiry_date: new Date('2025-01-20T08:30:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'Michael Jordan',
    spent_time: 35,
    progress: 75,
    purchase_date: new Date('2024-03-14T12:00:00.000Z'),
    expiry_date: new Date('2025-03-14T12:00:00.000Z'),
    certified: false
  },
  {
    _id: Math.random(),
    name: 'Priya Chatterjee',
    spent_time: 20,
    progress: 60,
    purchase_date: new Date('2024-05-18T10:00:00.000Z'),
    expiry_date: new Date('2025-05-18T10:00:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'Tom L.',
    spent_time: 28,
    progress: 85,
    purchase_date: new Date('2024-06-01T07:45:00.000Z'),
    expiry_date: new Date('2025-06-01T07:45:00.000Z'),
    certified: false
  },
  {
    _id: Math.random(),
    name: 'Emily Stone',
    spent_time: 18,
    progress: 55,
    purchase_date: new Date('2023-08-25T13:20:00.000Z'),
    expiry_date: new Date('2024-08-25T13:20:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'Raj Patel',
    spent_time: 32,
    progress: 70,
    purchase_date: new Date('2024-02-22T15:30:00.000Z'),
    expiry_date: new Date('2025-02-22T15:30:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'Lina K.',
    spent_time: 22,
    progress: 45,
    purchase_date: new Date('2023-12-19T18:00:00.000Z'),
    expiry_date: new Date('2024-12-19T18:00:00.000Z'),
    certified: false
  },
  {
    _id: Math.random(),
    name: 'Oscar W.',
    spent_time: 40,
    progress: 92,
    purchase_date: new Date('2024-04-10T09:00:00.000Z'),
    expiry_date: new Date('2025-04-10T09:00:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'Sofia L.',
    spent_time: 27,
    progress: 65,
    purchase_date: new Date('2024-07-15T16:30:00.000Z'),
    expiry_date: new Date('2025-07-15T16:30:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'Alexander H.',
    spent_time: 34,
    progress: 77,
    purchase_date: new Date('2024-03-25T12:45:00.000Z'),
    expiry_date: new Date('2025-03-25T12:45:00.000Z'),
    certified: false
  },
  {
    _id: Math.random(),
    name: 'Nina Shah',
    spent_time: 18,
    progress: 53,
    purchase_date: new Date('2023-07-13T07:30:00.000Z'),
    expiry_date: new Date('2024-07-13T07:30:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'William T.',
    spent_time: 45,
    progress: 95,
    purchase_date: new Date('2024-05-30T10:20:00.000Z'),
    expiry_date: new Date('2025-05-30T10:20:00.000Z'),
    certified: false
  },
  {
    _id: Math.random(),
    name: 'Lucas Wang',
    spent_time: 30,
    progress: 60,
    purchase_date: new Date('2023-11-10T08:15:00.000Z'),
    expiry_date: new Date('2024-11-10T08:15:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'Sarah J.',
    spent_time: 16,
    progress: 40,
    purchase_date: new Date('2024-06-17T19:40:00.000Z'),
    expiry_date: new Date('2025-06-17T19:40:00.000Z'),
    certified: false
  },
  {
    _id: Math.random(),
    name: 'Noah G.',
    spent_time: 23,
    progress: 68,
    purchase_date: new Date('2023-09-14T13:05:00.000Z'),
    expiry_date: new Date('2024-09-14T13:05:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'Zoe Kim',
    spent_time: 29,
    progress: 78,
    purchase_date: new Date('2024-01-08T14:50:00.000Z'),
    expiry_date: new Date('2025-01-08T14:50:00.000Z'),
    certified: false
  },
  {
    _id: Math.random(),
    name: 'Marcus L.',
    spent_time: 35,
    progress: 83,
    purchase_date: new Date('2023-05-21T09:00:00.000Z'),
    expiry_date: new Date('2024-05-21T09:00:00.000Z'),
    certified: true
  },
  {
    _id: Math.random(),
    name: 'Tanya Brooks',
    spent_time: 20,
    progress: 58,
    purchase_date: new Date('2024-08-14T11:30:00.000Z'),
    expiry_date: new Date('2025-08-14T11:30:00.000Z'),
    certified: false
  }
];

export default data;

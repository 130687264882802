import React from 'react'
import QuizPreview from 'components/organisms/QuizPreview/QuizPreview'
import { Helmet } from 'react-helmet'

function QuizPreviewPage() {
    return (
        <>
            <Helmet>
                <title>Preview Quiz</title>
                <meta name="description" content="Test quiz" />
            </Helmet>
            <QuizPreview />
        </>
    )
}

export default QuizPreviewPage
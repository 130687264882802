import { BasicTooltip } from '../Tooltip/Tooltip';
import styles from './MWEditor.module.scss';
import Image from 'assets/icons/imgicon.png';
import Icon from 'assets/icons/img.svg?react';

export const InputButton = ({ inputFileRef, tooltip, postId, handleFileAddition, icon }) => {
  return (
    <div data-tooltip-id={`${tooltip}-tooltip`}>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={inputFileRef}
        accept="video/*,image/*"
        id={`upload${postId}`}
        multiple={true}
        onChange={(e) => handleFileAddition(e.target.files, [], 0)}
      />
      <label htmlFor={`upload${postId}`}>
        <div className={styles.iconWrapper}>
          {icon ? (
            <Icon />
          ) : (
            <span className={styles.imgicon}>
              <img width={12} src={Image} alt="image icon" />
            </span>
          )}
        </div>
      </label>
      <BasicTooltip id={`${tooltip}-tooltip`} variant={'dark'} content={'Image or Video'} />
    </div>
  );
};

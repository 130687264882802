import { toast } from 'react-toastify';

export const validateQuizData = (title, description, quizData) => {
  if (!title) {
    toast.error('Please add a title.');
    return false;
  }
  if (!description) {
    toast.error('Please add a description.');
    return false;
  }
  if (!quizData.length) {
    toast.error('Please add at least one question.');
    return false;
  }

  for (let i = 0; i < quizData.length; i++) {
    const questionData = quizData[i];
    const question = questionData.question;
    const answer = questionData.answer;

    if (!question) {
      toast.error(`Please add a question for Qn ${i + 1}.`);
      return false;
    }

    if (!answer || Object.values(answer).length === 0) {
      toast.error(`Please add an answer for Qn ${i + 1}.`);
      return false;
    }
  }

  return true;
};

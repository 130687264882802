import React, { useState } from 'react';
import styles from './Projects.module.scss';
import ps from 'assets/images/PS.webp';
import Arrow from 'assets/icons/arrowright.svg?react';
import { isSharedMode, removeObjectElements } from 'helpers';
import {
  useDeleteProjectMutation,
  useGetProjectsByUserIdQuery,
  useSaveProjectMutation,
  useUpdateProjectMutation
} from 'api/individual-profile';
import { handleAsync } from 'helpers';
import Card from './Card';
import CreateProjectModal from './CreateProjectModal';
import { Loader } from 'components/ui';
import { useHandleRtkPagination } from 'hooks';
import useSelectCacheData from 'hooks/useSelectCacheData';
import { Button } from 'components/ui';
import Options from 'components/atoms/Options/Options';
import { showErrorToast } from 'utils/toast';
import { useTranslation } from 'react-i18next';

export default function Projects({ userId, isCurrentUserProfile }) {
  const sharedData = useSelectCacheData('getSharedProfileBySlug', {});

  const { t } = useTranslation();
  const [create, setCreate] = useState(false);
  const [updateProject] = useUpdateProjectMutation();
  const [saveProject, { isLoading: isSaving }] = useSaveProjectMutation();
  const [deleteProject] = useDeleteProjectMutation();
  const { result, setPage } = useHandleRtkPagination({
    queryName: 'getProjectsByUserId',
    rtkQuery: useGetProjectsByUserIdQuery,
    rtkQueryArgs: { userId },
    rtkQueryOptions: {
      skip: isSharedMode()
    }
  });
  const { data, isFetching, isLoading, isError, error, isSuccess } = !isSharedMode()
    ? result
    : sharedData;
  const onUpdateSubmit = async (project) => {
    const { tools } = project;
    if (tools && tools.length > 20) {
      showErrorToast('You can add up to 20 tools');
      return false;
    }
    const { id, ...rest } = project;
    const projectDto = removeObjectElements(rest, ['img', 'userId']);
    await handleAsync(
      updateProject({
        userId,
        projectId: id,
        project: projectDto
      }).unwrap()
    );
  };
  const onDeleteSubmit = async (id) => {
    const status = await handleAsync(
      deleteProject({
        userId,
        projectId: id
      }).unwrap()
    );
    return status[0];
  };

  const onCreateSubmit = async (project) => {
    const { tools } = project;
    if (tools && tools.length > 30) {
      showErrorToast('You can add up to 20 tools');
      return false;
    }
    const status = await handleAsync(saveProject({ userId, project }).unwrap());
    return status[0];
  };

  let content = null;
  if (isLoading) {
    content = <Loader />;
  } else if (isError) {
    content = <p style={{ color: 'red' }}> Error: {error?.data?.message}</p>;
  } else if (isSuccess) {
    const projects = isSharedMode() ? data.bio.projects : data.result;

    content = (
      <>
        <div className={styles.content}>
          {projects?.map((proj) => {
            //TODO image is static here
            return (
              <Card
                img={ps}
                width={52}
                height={52}
                company={proj.company}
                description={proj.description}
                position={proj.position}
                tools={proj.tools}
                name={proj.name}
                key={proj.id}
                id={proj.id}
                location={proj.location}
                date={proj.datepicker}
                to={proj.to}
                from={proj.from}
                ongoing={proj.ongoing}
                isCurrentUserProfile={isCurrentUserProfile}
                onUpdateSubmit={onUpdateSubmit}
                onDeleteSubmit={onDeleteSubmit}
              />
            );
          })}
        </div>
        {isFetching && <Loader />}
      </>
    );
  }

  return (
    <>
      <div className={styles.profileinfotech}>
        {isCurrentUserProfile && (
          <Options extraClass="topOptions" handleAdd={() => setCreate(true)} />
        )}
        <div className={styles.infowrapper}>
          <div className={styles.wrapper}>
            <h3 className={styles.title} id="projects-title">
              {t('Projects')}
            </h3>
            {content}
          </div>
          <div className={!data?.page?.hasNextPage ? `${styles.removebtn}` : `${styles.addmore}`}>
            <Button
              onClick={() => setPage((pre) => pre + 1)}
              disabled={isFetching || !data?.page?.hasNextPage}
              color="#bfbfbf"
            >
              {t('Load More')} <Arrow />
            </Button>
          </div>
        </div>
      </div>
      <CreateProjectModal
        isSaving={isSaving}
        open={create}
        onClose={() => setCreate(false)}
        onSaveProject={(project) => onCreateSubmit(project)}
      />
    </>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import { FaRegCopy } from 'react-icons/fa';
import { defaultBannerImage } from '../../QuizBuilder';
import CustomRadioButton from 'components/atoms/CustomRadioButton/CustomRadioButton';

const ShareModal = ({ isOpen, onClose }) => {

    const [isChecked, setIsChecked] = useState(false);
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose()
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} ref={modalRef}>
                <h2 className={styles.modalTitle}>Share options</h2>
                <div className={styles.imageContainer}>
                    <img src={defaultBannerImage} alt="Preview" />
                </div>
                <div className={styles.inputGroup}>
                    <label>Share with</label>
                    <input
                        type="text"
                        placeholder="Add emails (separate with comma)"
                        className={styles.inputField}
                    />
                    <div className={styles.scheduleExpiration}>
                        <CustomRadioButton
                            size='small'
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                        />
                        <span onClick={() => setIsChecked(!isChecked)}>Schedule expiration</span>
                    </div>
                </div>
                <div className={styles.inputGroup}>
                    <label>Security Password</label>
                    <input
                        type="password"
                        placeholder="If no security password is added, anyone can view the content"
                        className={styles.inputField}
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label>Sharable Link</label>
                    <div className={styles.linkWrapper}>
                        <input
                            type="text"
                            value="https://connect.masterwizr.com/room=wizr-natasha"
                            readOnly
                            className={styles.inputField}
                        />
                        <button className={styles.copyButton}>
                            <FaRegCopy />
                        </button>
                    </div>
                </div>
                <div className={styles.actionButtons}>
                    <button className={styles.cancelButton} onClick={onClose}>
                        Cancel
                    </button>
                    <button className={styles.shareButton}>Share</button>
                </div>
            </div>
        </div>
    );
};

export default ShareModal;

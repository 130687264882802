import React, { useEffect, useState } from 'react';
import styles from './DropDownMenu.module.scss';
import PropTypes from 'prop-types';
import FilterItem from 'components/atoms/FilterItem/FilterItem';
import { useDispatch, useSelector } from 'react-redux';
import { getProfilesQueryFilter, setProfilesQueryFilter } from 'store/profile.slice';
import { useTranslation } from 'react-i18next';

const DropDownMenu = ({
  filtersData,
  setOpenDropdown,
  openDropdown,
  handleCheckboxChange,
  setSelectedItems
}) => {
  const profilesFilter = useSelector(getProfilesQueryFilter);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleCheckboxReset = (title, filter) => {
    if (!profilesFilter[title]) return;
    const v = profilesFilter[title].split(',');
    const index = v.indexOf(filter.value);
    v.splice(index, 1);
    if (v.join(',').length === 0) {
      let temp = { ...profilesFilter };
      delete temp[title];
      dispatch(setProfilesQueryFilter({ ...temp }));
    } else {
      dispatch(setProfilesQueryFilter({ ...profilesFilter, [title]: v.join(',') }));
    }
    setSelectedItems((prevItems) => prevItems.filter((item) => item !== filter.value));
  };
  const handleClearAll = () => {
    dispatch(setProfilesQueryFilter({}));
    setSelectedItems([]);
  };
  return (
    <div
      className={
        openDropdown ? `${styles.dropdownMenu} ${styles.openedDropdown}` : styles.dropdownMenu
      }
    >
      <div className={styles.dropdownContent}>
        {filtersData.map((group, index) => {
          if (group.filters.length < 1) return <></>;
          return (
            <div className={styles.menuGroup} key={index}>
              <span className={styles.groupTitle}>{t(group.title === 'availability.status' ? 'Availability' : group.title)}</span>
              <div className={styles.groupElements}>
                {group.filters.map((filter, idx) => {
                  return (
                    <FilterItem
                      profilesFilter={profilesFilter}
                      handleCheckboxChange={handleCheckboxChange}
                      title={group.title}
                      filter={filter}
                      key={idx}
                      handleCheckboxReset={handleCheckboxReset}
                      type={filter.type}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <button
        onClick={handleClearAll} // Call the handleClearAll function on click
        className={styles.clearAllButton}
      >
        {t('Clear All')}
      </button>
    </div>
  );
};

export default DropDownMenu;
DropDownMenu.propTypes = {
  filtersData: PropTypes.array,
  setOpenDropdown: PropTypes.func
};

import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
  name: 'popups',
  initialState: {
    pointer: 0,
    data: null
  },
  reducers: {
    togglePopup: (state, action) => {
      const { pointer, data } = action.payload || {};
      state.pointer = pointer || 0;
      state.data = data || null;
    }
  }
});
export const { togglePopup } = popupSlice.actions;

export default popupSlice.reducer;

import React from 'react';
import styles from './UserTable.module.scss';

const UserTable = ({ data }) => {
  const colorMap = {
    passed: '#00A480',
    failed: '#FF2700',
    pending: '#EF8D2B'
  };
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Users</th>
            <th>Date</th>
            <th>Score</th>
            <th>Status</th>
            <th>Results</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  justifyContent: 'center',
                  fontWeight: '700'
                }}
              >
                <img
                  width={34}
                  height={34}
                  src={item.profileImage}
                  style={{ borderRadius: '50%' }}
                  alt="profileImage"
                />
                {item.name}
              </td>
              <td style={{ fontWeight: '700' }}>{item.date}</td>
              <td style={{ color: !Number(item.score) ? '#EF8D2B' : '#fff' }}>{item.score}</td>
              <td style={{ color: colorMap[item.status.toLowerCase()] }}>{item.status}</td>
              <td className={styles.actionsColumn} style={{ textAlign: 'center' }}>
                <a href={`#`}>View Results</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;

import React from 'react';
import styles from './TechsSection.module.scss';
import SketchI from 'assets/icons/sketch.svg?react';
import Options from 'components/atoms/Options/Options';
import TechUi from './TechUI';

const defaultSectionTitle = 'Techs';
function TechSectionView({ techs, sectionTitle }) {
  const [edit, setEdit] = React.useState(false);
  return (
    <>
      <div className={styles.techsSection}>
        <Options
          extraClass="languageOptions"
          handleEdit={() => {
            setEdit(true);
          }}
          handleSave={() => {
            setEdit(false);
          }}
          handleDiscard={() => {
            setEdit(false);
          }}
          edit={edit}
        />
        <h3 className={styles.sectionTitle}>{sectionTitle ?? defaultSectionTitle}</h3>
        <div className={styles.techsWrapper}>
          {techs.map((tech, index) => (
            <TechUi key={index} {...tech} />
          ))}
          {edit && (
            <span className={styles.addTech} onClick={() => {}}>
              + Add Tech
            </span>
          )}
        </div>
      </div>
    </>
  );
}
function TechsSection({ sectionTitle }) {
  return <TechSectionView techs={placeholder} sectionTitle={sectionTitle} />;
}
const placeholder = [
  {
    name: 'html',
    rate: 4,
    icon: SketchI
  },
  {
    name: 'css',
    rate: 3,
    icon: SketchI
  },
  {
    name: 'javascript',
    rate: 5,
    icon: SketchI
  },
  {
    name: 'react',
    rate: 4,
    icon: SketchI
  },
  {
    name: 'redux',
    rate: 3,
    icon: SketchI
  }
];
export default TechsSection;

import { Modal } from 'components/modals';
import React from 'react';
import styles from './DescriptionAndUploadModal.module.scss';
import Upload from 'assets/icons/uploadicon.svg?react';
import HiddenFileInput from 'components/molecules/HiddenFileInput/HiddenFileInput';
import Loader from '../Loader/Loader';
import { useTour } from '@reactour/tour';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

function DescriptionAndUploadModal({
  onClose,
  onDescriptionChange,
  description,
  isModalOpen,
  onBlobUpload,
  isBlobUploading = false,
  userUploadedImage = false,
  onSubmit,
  onSectionUpdate,
  //   inputAccept must be string =>  'videos', 'images'
  inputAccept
}) {
  const [files, setFiles] = React.useState(null);
  const fileRef = React.useRef(null);
  const { currentStep, setCurrentStep, isOpen } = useTour();
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} isModalOpen={isModalOpen} hasHeader extraClass="descriptionModal">
      <div className={styles['wrapper']}>
        <h3 className={styles['title']}>{t('Tell us about yourself')}</h3>
        <div className={styles['content-wrapper']}>
          <div>
            <p className={styles['textarea-title']}>{t('Description')}</p>
            <textarea
              maxLength={1000}
              onChange={onDescriptionChange}
              value={description}
              placeholder="..."
              name="description"
              id="description"
            />
          </div>
          <div>
            <p className={styles['textarea-title']}>
              {inputAccept === 'videos' ? t('Video presentation') : t('Image')} ({t('Optional')}*)
            </p>{' '}
            <HiddenFileInput
              ref={fileRef}
              id={`upload`}
              // accept only video files
              accept={inputAccept === 'videos' ? 'video/*' : 'image/*'}
              multiple={false}
              onChange={(e) => {
                const filesArray = Array.from(e.target.files);
                setFiles(filesArray);
                onBlobUpload(e.target.files[0]);
              }}
              type="file"
              //   fileRef={inputFileRef}
            />
            <label className={styles['video-selector']} htmlFor="upload">
              {isBlobUploading && <Loader />}
              {!isBlobUploading && (
                <>
                  {/* check if the imput file has file */}
                  <Upload />{' '}
                  {files && (
                    <span style={{ color: '#08e1b2' }}>{files.map((f) => f?.name).join(', ')}</span>
                  )}
                  <span>Upload your {inputAccept === 'videos' ? 'video' : 'image'} </span>
                  <span>{userUploadedImage} </span>
                  {userUploadedImage && (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const resultObj = { mission: { image: '' } };
                        onSectionUpdate(resultObj);
                      }}
                    >
                      {t('Reset')}
                    </Button>
                  )}
                </>
              )}
            </label>
          </div>
        </div>
        <div className={styles['button-wrapper']}>
          <button
            onClick={() => {
              onClose();
              isOpen && setCurrentStep(currentStep + 1);
            }}
          >
            {t('Cancel')}
          </button>
          <button
            disabled={isBlobUploading}
            onClick={() => {
              onSubmit();
              isOpen && setCurrentStep(currentStep + 1);
            }}
          >
            {isBlobUploading ? t('Uploading...') : t('Save')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DescriptionAndUploadModal;

import React, { useState, useEffect } from 'react';
import AddNewsSection from 'components/molecules/AddNewsSection/AddNewsSection';
import PostCard from 'components/molecules/PostCard/PostCard';
import styles from './SocialBoard.module.scss';
import Library from '../../../assets/icons/library.svg?react';
import Studio from '../../../assets/icons/studio.svg?react';
import Loader from '../../ui/Loader/Loader';
import { serialize } from '../../atoms/MWEditor/Helpers';
import profileImage from 'assets/images/avatar.webp';
import { getUserStatusSelector } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { getS3Url } from 'helpers/s3Handler';
import { debounce } from 'lodash';
import { useGetUrlQuery, useHandleRtkPagination, useUpdateEffect } from 'hooks';
import { useGetPostsByCompanyIdQuery } from 'api/social-board';
import NewsFilters from 'components/molecules/NewsFilters/NewsFilters';
import { getCachedQueryResult } from 'api/utils';
import BannerNavigation from 'components/molecules/BannerNavigation/BannerNavigation';
import { NAV_DATA } from 'constants';
import { useLocation } from 'react-router-dom';
import { getIs_Admin_Editor_Publisher_Selector } from 'store/login.slice';
import { NoPostsFound } from 'components/atoms/NoPostsFound/NoPostsFound';

export default function SocialBoard() {
  const canPost = useSelector(getIs_Admin_Editor_Publisher_Selector);
  const location = useLocation();
  const [category, setCategory] = useState('');
  const activePostId = useGetUrlQuery('entityId');
  const userStatus = useSelector(getUserStatusSelector);
  const companyId = userStatus.company.id;
  const { result, setPage, page } = useHandleRtkPagination({
    queryName: 'getPostsByCompanyId',
    rtkQuery: useGetPostsByCompanyIdQuery,
    rtkQueryArgs: { companyId, category, activePostId }
  });
  const { data, isFetching, isError, isSuccess, error, isLoading, refetch } = result;
  const [showAnimation, setShowAnimation] = useState(false);

  // This effect is used to refetch the data when the url changes, like when the active post changes
  // useUpdateEffect is used to avoid the initial refetch
  useUpdateEffect(() => {
    refetch({
      companyId,
      category,
      activePostId
    });
  }, [activePostId, location]);
  useEffect(() => {
    const handleScroll = debounce(() => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      // TODO: try using a custom hook for this, do not use usePagination hook it must be checked
      if (scrollTop + clientHeight >= scrollHeight * 0.65) {
        if (!isFetching && data?.page?.hasNextPage) {
          setPage((prev) => prev + 1);
        }
      }
      const currentPosition = window.pageYOffset;
      if (currentPosition > 150) {
        setShowAnimation(true);
      } else {
        setShowAnimation(false);
      }
    }, 300);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [data, isFetching]);

  let images = [];
  let content;
  if (isLoading) {
    content = <Loader />;
  } else if (data) {
    content = (
      <div className={styles.socialboard}>
        <div>
          {canPost && (
            <div className={styles.socialboard}>
              <AddNewsSection
                postsPageToIndex={() => {
                  setPage(1);
                }}
                userStatus={userStatus}
              />
            </div>
          )}
          <NewsFilters
            filter={category}
            setFilters={(ctg) => {
              setCategory(ctg);
              const cachedResult = ctg
                ? getCachedQueryResult('getPostsByCompanyId', { category: ctg })
                : getCachedQueryResult('getPostsByCompanyId');
              cachedResult?.page?.number ? setPage(cachedResult?.page?.number) : setPage(1);
            }}
          ></NewsFilters>
          {data?.result?.length ? (
            data.result?.map((post, index) => {
              return (
                <PostCard
                  updatedAt={post.updatedAt}
                  postCurrentPage={page}
                  presentation={images}
                  employeeImage={
                    post?.profile?.picture ? getS3Url(post?.profile?.picture) : profileImage
                  }
                  employeeName={`${post?.profile?.contact?.firstName || 'Unknown'} ${
                    post?.profile?.contact?.lastName || 'Unknown'
                  }`}
                  time={post.createdAt}
                  key={post.id + index}
                  postId={post.id}
                  // TODO: rely on id only
                  ownerProfileId={post?.profile?.id || post?.profile?._id}
                  content={post.content}
                  media={post.media}
                  commentCount={post.commentsCount || 0}
                  profiles={post.profiles}
                  category={post.category}
                  poll={post.poll}
                  pollAnswers={post.pollAnswers}
                  cover={post.cover}
                  initialComments={post.comments}
                  reactions={post.reactions}
                  createdAt={post.createdAt}
                >
                  <div
                    className={styles['post-card-content']}
                    dangerouslySetInnerHTML={{ __html: serialize(JSON.parse(post.content)) }}
                  />
                </PostCard>
              );
            })
          ) : (
            <NoPostsFound filter={category} />
          )}
          {isFetching && <Loader />}
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={styles.wizrxVideo}></div>
      <BannerNavigation data={NAV_DATA} extraClass="infoNav" />
      <div className={styles.container}>{content}</div>
    </>
  );
}

import { useGetUrlQuery } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const useCourseSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchText = useGetUrlQuery('q') || '';

  const handleSearchChange = (event) => {
    const newSearchText = event.target.value;

    const encodedSearchText = encodeURIComponent(newSearchText);

    if (newSearchText) {
      navigate(`${location.pathname}?q=${encodedSearchText}`);
    } else {
      navigate(location.pathname);
    }
  };

  const clearSearch = () => {
    navigate(location.pathname);
  };

  return {
    searchText,
    handleSearchChange,
    clearSearch
  };
};

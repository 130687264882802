import React from 'react';
import { Wrapper } from '../../components';
import Call from 'assets/icons/call.svg?react';
import Location from 'assets/icons/location.svg?react';
import styles from './ContactInfo.module.scss';
import { EditableText } from 'components/form';
import { getTheme } from 'helpers/general';
function ContactCard({ title, description, icon, onClick }) {
  const IconCloned = React.cloneElement(icon, {
    width: 30,
    height: 30
  });
  return (
    <div className={styles['contact-info__card']} onClick={onClick}>
      {IconCloned}
      <div>
        <h4>{title}</h4>
        <EditableText
          style={{
            textAlign: 'left',
            color: '#d6d6d6'
          }}
          value={description}
          placeholder={'Not Set Yet'}
        />
      </div>
    </div>
  );
}

function ContactInfo({ contact, companyTheme }) {
  const [contactState, setContactState] = React.useState({
    description: contact?.description || ''
  });
  const theme = getTheme(companyTheme, 'companyPage');

  return (
    <>
      <Wrapper px={4} py={3} bg={theme.contactGradient}>
        <div className={styles['contact-info']}>
          <p>
            Questions or feedback? Feel free to email us. You can also always reach us by phone too.
            We're here to help!
          </p>
        </div>

        <div className={styles['contact-info__cards']}>
          <ContactCard
            onClick={() => {
              const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
              if (isMobile) {
                window.open(`tel:${contact.phone}`);
              } else {
                window.open(`https://wa.me/${contact.phone}`);
              }
            }}
            description={contact?.phone}
            title={'Phone'}
            icon={<Call />}
          />
          <ContactCard
            description={contact?.location}
            onClick={() => {
              window.open(`https://www.google.com/maps/search/${contact.location}`);
            }}
            title={'Location'}
            icon={<Location />}
          />
        </div>
      </Wrapper>
    </>
  );
}

export default ContactInfo;

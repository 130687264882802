import styles from './CourseStructure.module.scss';
import { FaPlus } from 'react-icons/fa';
import Chapter from '../Chapter/Chapter';
import { randomUUId } from 'helpers/general';

const new_chapter_obj = {
    uuid: null,
    title: "New Chapter",
    lessons: [],
};

export default function CourseStructure({ 
    chapters, setChapters,
    lessons, setLessons,
    courseMaterial, setCourseMaterial,
    courseId,
    deleteChapter,
    deleteLessons,
    handleContentDelete
 }) {

    const handleAddChapter = () => {
        setChapters(prev => [...prev, { ...new_chapter_obj, uuid: randomUUId() }]);
    }

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2>Course Structure</h2>
                <p>
                    {chapters.length} Chapters
                </p>
                <div className={styles.data}>
                    {chapters.map((chapter) => {
                        return (
                            <Chapter key={chapter.id} {...chapter}
                                setChapters={setChapters}
                                lessons={lessons}
                                setLessons={setLessons}
                                courseMaterial={courseMaterial}
                                setCourseMaterial={setCourseMaterial}
                                courseId={courseId}
                                deleteChapter={deleteChapter}
                                deleteLessons={deleteLessons}
                                handleContentDelete={handleContentDelete}
                            />
                        )
                    })}
                    <button
                        className={styles.add_lesson}
                        onClick={handleAddChapter}
                        style={{ cursor: "pointer" }}
                    >
                        <FaPlus /> Add Chapter
                    </button>
                </div>
            </div>
        </div>
    )
}

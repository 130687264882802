import React, { useState } from 'react';
import RatingStarsHoverable from 'components/molecules/RatingStarsHoverable/RatingStarsHoverable';
import styles from '../../../styles.module.scss';
import style from './styles.module.scss';
import { Button } from 'components/ui';
import profile from 'assets/images/portalofmonth.webp';

export default function index() {
  const [rating, setRating] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [reviews, setReviews] = useState([]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSaveReview = () => {
    if (userInput.trim()) {
      const newReview = {
        id: reviews.length + 1,
        text: userInput,
        rating: rating,
        date: new Date().toLocaleDateString(),
        userName: 'Emma Huston'
      };
      setReviews([newReview, ...reviews]);
      setUserInput('');
      setRating(0);
    }
  };

  return (
    <div className={style.container}>
      <h2 className={styles.sectionTitle}>Reviews ({reviews.length})</h2>
      <div className={style.reviewForm}>
        <h3 className={style.title}>Leave a review</h3>
        <RatingStarsHoverable size={2} value={rating} setValue={setRating} />
        <textarea
          placeholder="Share your experience"
          onChange={handleInputChange}
          value={userInput}
        ></textarea>
        <Button
          style={{
            cursor: !userInput.trim() || rating === 0 ? 'not-allowed' : 'pointer'
          }}
          bgColor={
            userInput.trim() && rating > 0
              ? 'linear-gradient(45deg, #30a5e0, #00a480)'
              : 'rgba(55, 54, 54, 0.53)'
          }
          onClick={handleSaveReview}
          disabled={!userInput.trim() || rating === 0}
        >
          Submit a review
        </Button>
      </div>
      <div className={style.reviewsList}>
        {reviews.map((review) => (
          <div className={style.reviewCard} key={review.id}>
            <div className={style.topContainer}>
              <div className={style.info}>
                <img src={profile} alt="profile" />
                <div className={style.nameHolder}>
                  <p>{review.userName}</p>
                  <span className={style.date}>Reviewed on {review.date}</span>
                </div>
              </div>
              <RatingStarsHoverable size={1.5} value={review.rating} disabled />
            </div>
            <div className={style.review}>
              <p>" {review.text} "</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import { useGetUrlQuery } from 'hooks';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useFilteredCourses = (courses) => {
  const [searchText, setSearchText] = useState('');
  const location = useLocation();

  useEffect(() => {
    const currentSearchText = useGetUrlQuery('q') || '';
    setSearchText(currentSearchText);
  }, [location.search]);

  const filteredCourses = courses.filter((course) =>
    course.title.toLowerCase().includes(searchText.toLowerCase())
  );

  return { filteredCourses, searchText };
};

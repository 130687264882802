import React from 'react';
import styles from './styles.module.scss';

export default function TableBody({ entry, handleCertification }) {
  const progressNumber = parseInt(entry?.progress) || 0;
  const eligableForCertificate = !!(progressNumber && progressNumber > 99);
  return (
    <tr key={entry._id} className={styles.table_tr}>
      <td className={styles.table_td} style={{ textAlign: 'start' }} data-label="Name">
        {entry.name}
      </td>
      <td className={styles.table_td} data-label="Spent Time">
        {entry.spent_time + ' hours'}
      </td>
      <td className={styles.table_td} data-label="Progress">
        <div className={styles.progress_container}>
          <pre className={styles.progress_bar} style={{ width: `${progressNumber || 0}%` }} />
          <span className={styles.progress_value}>
            {progressNumber ? `${progressNumber}%` : 'Has not started'}
          </span>
        </div>
        <span className={styles.mobile_prog_view}>
          {progressNumber ? `${progressNumber}%` : 'Has not started'}
        </span>
      </td>
      <td className={styles.table_td} data-label="Purchase date">
        {entry.purchase_date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        })}
      </td>
      <td className={styles.table_td} data-label="Expiration date">
        {entry.expiry_date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        })}
      </td>
      <td className={styles.table_td} data-label="Certificate">
        {eligableForCertificate ? (
          <button
            onClick={() => handleCertification(entry)}
            className={`${styles.cert_btn} ${entry?.certified ? styles.certified : ''}`}
          >
            {entry?.certified ? 'Show certificate' : 'Give certificate'}
          </button>
        ) : (
          `ㅤ`
        )}
      </td>
    </tr>
  );
}

import React from 'react';
import { Courses, Forms, Resources, Participants, Reviews, Ongoing, Completed, Favorited } from './components/PagesView';

export const tabNames = {
  courses: 'Courses',
  forms: 'Forms',
  resources: 'Resources',
  participants: 'Participants',
  reviews: 'Reviews'
}

export const courseOptions = {
  createCategory: 'Create Category',
  createCourse: 'Create Course'
}

export const formOptions = {
  createQuiz: 'Create Quiz',
  createSurvey: 'Create Survey'
}

export const resourceOptions = {
  upload: 'Upload Resources'
}

export const participantOptions = {
  selectCourse: 'Select Course'
}

export const reviewOptions = {
  salesTraining: 'Sales & Training'
}

const links = [
  {
    value: tabNames.courses,
    path: '/dashboard/courses',
    page: <Courses />,
    button: {
      type: 'dropdown',
      label: 'Create new',
      options: [courseOptions.createCategory, courseOptions.createCourse]
    }
  },
  {
    value: tabNames.forms,
    path: '/dashboard/forms',
    page: <Forms />,
    button: {
      type: 'dropdown',
      label: 'Create new',
      options: [formOptions.createQuiz, formOptions.createSurvey]
    }
  },
  {
    value: tabNames.resources,
    path: '/dashboard/resources',
    page: <Resources />,
    button: {
      type: 'bare',
      label: 'Upload Resources'      
    }  
  },
  {
    value: tabNames.participants,
    path: '/dashboard/participants',
    page: <Participants />,
    button: {
      type: 'dropdown',
      label: 'Select Course',
      options: [participantOptions.selectCourse]
    }
  },
  {
    value: tabNames.reviews,
    path: '/dashboard/reviews',
    page: <Reviews />,
    button: {
      type: 'dropdown',
      label: 'Sales & Training',
      options: [reviewOptions.salesTraining]
    }
  }
];

export const completedCourses = [
  {
    id: 2,
    title: "Learning Techniques",
    author: "Matthew Doe",
    sections: 3,
    lessons: 20,
    progress: 100,
    completed: true,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15273_hxnvbz.png',
  },
  {
    id: 3,
    title: "Digital Marketing Masterclass",
    author: "Jane Smith",
    sections: 5,
    lessons: 35,
    progress: 100,
    completed: true,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15277_ov0htd.png',
  },
  {
    id: 4,
    title: "Introduction to Programming",
    author: "John Appleseed",
    sections: 4,
    lessons: 25,
    progress: 100,
    completed: true,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031953/Templates/Rectangle_15275_vy0ppi.png',
  },
  {
    id: 6,
    title: "Graphic Design for Beginners",
    author: "Chris Brown",
    sections: 4,
    lessons: 22,
    progress: 100,
    completed: true,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084641/Templates/Rectangle_15400_1_qqpf3y.png',
  }
];

export const favoritedCourses = [
  {
    id: 1,
    title: "Sales Training",
    author: "Matthew Doe",
    sections: 3,
    lessons: 20,
    progress: 0,
    completed: false,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15274_bzggyl.png',
  },
  {
    id: 2,
    title: "Learning Techniques",
    author: "Matthew Doe",
    sections: 3,
    lessons: 20,
    progress: 100,
    completed: true,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15273_hxnvbz.png',
  },
  {
    id: 3,
    title: "Digital Marketing Masterclass",
    author: "Jane Smith",
    sections: 5,
    lessons: 35,
    progress: 75,
    completed: false,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15277_ov0htd.png',
  }
];

export const ongoingCourses = [
  {
    id: 3,
    title: "Digital Marketing Masterclass",
    author: "Jane Smith",
    sections: 5,
    lessons: 35,
    progress: 75,
    completed: false,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15277_ov0htd.png',
  },
  {
    id: 4,
    title: "Introduction to Programming",
    author: "John Appleseed",
    sections: 4,
    lessons: 25,
    progress: 30,
    completed: false,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031953/Templates/Rectangle_15275_vy0ppi.png',
  },
  {
    id: 5,
    title: "Project Management Essentials",
    author: "Sarah Lee",
    sections: 6,
    lessons: 40,
    progress: 50,
    completed: false,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15276_lv1u8s.png',
  },
  {
    id: 7,
    title: "Advanced Excel Techniques",
    author: "Emily White",
    sections: 5,
    lessons: 30,
    progress: 10,
    completed: false,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084640/Templates/Rectangle_15399_1_ebten7.png',
  },
  {
    id: 8,
    title: "Public Speaking Mastery",
    author: "Tom Black",
    sections: 3,
    lessons: 15,
    progress: 45,
    completed: false,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084642/Templates/Rectangle_15401_1_yrbzvc.png',
  },
  {
    id: 9,
    title: "Photography Basics",
    author: "Lisa Ray",
    sections: 4,
    lessons: 18,
    progress: 20,
    completed: false,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15274_bzggyl.png',
  },
  {
    id: 10,
    title: "Business Strategy Fundamentals",
    author: "David Green",
    sections: 5,
    lessons: 28,
    progress: 90,
    completed: false,
    imageUrl: 'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15273_hxnvbz.png',
  },
];

export const newTabNames = {
  ongoing: "Ongoing Courses",
  completed: "Completed Courses",
  favorited: "Favorited Courses"
}

const newLinks = [
  {
    value: newTabNames.ongoing,
    path: '/dashboard/courses/ongoing',
    page: <Ongoing />
  },
  {
    value: newTabNames.completed,
    path: '/dashboard/courses/completed',
    page: <Completed />
  },
  {
    value: newTabNames.favorited,
    path: '/dashboard/courses/favorited',
    page: <Favorited />
  }
];

export default newLinks;

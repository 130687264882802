import React, { useState } from 'react';
import styles from './CustomDropdown.module.scss';
import PropTypes from 'prop-types';
import Arrow from 'assets/icons/arrowdown.svg?react';
import Search from 'assets/icons/search.svg?react';
import Icontext from 'components/molecules/IconText/IconText';
import { useTranslation } from 'react-i18next';
import { BasicTooltip } from 'components/atoms/Tooltip/Tooltip';

export default function CustomDropdown({
  label,
  title,
  list = [],
  extraClass,
  extraInnerClass,
  placeholder,
  value,
  onChange,
  heightClass = 'midHeight',
  search,
  Icon,
  clickable,
  ...rest
}) {
  const [dropdown, setDropdown] = useState(false);
  const [inputClicked, setInputClicked] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  function onSearch(e) {
    setSearchValue(e.target.value);
  }
  return (
    <div className={`${styles.dropdownContainer} ${styles[extraClass]}`}>
      {title && <h3>{t(title)}</h3>}
      {label && <label>{t(label)}</label>}

      <div
        className={`${styles.dropdown} ${styles[extraInnerClass]}`}
        onClick={() => setDropdown(!dropdown)}
      >
        <p
          style={{
            userSelect: 'none'
          }}
        >
          {value || placeholder}
        </p>
        {Icon === undefined ? (
          <Arrow className={dropdown === true ? styles.active : ''} />
        ) : (
          <div className={dropdown === true ? styles.active : ''}>{Icon}</div>
        )}
        {dropdown && (
          <>
            <div className={`${styles.options} ${styles[heightClass]}`}>
              {search && (
                <div className={styles.searchInput}>
                  <Search />
                  <input
                    type="text"
                    onFocus={() => setInputClicked(true)}
                    onBlur={() => setInputClicked(false)}
                    onChange={onSearch}
                    autoFocus
                    value={searchValue}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              )}
              {list
                .filter((item) => {
                  if (!searchValue) return list;
                  return item?.text
                    ? item.text.toLowerCase().includes(searchValue.toLowerCase())
                    : item?.toLowerCase()?.includes(searchValue.toLowerCase());
                })
                .map((item, i) => {
                  return (
                    <Icontext
                      text={item.label || item.text || item}
                      icon={item.icon}
                      key={i}
                      // TODO: onChange function should have on "item" as parameter NOT "item.text"
                      onClick={
                        clickable
                          ? item.onClick
                          : () => onChange?.(item?.text ? item.text : item, item)
                      }
                    />
                  );
                })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

CustomDropdown.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  list: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.bool,
  extraClass: PropTypes.string,
  search: PropTypes.bool,
  heightClass: PropTypes.string
};

import React, { useState } from 'react';
import ChevronLeft from 'assets/icons/chevronLeft.svg?react';
import ChevronRight from 'assets/icons/chevronRight.svg?react';
import styles from './DataSlider.module.scss';
import ApexChart from '../PieChart/Piechart';
const DataSlider = ({ data, onSlideChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const chartData = {
    labels: data.map((item) => item.questionData[currentPage - 1].answer),
    series: data.map((item) => item.questionData[currentPage - 1].numberOfResponses),
    backgroundColor: ['#0077b6', '#1e90ff', '#4169e1', '#6495ed', '#87ceeb', '#add8e6']
  };
  const handleNext = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : totalPages));
    onSlideChange(currentPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
    onSlideChange(currentPage - 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    onSlideChange(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    // Always show the first page
    pageNumbers.push(
      <span
        key={1}
        className={currentPage === 1 ? `${styles.pageNumber} ${styles.active}` : styles.pageNumber}
        onClick={() => handlePageClick(1)}
      >
        1
      </span>
    );

    // Show ellipsis if needed between the first page and start of current page range
    if (currentPage > 4) {
      pageNumbers.push(
        <span key="start-ellipsis" className={styles.ellipsis}>
          ...
        </span>
      );
    }

    // Calculate start and end pages around the current page (2 before and 2 after)
    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    // Loop through and add the pages within the range
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={
            currentPage === i ? `${styles.pageNumber} ${styles.active}` : styles.pageNumber
          }
          onClick={() => handlePageClick(i)}
        >
          {i}
        </span>
      );
    }

    // Show ellipsis if needed between the end of current page range and last page
    if (currentPage < totalPages - 3) {
      pageNumbers.push(
        <span key="end-ellipsis" className={styles.ellipsis}>
          ...
        </span>
      );
    }

    // Always show the last page
    if (totalPages > 1) {
      pageNumbers.push(
        <span
          key={totalPages}
          className={
            currentPage === totalPages ? `${styles.pageNumber} ${styles.active}` : styles.pageNumber
          }
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </span>
      );
    }

    return (
      <>
        &lt;
        {pageNumbers}
        &gt;
      </>
    );
  };

  const currentData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className={styles.dataSlider}>
      <div className={styles.headingText}>
        <h2 className={styles.question}>{currentPage}. What department you worked in?</h2>
        <p className={styles.responses}>{data.length} responses</p>
      </div>

      <button
        onClick={handlePrev}
        disabled={currentPage === 1}
        className={styles.arrowButton}
        style={{ position: 'absolute', left: '20px' }}
      >
        <ChevronLeft />
      </button>
      {/* Placeholder for the chart */}
      <div className={styles.chartPlaceholder}>
        <ApexChart series={chartData.series} labels={chartData.labels} />
        <div className={styles.labels}>
          {chartData.labels.map((label, index) => (
            <div key={index} className={styles.label}>
              <div
                className={styles.colorBox}
                style={{ backgroundColor: chartData.backgroundColor[index] }}
              />
              <p>{label}</p>
            </div>
          ))}
        </div>
      </div>
      {/* Pagination Controls */}
      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className={styles.arrowButton}
        style={{ position: 'absolute', right: '20px' }}
      >
        <ChevronRight />
      </button>
      <div className={styles.paginationControls}>
        <div className={styles.pageNumbers}>{renderPageNumbers()}</div>
      </div>
    </div>
  );
};

export default DataSlider;

import React from 'react';
import styles from './Info.module.scss';
import { Link } from 'react-router-dom';
import Arrow from 'assets/icons/arrowright.svg?react';
import Boarding from 'components/molecules/BoardingContent';
import { useTranslation } from 'react-i18next';

export default function Info() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Link to={`/`} className={styles.link}>
        <Arrow />
        {t('Back to Profiles')}
      </Link>
      <Boarding removeBtn={true} />
    </div>
  );
}

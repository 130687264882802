import React from 'react';
import styles from './Search.module.scss';
import SearchIcon from 'assets/icons/search.svg?react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { isStringLimited } from 'helpers';
import { showErrorToast } from 'utils/toast';
export default function Search({ label, placeholder, searchQuery, setSearchQuery, limit }) {
  const handleOnChange = debounce(async (value) => {
    setSearchQuery(value);
  }, 300);
  const handleInputChange = (value) => {
    const [isIt, searchValue] = isStringLimited(value, limit);
    if (!isIt) showErrorToast(` should be less than ${limit} characters`);
    handleOnChange(searchValue);
  };
  return (
    <div className={styles.searchGroup}>
      <label>{label}</label>
      <SearchIcon />
      <input
        type="text"
        placeholder={placeholder}
        autoFocus
        defaultValue={searchQuery}
        maxLength={limit}
        onChange={(e) => {
          handleInputChange(e.target.value);
        }}
      />
    </div>
  );
}

Search.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string
};

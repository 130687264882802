import Status from 'components/molecules/Status/Status';
import React, { useState } from 'react';
import styles from './AvailabilityFilter.module.scss';
import Arrow from 'assets/icons/arrowrotate.svg?react';
import List from 'assets/icons/list.svg?react';
import Grid2 from 'assets/icons/columnstwo.svg?react';
import Grid4 from 'assets/icons/grid4.svg?react';
import PropTypes from 'prop-types';
import CheckInput from 'components/atoms/CheckInput/CheckInput';
import { AVAILABILITY_OPTIONS } from 'constants';
function AvailabilityFilter({
  index,
  setIndex,
  selectedOption,
  onOptionSelect,
  companyTeams,
  setSelectedTeamsId,
  selectedTeamsId
}) {
  const dropdownItemRef = React.useRef();
  const [dropdown, setDropdown] = useState(false);
  const [teams, setTeams] = useState(false);

  const teamsData = companyTeams;
  if (!teamsData) return;

  return (
    <div className={styles.filter}>
      <div className={styles.left}>
        {selectedOption !== 'All' && (
          <Status
            color={
              !selectedOption
                ? ''
                : selectedOption?.includes('Available') || selectedOption?.includes('Working')
                  ? '#519404'
                  : 'red'
            }
          />
        )}
        <div onClick={() => setDropdown(!dropdown)} className={styles.dropdownContainer}>
          <h2>
            {selectedOption || 'All'} <Arrow />
          </h2>
          <div
            className={
              dropdown ? `${styles.dropdown} ${styles.showDropdown}` : `${styles.dropdown}`
            }
          >
            {Object.values(AVAILABILITY_OPTIONS).map((option) => {
              return (
                <div
                  key={option.id}
                  onClick={() => onOptionSelect(option)}
                  className={styles.option}
                >
                  {option.item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.filterOptions}>
          {/* <Grid2
            onClick={() => setIndex(1)}
            className={index === 1 ? `${styles.active} ${styles.activelist}` : ``}
          /> */}
          <List
            onClick={() => setIndex(0)}
            className={index === 0 ? `${styles.active} ${styles.activelist}` : ``}
          />
          <Grid4 onClick={() => setIndex(2)} className={index === 2 ? styles.active : ``} />
        </div>
        <div>
          <button
            className={teams ? styles.active : ''}
            onClick={() => setTeams(!teams)}
            name="Teams"
          >
            Teams
          </button>
          <div
            style={{
              height:
                teamsData.length <= 7
                  ? `${teamsData.length * dropdownItemRef?.current?.clientHeight}px `
                  : `${7 * dropdownItemRef?.current?.clientHeight}px`
            }}
            className={teams ? `${styles.teams} ${styles.showTeams}` : styles.teams}
          >
            {teamsData.map((team) => {
              return (
                <div ref={dropdownItemRef} className={styles.checkContainer} key={team.id}>
                  <div className={styles.check}>
                    <CheckInput
                      label={team.name}
                      name={team.id}
                      id={team.id}
                      checked={selectedTeamsId?.includes(team.id)}
                      onChange={() => {
                        if (selectedTeamsId?.includes(team.id)) {
                          setSelectedTeamsId((prev) => prev.filter((id) => id !== team.id));
                        } else {
                          setSelectedTeamsId((prev) => [...prev, team.id]);
                        }
                      }}
                    />
                  </div>

                  {/* <h3
                    className={team.name.length > 15 ? styles.teamName : ''}
                    title={team.name.length > 15 ? team.name : null}
                  >
                    {team.name.length > 15 ? team.name.slice(0, 12) + '...' : team.name}
                  </h3> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AvailabilityFilter;
AvailabilityFilter.propTypes = {
  index: PropTypes.number,
  setIndex: PropTypes.func
};

import React from 'react';
import styles from './ResponsiveWrapper.module.scss';
function ResponsiveWrapper({ children, extraClass }) {
  return (
    <div className={`${styles['wrapper']} ${styles[extraClass]}`}>
      <div className={styles['main-content']}>{children}</div>
    </div>
  );
}

export default ResponsiveWrapper;

import { useDispatch, useSelector } from "react-redux";
import { answerTypes, previewStages, setPreviewStage } from "store/quiz.slice";
import styles from './styles.module.scss';
import CircularProgress from "../CircularProgress";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isAnswerCorrect } from "helpers/quiz";

const QuizResult = () => {
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const { title, description, quizData, answers, timeSpent } = useSelector(state => state.quiz);
    const [needReview, setNeedReview] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        textAns,
    } = answerTypes;

    useEffect(() => {
        let count = 0;
        let totalPointsCount = 0;
        let textQns = 0;

        quizData.forEach((question, i) => {
            const userAnswer = answers[i];

            if (question.type === textAns) textQns++;
            if (isAnswerCorrect(question, userAnswer)) count += question.points;
            totalPointsCount += question.points;
        });

        setCorrectAnswers(count);
        setTotalPoints(totalPointsCount);
        setNeedReview(textQns);
    }, [quizData, answers]);

    const backToQns = () => navigate('/quiz');
    const goToSummary = () => dispatch(setPreviewStage(previewStages.summary));

    const score = Math.round((correctAnswers / totalPoints) * 100);
    const results = `${correctAnswers}/${totalPoints}`;
    const isPassed = score >= 50;
    const compliment = isPassed ? "Nice work, Great score" : "Fair, could improve";
    const statusText = isPassed ? "Quiz Passed" : "Quiz Failed";
    const progressColor = isPassed ? styles.passColor : styles.failColor;

    const minutes = Math.floor(timeSpent / 60);
    const seconds = timeSpent % 60;
    const timeTaken = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`

    return (
        <div className={styles.quizResultWrapper}>
            <div className={styles.quizResultContainer}>
                <div className={styles.quizResult}>
                    <div>
                        <h2 className={styles.title}>{title}</h2>
                        <p className={styles.description}>{description}</p>
                    </div>

                    <div>
                        <h3 className={styles.compliment}>{compliment}</h3>
                        <CircularProgress progress={score} progressColor={progressColor} />

                        <h4 className={styles.statusText}>{statusText}</h4>
                        <div className={styles.details}>
                            <div className={styles.resultItem}>
                                <span>{results}</span>
                                <p>Results</p>
                            </div>
                            <div className={styles.resultItem}>
                                <span>{timeTaken}</span>
                                <p>Time</p>
                            </div>
                        </div>
                        {needReview > 0 && (
                            <div className={styles.reviewNotice}>
                                <span>{needReview} answers need review</span>
                            </div>
                        )}
                        <div className={styles.buttons}>
                            <button onClick={goToSummary}
                                className={styles.detailsButton}
                            >
                                See Details
                            </button>
                            <button
                                className={styles.backButton}
                                onClick={backToQns}>Back to Questions</button>
                        </div>
                    </div>
                    <div className={styles.spacer}></div>
                </div>
            </div>
        </div>
    );
};

export default QuizResult;

import React from 'react';
import startImg from 'assets/icons/affiliate.svg?react';
import styles from './AffiliateCard.module.scss';
import { TrashI } from 'components/ui';
function AffiliateCard({ title, editMode, onToggleSelection, isSelected }) {
  return (
    <div className={styles['affiliate-card']}>
      {editMode && (
        <>
          <input type="checkbox" checked={isSelected} onClick={onToggleSelection} />
        </>
      )}
      <div
        className={styles['affiliate-card__img']}
        style={{
          backgroundImage: `url(${startImg})`
        }}
      />
      <p>{title}</p>
    </div>
  );
}

export default AffiliateCard;

import styles from './styles.module.scss';

const DisplayIcon = ({
    Icon,
    isActive,
    onClick,
}) => {
    const activeColor = '#0693DA';
    const inactiveColor = '#707070';

    return (
        <div
            onClick={onClick}
            className={styles.displayIcon}
            style={{ border: `1px solid ${isActive ? activeColor : inactiveColor}` }}>
            <Icon color={isActive ? activeColor : inactiveColor} size={14} />
        </div>
    );
};

export default DisplayIcon;

import React from 'react'
import styles from '../styles.module.scss'

export default function ViewLoader({ id }) {
  return (
    <div className={styles.loader_conatiner}>
      <p className={styles.loader_txt}>Uploading in progress</p>
      <div id={`${id}-loading`} className={styles.loader}></div>
    </div>
  )
}

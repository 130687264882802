import React from 'react';
import styles from './Paginator.module.scss';
import Arrow from 'assets/icons/arrow-right.svg?react';
export default function Paginator({ current, setCurrent, first, last }) {
  let slice;
  if (current === last) {
    slice = [current - 2, current - 1, current];
  } else if (current === first) {
    slice = [current, current + 1, current + 2];
  } else {
    slice = [current - 1, current, current + 1];
  }
  slice = slice.filter((element) => {
    return element >= 0;
  });
  if (slice.length == 1 && slice[0] == 0) {
    return <div className={styles.container}></div>;
  }
  return (
    <div className={styles.container}>
      {/* <button className={current != first ? styles.button : styles.disabled} onClick={current != first ? () => { setCurrent(first)}:()=>{}}>First</button> */}
      <button
        className={
          current - 1 >= first
            ? `${styles.arrow} ${styles.previous} ${styles.button}`
            : `${styles.arrow} ${styles.previous} `
        }
        onClick={
          current - 1 >= first
            ? () => {
                setCurrent(current - 1);
              }
            : () => {}
        }
        name="button"
      >
        <Arrow />
      </button>
      {slice[0] >= first ? (
        <button
          className={current === slice[0] ? styles.passiveselected : styles.passive}
          onClick={() => {
            setCurrent(slice[0]);
          }}
        >
          {slice[0]}
        </button>
      ) : (
        <></>
      )}
      {slice[1] ? (
        <button
          className={current === slice[1] ? styles.passiveselected : styles.passive}
          onClick={() => {
            setCurrent(slice[1]);
          }}
        >
          {slice[1]}
        </button>
      ) : (
        <></>
      )}
      {slice[2] <= last ? (
        <button
          className={current === slice[2] ? styles.passiveselected : styles.passive}
          onClick={() => {
            setCurrent(slice[2]);
          }}
        >
          {slice[2]}
        </button>
      ) : (
        <></>
      )}
      <button
        className={current + 1 <= last ? `${styles.arrow} ${styles.button}` : `${styles.arrow} `}
        onClick={
          current + 1 <= last
            ? () => {
                setCurrent(current + 1);
              }
            : () => {}
        }
      >
        <Arrow />
      </button>
      {/* <button className={current != last ?styles.button:styles.disabled} onClick={current != last ?() => { setCurrent(last) }:()=>{}}>Last</button> */}
    </div>
  );
}

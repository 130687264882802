import { IoMdStopwatch } from "react-icons/io";
import styles from './styles.module.scss';

const Timer = ({ timeLeft }) => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
        <div className={styles.timer}>
            <IoMdStopwatch size={23} />
            <span>
                {`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`} mins left
            </span>
        </div>
    );
};

export default Timer;

import React from 'react';
import CourseCard from 'components/atoms/CourseCard/CourseCard';
import { coursesData } from 'constants/courses';
import styles from '../../../styles.module.scss';

export default function Quizzes() {
  const filterContent = coursesData.map(({ date, by, lessons, ...rest }) => rest).slice(0, 3);

  return (
    <>
      <h2 className={styles.sectionTitle}>Quizzes ({filterContent.length})</h2>
      <div className={styles.cardlist}>
        {filterContent.map((course, index) => (
          <div key={index}>
            <CourseCard btn="Start quiz" {...course} extraClass="singleCourseCard" />
          </div>
        ))}
      </div>
    </>
  );
}

import { Helmet } from 'react-helmet';
import NewCourse from 'components/organisms/NewCourse/NewCourse';

const CreateCourse = () => {
    return (
        <>
            <Helmet>
                <title>New Course</title>
                <meta name="description" content="Create a new course" />
            </Helmet>
            <NewCourse />
        </>
    )
}

export default CreateCourse;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SideProfile.module.scss';
import BannerProfile from '../BannerProfile/BannerProfile';
import Text from 'components/atoms/Text/Text';
import Icontext from '../IconText/IconText';
import Chat from 'assets/icons/chat-2.svg?react';
import Call from 'assets/icons/call.svg?react';
import Dropdown from 'assets/icons/dropdown.svg?react';
import SocialIcons from 'components/atoms/Icons/Icons';
import cover from '../../../assets/images/cover1.webp';
import defaultUserImage from '../../../assets/images/avatar.webp';
import { Link } from 'react-router-dom';
import { getS3Url } from 'helpers/s3Handler';
import { getCurrentTimeByTimeZone, getWeekDay } from 'helpers/dateHandler';
import { Button } from 'components/ui';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import { CHAT_CLIENT_URL, CHAT_SERVER_URL } from 'utils/constans';
import { showErrorToast, showInfoToast } from 'utils/toast';
import Whatsapp from 'components/atoms/Whatsapp/Whatsapp';

function SideProfile({ modal, profileData }) {
  const { profileStatus } = useSelector(getUserStatusSelector);
  const GetCurrentLocalTime = () => {
    if (profileData?.availability?.timezone != '') {
      return getCurrentTimeByTimeZone(profileData?.availability?.timezone);
    } else {
      return 'Timezone not set';
    }
  };
  const handleChatClick = async () => {
    showInfoToast(t(`Redirecting to Wizr Chat`));
    try {
      const createChat = await fetch(`${CHAT_SERVER_URL}/chats/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          creator: profileStatus.id,
          type: 'message',
          profileIds: [profileStatus.id, profileData.id],
          companyId: profileStatus.company,
          description: '',
          name: '',
          picture: ''
        })
      });
      if (!createChat.ok) return showErrorToast(createChat.statusText);
      const chat = await createChat.json();
      if (chat) window.open(`${CHAT_CLIENT_URL}/chat/${chat._id}`, '_blank');
    } catch (error) {
      showErrorToast(t(`${error}`), { delay: 2000 });
    }
  };
  const GetCurrentWorkHours = () => {
    const weekDay = getWeekDay();
    if (profileData?.availability?.daysAvailability) {
      const availability = profileData?.availability?.daysAvailability[weekDay];
      if (weekDay == 'sat' || weekDay == 'sun') {
        return 'Weekend';
      } else if (
        availability &&
        availability?.startTime &&
        availability?.endTime &&
        availability?.startTime != '' &&
        availability?.endTime != ''
      ) {
        return `${availability.startTime} -  ${availability.endTime}`;
      }
    }
    return 'Schedule not set for today';
  };
  const personalData = [
    {
      paragraph: 'DISPLAY NAME',
      title: `${profileData?.contact.firstName} ${profileData?.contact.lastName}`
    },
    {
      paragraph: 'EMAIL ADDRESS',
      title: profileData?.contact.email
    },
    {
      paragraph: 'CURRENT LOCAL TIME',
      title: GetCurrentLocalTime()
    },
    {
      paragraph: 'WORK HOURS',
      title: GetCurrentWorkHours()
    }
  ];
  const [dropdown, setDropdown] = useState(false);

  return (
    <div className={modal ? `${styles.sideprofile} ${styles.showMenu}` : styles.sideprofile}>
      <BannerProfile
        image={profileData?.bannerImage}
        bannerShade={profileData?.bannerShade}
        left="100px"
        width={100}
        height={100}
        profile={profileData?.picture ? getS3Url(profileData?.picture) : defaultUserImage}
        bannerheight="22vh"
        top="90px"
        radius="0"
        extraClass="sideBanner"
      />
      <div className={styles.container}>
        <Text
          title={`${profileData?.contact?.firstName} ${profileData?.contact?.lastName}`}
          paragraph={profileData?.role?.name}
          extraClass={`sideProfile-style`}
        />
        <div className={styles.chat}>
          {profileData?.id !== profileStatus.id ? (
            <>
              <Icontext
                padding="3px 15px"
                bgColor="#232323"
                icon={<Chat />}
                text="Message"
                onClick={handleChatClick}
              />
              <Icontext
                padding="3px 15px"
                bgColor="#232323"
                icon={<Call />}
                text="Call"
                onClick={handleChatClick}
              />
            </>
          ) : (
            <></>
          )}
          {profileData?.contact.phone && (
            <Icontext onClick={() => setDropdown(!dropdown)} icon={<Dropdown />} />
          )}
          {dropdown && (
            <div className={styles.dropdown}>
              <Whatsapp
                element={'div'}
                number={profileData?.contact.phone}
                message="Hello this message is directly from WiZR X"
              >
                <Text paragraph={'WHATSAPP'} font="500" title={profileData?.contact.phone} />
              </Whatsapp>
            </div>
          )}
        </div>
        <div className={styles.data}>
          {personalData.map((d, i) => {
            return <Text font="500" key={i} {...d} />;
          })}
          <Text paragraph={'SOCIAL MEDIA'} />
          <SocialIcons extraClass="sideIcons" social={profileData?.social} />
        </div>
        <Link to={`/profile/${profileData?.id}`}>
          <Button bgColor="#313131" color="#08dcae">
            View Full Profile
          </Button>
        </Link>
      </div>
    </div>
  );
}
export default SideProfile;
SideProfile.propTypes = {
  query: PropTypes.object,
  modal: PropTypes.bool,
  profileData: PropTypes.object
};
